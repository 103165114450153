import React, { Component } from 'react';
import { Nav } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import HeaderDropdown  from './HeaderDropdown';
import MaintenanceAlert  from '../../views/Tables/MaintenanceAlert';
import logo from '../../assets/img/brand/visioniq.png';
import compactlogo from '../../assets/img/brand/compact.png';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class Header extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <MaintenanceAlert />
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 127, height: 26, alt: 'Media Resources Logo' }}
          minimized={{ src: compactlogo, width: 30, height: 30, alt: 'Media Resources Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" navbar>
          {/*TODO - add notifications <HeaderDropdown notif/>*/}
          <HeaderDropdown accnt/>
        </Nav>
        </React.Fragment>
    );
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
