import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { MdAdd, MdBlock, MdVisibilityOff, MdRestore, MdAutorenew } from 'react-icons/md';
import { displayActions } from "../../../api/_actions";
import { utils } from "../../../utils"
import DisplayComment from "./DisplayComment"


class DisplayComments extends Component {
  constructor(props) {
    super(props);

    this.toggleAddComment = this.toggleAddComment.bind(this)
    this.loadData = this.loadData.bind(this)
    this.newItem = this.newItem.bind(this)
    this.updateParent = this.updateParent.bind(this)
    
    this.state = {
      viewDeleted: false,
      firstTimeAddComment: true,
      newItem: this.newItem()
    }
  }
  componentDidMount() {
    this.interval = setInterval(() => {this.loadData()}, 30000);
    this.loadData()
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null
  }
  componentDidUpdate(prevProps) {
    let {firstTimeAddCommment} = this.state 
    if (this.props.selectedDisplay !== prevProps.selectedDisplay) {
      this.loadData()
      this.setState({
        firstTimeAddCommment: true,
        newItem: this.newItem()
      })
      this.updateParent();
    } else if (firstTimeAddCommment && this.props.comments !== undefined) {
      // show Create comment form when there aren't any comments
      const {comments} = this.props
      const displayId = this.props.selectedDisplay
      const addComment = displayId > 0  && !(comments && comments.length)
      if (addComment && !this.state.addComment) {
        this.setState({
          addComment,
          firstTimeAddCommment: false
        })
      }
      this.updateParent();
    }

    if (this.props.newComment !== prevProps.newComment) {
      this.setState({addComment: false})  // create comment succeeded => close the New Comment form
    }
  }
  updateParent(){
    const {comments} = this.props
    this.props.onCommentsChange && this.props.onCommentsChange(comments && comments.length)
  }
  loadData() {
    const {selectedDisplay} = this.props
    const {viewDeleted} = this.state
    if (selectedDisplay) {
      this.props.dispatch(displayActions.getComments(selectedDisplay, viewDeleted));
      this.props.dispatch(displayActions.getSingleSummary(selectedDisplay));
    }
  }
  newItem(){
    return {
      DisplayId: this.props.selectedDisplay
    }
  }

  toggleViewDeleted = (e) => {
    e && e.preventDefault && e.preventDefault()
    const { selectedDisplay } = this.props;
    this.props.dispatch(displayActions.getComments(selectedDisplay, !this.state.viewDeleted))
    this.setState({viewDeleted: !this.state.viewDeleted})
  }
  toggleAddComment() {
    this.setState({
      addComment: !this.state.addComment,
      newItem: (!this.state.addComment ? this.newItem() : this.state.newComment)
    })
  }
  saveComment(item) {
    let create = item && !item.MsgInfo
    if (create) {
        this.props.dispatch(displayActions.postComment(item))
    } else {
        this.props.dispatch(displayActions.putComment(item))
    }
  }
  deleteComment(item, event) {
    const {viewDeleted} = this.state
    event && event.preventDefault && event.preventDefault();
    if (item && window.confirm("Are you sure you want to delete the comment?")) {
      this.props.dispatch(displayActions.deleteComment(item, viewDeleted))
    } else {
      this.setState({errorComment: "Nothing to delete"})
    }
  }
  unDeleteComment(item, event) {
    event && event.preventDefault && event.preventDefault();
    if (item) {
      this.props.dispatch(displayActions.unDeleteComment(item))
    } else {
      this.setState({errorComment: "Nothing to delete"})
    }
  }
  commentHistory(item, event) {
    event && event.preventDefault && event.preventDefault();
    if (item) {
      this.props.dispatch(displayActions.getCommentHistory(item))
    } else {
      this.setState({errorComment: "There is no comment history"})
    }
  }

  render() {
    let { display, comments, error, newData } = this.props;
    const { viewDeleted, addComment, newItem } = this.state;
    const displayId = display && display.DisplayId
    const allowWrite = utils.getUser().permissions.canCreate(utils.Resources.displayComments)

    return (
      <div className="animated fadeIn h-100 pb-1">
        <Card className="w-100 h-100 mb-0">
          <CardHeader>
            <i className="fa fa-align-justify" data-toggle="tooltip" data-placement="top" title={displayId}></i>Comments
            <div className="float-right">
              {allowWrite && (
                addComment
                  ? <i title="Cancel comment"><MdBlock  size={20} onClick={this.toggleAddComment}/></i>
                  : <i title="Add comment">   <MdAdd    size={20} onClick={this.toggleAddComment}/></i>
              )}
              <a title="Refresh" className="ml-1 text-light"><MdAutorenew size={16} onClick={this.loadData} /></a>
            </div>
          </CardHeader>
          <CardBody className="scrollbar scrollbar-warning pb-0">
              {/* add comment */}
              {addComment && allowWrite && displayId &&
                <DisplayComment 
                  displayId={displayId} 
                  item={newItem}
                  create={true}
                  isVisible={this.props.isVisible}
                  onSave={this.saveComment.bind(this, newItem)} 
                  onClose={this.toggleAddComment.bind(true)} 
                />
              }

              {/* show all comments */}
              {comments && comments.length
              ? comments.map(item => {
                  return (<DisplayComment 
                    key={item.MsgInfo}
                    displayId={displayId} 
                    item={item} 
                    newData={newData}
                    onSave={this.saveComment.bind(this, item)}
                    onDelete={this.deleteComment.bind(this, item)}
                    onUnDelete={this.unDeleteComment.bind(this, item)}
                    onHistory={this.commentHistory.bind(this, item)}
                  />)
                })
              : <span className="text-muted">There are no previous comments</span>
              }
              
              {/* show errors */}
              {error &&
                <small className="text-danger text-muted">{"" + error}</small>
              }
          </CardBody>
          <CardFooter>
          <i className="float-right p-0 set-btn-secondary" onClick={this.toggleViewDeleted}>
              {viewDeleted
                  ? <React.Fragment><MdVisibilityOff size={16} className="mr-2"/>Hide deleted comments</React.Fragment>
                  : <React.Fragment><MdRestore size={16} className="mr-2"/>View deleted comments</React.Fragment>
              }
            </i>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { selectedDisplay, display, comments, errorComments, newData, newComment } = state.display;
  return {
    selectedDisplay, display, comments, errorComments, newData, newComment
  };
}

const connectedDisplayComments = connect(mapStateToProps)(DisplayComments);
export default connectedDisplayComments;
