import React, { useState } from 'react';
import 'react-dates/initialize';
import _ from 'lodash';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
} from 'reactstrap';
import {
  MdInfoOutline
} from 'react-icons/md';
import 'moment-timezone';
import moment from 'moment';
import { utils } from '../../utils';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { monitorActions } from "../../api/_actions";


const DetailsPC = ({item, display, autoLoad}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const displayId = _.get(display, "DisplayId", null)
  const dispatch = useDispatch();
  const refData = React.useRef(null);
  refData.current = (!autoLoad) 
                    ? _.get(item, "Stats")
                    : useSelector(state => {
                        return _.get(state, `monitor.${displayId}.PcHealth.Stats`)
                      }, shallowEqual)
  
  const data = refData.current

  const toggle = (val) => {    
    if (!dropdownOpen && !refData.current) {
        dispatch(monitorActions.getPCStats(_.get(display, "DisplayId")))
        .catch(err => {})  // do nothing. Error is already reported in the console
    }
    setDropdownOpen(!dropdownOpen)
  }
  React.useEffect(() => {refData.current = null}, [displayId]);

  return (
    <React.Fragment>
      {(data || autoLoad) &&
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="align-top ml-2 float-right">
          <DropdownToggle caret={false} size="sm" className="p-0 btn btn-transparent" title="Details">
            <MdInfoOutline size="16" />
          </DropdownToggle>
          <DropdownMenu right className="p-2">
            {(!data) 
              ? <div className="text-danger">No data</div>
              : <React.Fragment>
                  <DropdownItem header tag="div" color="light">
                    <strong>SYSTEM UTILIZATION</strong>
                  </DropdownItem>
                  <DropdownItem className="px-1">
                    <div className="text-uppercase mb-1 d-flex">
                      <small><b>CPU Usage</b></small>
                      <small className="ml-auto"  title="Current value">{utils.Round(data.CPU)}%</small>
                    </div>
                    <Progress size="lg" color="info" value={data.CPU} className="progress-xs" />
                    <small className="text-muted">{`${data.CPU_cores} Cores`}</small>
                    <small className="text-muted ml-2">{`${data.CPU_proc} Processes`}</small>
                  </DropdownItem>
                  <DropdownItem className="px-1">
                    <div className="text-uppercase mb-1 d-flex">
                      <small><b>Memory Usage</b></small>
                      <small className="ml-auto" title="Current value">{utils.Round(data.RAM)} %</small>
                    </div>
                    <Progress size="xs" color="warning" value={data.RAM} className="progress-xs" />
                    <small className="text-muted">{`${Number(data.RAM_size).toLocaleString("en-US")} KB`}</small>
                  </DropdownItem>
                  <DropdownItem className="px-1">
                    <div className="text-uppercase mb-1 d-flex">
                      <small><b>HDD Usage</b></small>
                      <small className="ml-auto" title="Current value">{utils.Round(data.HDD)} %</small>
                    </div>
                    <Progress size="xs" color="danger" value={data.HDD} className="progress-xs" />
                    <small className="text-muted">{`${Number(data.HDD_size).toLocaleString("en-US")} KB`}</small>
                  </DropdownItem>            
                  {!data.GPU ? ""
                             :(<DropdownItem className="px-1">
                                <div className="text-uppercase mb-1 d-flex">
                                  <small><b>GPU Usage</b></small>
                                  <small className="ml-auto" title="Current value">{utils.Round(data.GPU)} %</small>
                                </div>
                                <Progress size="lg" color="info" value={data.GPU} className="progress-xs" />
                                <small className="text-muted">{`${data.GPU_proc} Processes`}</small>
                              </DropdownItem>)
                  }
                  <DropdownItem className="px-1">
                    <div className="text-uppercase mb-1 d-flex">
                      <small><b>CPU Temp</b></small>
                      <small className="ml-auto" title="Current value">{utils.Round(data.CPU_temp)} &deg;C</small>
                    </div>
                    <Progress size="xs" color="danger" value={data.CPU_temp} className="progress-xs mb-1" />
                  </DropdownItem>            

                  {data && data.Crashes && data.Crashes.length > 0 &&
                    <React.Fragment>
                      <DropdownItem header tag="div" color="light">
                        <strong>APPLICATION FAILURES</strong>
                      </DropdownItem>
                      {data.Crashes.map((o,idx) => {
                        return <DropdownItem className="p-1" key={idx}>
                          <div className="mb-1">
                            <small><b>{o.Name} </b></small>
                            <small className="text-muted"><b>{utils.timeAgo(moment(o.TS)).description}</b></small>
                          </div>
                        </DropdownItem>
                      })}
                  </React.Fragment>
                  }
                </React.Fragment>
            }
          </DropdownMenu>
        </ButtonDropdown>
      }
    </React.Fragment>
  );
}

export default DetailsPC;