import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-dates/initialize';
import 'moment-timezone';
import {
  Card, CardBody, CardHeader
} from 'reactstrap';
import {DetailsRouter} from "../../../_components/display/detailsRouter"

class DisplayRouter extends Component {
  render() {
    const { display } = this.props;
   
    return (
      <div className="animated fadeIn h-100 w-100">
        <Card>
          <CardHeader>
            <i className="fa fa-align-justify" data-toggle="tooltip" data-placement="top" title={display && display.DisplayId}></i>Router Details<small className="text-muted ml-2">{display && display.ProjectName}</small>
          </CardHeader>
          <CardBody>
            <DetailsRouter display={display}></DetailsRouter>
          </CardBody>
        </Card>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { selectedDisplay, display } = state.display;
  return { selectedDisplay, display };
}

const connectedDisplayRouter = connect(mapStateToProps)(DisplayRouter);
export default connectedDisplayRouter;
