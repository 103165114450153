import { authHeader } from "./auth-header";
import axios from 'axios';

let cancelTokens = {};
export function reqOptions(action) {
    let reqOp = {
        headers: authHeader(),
    }

    if (action) {
        if (cancelTokens[action] !== undefined) {
            cancelTokens[action].cancel(`Canceled operation ${action.toString()}`)
        }
        cancelTokens[action] = axios.CancelToken.source()
        reqOp.cancelToken = cancelTokens[action].token
    }

    return reqOp;
}