import { maintenanceConstants } from '../_constants';
import { maintenanceService } from '../_services';

export const maintenanceActions = {
  success,
  error,
  clear,
  getMaintenanceMessages
};

function success(message) {
  return { type: maintenanceConstants.SUCCESS, message };
}

function error(message) {
  return { type: maintenanceConstants.ERROR, message };
}

function clear() {
  return { type: maintenanceConstants.CLEAR };
}

function getMaintenanceMessages() {
  return dispatch => {
    dispatch(request());

    maintenanceService.getMaintenanceMessages()
      .then(
        maintenance => dispatch(success(maintenance)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: maintenanceConstants.GET_MAINTENANCE_MSG_ALL_REQUEST } }
  function success(maintenance) { return { type: maintenanceConstants.GET_MAINTENANCE_MSG_ALL_SUCCESS, maintenance } }
  function failure(error) { return { type: maintenanceConstants.GET_MAINTENANCE_MSG_ALL_FAILURE, error } }
}
