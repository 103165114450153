import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import {brightnessActions} from "../../../api/_actions";
import { Line } from 'react-chartjs-2';
import 'react-dates/initialize';
import { SingleDatePicker, DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import 'moment-timezone';

let options = {
  tooltips: {
    enabled: true,
    displayColors: false,
    callbacks: {
      title: function(tooltipItem, data) {
        return data['labels'][tooltipItem[0]['index']];
      },
      label : function(tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index];
        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return ['Brightness: ' + value + ' %', 'Mode: ' + label.ActualMethod, 'Input Value: ' + label.ControlInput];
      }
    }
  },
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    xAxes: [{
      scaleLabel: {
        labelString: 'Time of Day',
        display: true,
        fontColor: '#fff',
      },
      type: 'time',
      time: {
        unit: 'minute',
        unitStepSize: 60,
        parser: 'HH:mm',
        displayFormats: {
          minute: 'HH:mm',
        },
      },
      ticks: {
        fontColor: '#fff',
        min: '00:00',
        max: '23:59'
      },
      distribution: 'linear',
      gridLines: {
        display: true,
        lineWidth: 2
      },
    }],
    yAxes: [{
      scaleLabel: {
        labelString: 'Brightness (%)',
        display: true,
        fontColor: '#fff',
      },
      type: 'linear',
      ticks: {
        stepSize: 10,
        autoSkip: true,
        min: 0,
        max: 100,
        fontColor: '#fff',
      },
      distribution: 'linear',
      gridLines: {
        display: true,
        lineWidth: 2
      },
    }]
  }
};

class BrightnessHistory extends Component {

  componentDidMount() {
    this.interval = setInterval(() =>
    {
      const { selectedDisplay } = this.props;
      if (selectedDisplay) {
        this.props.dispatch(brightnessActions.getHistory(selectedDisplay, this.state.selectedDate));
      }
    }, 60000);

    const { selectedDisplay } = this.props;
    if (selectedDisplay) {
      this.props.dispatch(brightnessActions.getHistory(selectedDisplay, this.state.selectedDate));
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedDisplay, history } = this.props;
    const { selectedDate } = this.state;
    if (selectedDisplay !== prevProps.selectedDisplay || selectedDate !== prevState.selectedDate) {
      this.props.dispatch(brightnessActions.getHistory(selectedDisplay, selectedDate));
    }
    if (history !== prevProps.history) {
      this.onDatasetChange();
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedDate: moment(),
      focused: null,
      modal: false,
      line: {
        labels: [],
        datasets: [],
      },
      options: options,
    };
    this.onDatasetChange = this.onDatasetChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  onDateChange(selectedDate) {
    this.setState({ selectedDate });
  }

  toggle() {
    if (this.state.modal) {
      this.setState({
        selectedDate: moment(),
        modal: false
      });
    } else {
      this.setState({
        modal: true
      });
    }
  }

  onDatasetChange() {
    const { history, display } = this.props;
    const { selectedDate } = this.state;
    let formatteddata = [];
    let rawdata = [];
    let labelset = [];
    let timezone = (display && display.Timezone) ? display.Timezone : 'UTC';
    if (history && Array.isArray(history)) {
      history.forEach(function(element) {
        let tmpEntry = moment.utc(element.ControlTimestamp);
        //this compares to ensure this is on the selected day based on the display's timezone
        if (tmpEntry && display && tmpEntry.tz(timezone).date() === selectedDate.date()) {
          rawdata.push(element);
          formatteddata.push(element.ControlOutput);
          labelset.push(tmpEntry.tz(timezone).format("HH:mm"));
        }
      });
    }
    let dataset = {
      steppedLine: 'before',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      label: rawdata,
      data: formatteddata,
    };

    //get timezone
    let timezoneString = "UTC";
    if (display && history && history[0]) {
      let tmpEntry = moment.utc(history[0].ControlTimestamp);
      timezoneString = tmpEntry.tz(timezone).format('z');
    }

    this.setState({
      line: Object.assign({}, this.state.line, {
        datasets: [dataset],
        labels: labelset
      }),
      options: Object.assign({}, this.state.options, {
        ...this.state.options,
        scales: {
          ...this.state.options.scales,
          xAxes: [{
            ...this.state.options.scales.xAxes[0],
            scaleLabel: {
              ...this.state.options.scales.xAxes[0].scaleLabel,
              labelString: 'Time of Day (' + timezoneString + ')',
            }
          }]
        }
      }),
    });
  }

  render() {

    const { display } = this.props;
    return (
      <div className="animated fadeIn h-100 w-100">
        <Card className="h-100 w-100 mb-0">
          <CardHeader>
            <div className="d-flex justify-content-between">
            <div><i className="fa fa-align-justify"></i> Brightness History</div>
              <button type="button" className="brightness-btn btn btn-primary btn-sm my-0" onClick={this.toggle}>
              Full Screen
            </button>
            </div>
          </CardHeader>
          <CardBody>
            <div className="h-100">
              <Line data={this.state.line} options={this.state.options} />
            </div>
          </CardBody>
        </Card>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="custom-modal">
          <ModalHeader toggle={this.toggle}>Brightness History</ModalHeader>
          <ModalBody>
            <div className="container-fluid">
                <div className="row">
                  <div className="col-md-7 h-100">
                    <div className="d-flex justify-content-center"><h2>{display && display.CompanyName} {display && display.ProjectName}</h2></div>
                    <Line data={this.state.line} options={this.state.options} />
                 </div>
                <div className="col-md-auto">
                  <SingleDatePicker
                    small={false}
                    block={true}
                    date={this.state.selectedDate}
                    readOnly={true}
                    onDateChange={()=>{}}
                    onFocusChange={()=>{}}
                    focused={false}
                    hideKeyboardShortcutsPanel={true}
                  />
                  <DayPickerSingleDateController
                    numberOfMonths={1}
                    date={this.state.selectedDate}
                    isOutsideRange={(day) => {
                      return !day.isSameOrBefore(moment(), 'day');
                    }}
                    focused={this.state.focused}
                    onDateChange={date => this.onDateChange(date)}
                    onFocusChange={({ focused }) =>
                      this.setState({ focused })
                    }
                    hideKeyboardShortcutsPanel={true}
                  />
                </div>
                </div>
              </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { selectedDisplay, display } = state.display;
  const { history } = state.brightness;
  return {
    selectedDisplay, display, history
  };

}

const connectedBrightnessHistory = connect(mapStateToProps)(BrightnessHistory);
export default connectedBrightnessHistory;
