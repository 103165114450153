import { commentsConstants } from '../_constants';
import { commentsService } from '../_services';

export const commentsActions = {
  getComments,
};

function getComments() {
  return dispatch => {
    dispatch(request());

    commentsService.getComments()
      .then(
        comments => dispatch(success(comments)),
        error => {dispatch(failure(error))}
      );
  };

  function request() { return { type: commentsConstants.GET_COMMENTS_REQUEST } }
  function success(comments) { return { type: commentsConstants.GET_COMMENTS_SUCCESS, comments } }
  function failure(error) { return { type: commentsConstants.GET_COMMENTS_FAILURE, error } }
}