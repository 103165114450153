export const alertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR',

  GET_ALERTS_ALL_REQUEST: 'GET_ALERTS_ALL_REQUEST',
  GET_ALERTS_ALL_SUCCESS: 'GET_ALERTS_ALL_SUCCESS',
  GET_ALERTS_ALL_FAILURE: 'GET_ALERTS_ALL_FAILURE',

  GET_ALERTS_STATS_REQUEST: 'GET_ALERTS_STATS_REQUEST',
  GET_ALERTS_STATS_SUCCESS: 'GET_ALERTS_STATS_SUCCESS',
  GET_ALERTS_STATS_FAILURE: 'GET_ALERTS_STATS_FAILURE'

};
