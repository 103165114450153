import React, { Component } from 'react'
import { connect } from 'react-redux'
import {displayActions} from "../../../api/_actions"
import { Spinner } from "../../../_components/Spinner"
import PropTypes from 'prop-types'
import {
  MdCheck,
  MdDoNotDisturbAlt
} from 'react-icons/md';
import moment from 'moment';
import 'moment-timezone';
import "spinkit/css/spinkit.css"
import _ from 'lodash'

// =======================================
// This is a wrapper for any Display field that needs to be updated
// it provides:
//      - CRUD operation of a Display field
//      - Spinner indicating saving in progress
//      - Error message
// Example:
//  <DisplayFieldEdit fieldName="Monitoring" display={display} render={(state, save)=>{}} />
// =======================================
class DisplayFieldEdit extends Component {

  constructor(props) {
    super(props)

    this.state = {
      [props.fieldName]: _.get(props, `display.${props.fieldName}`, 
                         _.get(props, `image.${props.fieldName}`, null)),
      isDirty: false,
      saveError: null,
      isHovering: false,
    }
    this.onFieldChanged = this.onFieldChanged.bind(this)
    this.saveField = this.saveField.bind(this)
    this.cancelChanges = this.cancelChanges.bind(this)
    this.confirmSave = this.confirmSave.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && !nextProps.saving && nextProps.actionKey===nextProps.fieldName) {
      this.setState({
        isDirty: false,
        saveError: null,
        isHovering: false,
      })
    }
  }

  handleMouseHover = (newVal) => {
    this.setState({isHovering: newVal})
  }
  confirmSave = () => window.confirm("Save changes?")
  cancelChanges() {
    const oldVal =  _.get(this.props, `display.${this.props.fieldName}`, 
                    _.get(this.props, `image.${this.props.fieldName}`, null))
    this.setState({
      [this.props.fieldName]: oldVal,
      isDirty: false
    })
  }
  onFieldChanged(event) {
    // get input value
    const target = event.currentTarget || event.target
    let newVal = null
    switch (target.type) {
      case "checkbox":
      case "radio": 
        newVal = target.checked
        break
      case "date":  // conver the time to local Browser Timezone and format the output
        newVal =  target.value ? moment.tz(target.value, moment.tz.guess()).format(moment.HTML5_FMT.DATE) : null
        break
      case "button":
        newVal = (target.value === "false") ? true : !target.value
        event && event.stopPropagation && event.stopPropagation()
        break
      default:
        newVal = target.value
        break
    }
    const oldVal =  _.get(this.props, `display.${this.props.fieldName}`, 
                    _.get(this.props, `image.${this.props.fieldName}`, null))

    // confirm save or rollback 
    if (this.props.hideButtons && !this.confirmSave()) {
      target.checked = oldVal
      return false
    }
    
    // check if we should save the changes
    let callBack = (this.props.hideButtons) ? () => this.saveField() : null

    // update the dirty state + save the changes (optional)
    let isDirty = ((this.props.display || this.props.image) && newVal !== oldVal)
    this.setState({isDirty, [this.props.fieldName]:newVal}, callBack)
    return false
  }
  saveField() {    
    if (this.state.isDirty && (this.props.display || this.props.image)) {

      // confirm save
      if (!this.props.hideButtons && !this.confirmSave()) {
        return false
      }

      // update fields
      const DisplayId = _.get(this, "props.display.DisplayId", _.get(this, "props.image.DisplayId"))
      const updateObj = {[this.props.fieldName]: this.state[this.props.fieldName]}

      this.props.dispatch(displayActions.putDisplay(DisplayId, updateObj, this.props.fieldName));

    } else {
      this.setState({saveError: "Nothing to save"})
    }
  }
  handleMouseHover = (newVal) => {
    this.setState({isHovering: newVal})
  }

  render() {
    const { saving, saveError, actionKey, fieldName, hideButtons } = this.props;
    return (
        <div
          onMouseEnter={this.handleMouseHover.bind(this,1)}
          onMouseLeave={this.handleMouseHover.bind(this,0)}
          className={this.props.className || "flex-fill"}
        >
            {/* render the input field  */}
            {this.props.render(this.state, this.onFieldChanged)}

            {/* render edit buttons */}
            {(!hideButtons) && 
              <React.Fragment>
                <button type="button"
                        title={this.state.isDirty ? "Save" : "There are no changes to be saved"}
                        className={`btn btn-link p-0 mr-2 ${this.state.isHovering ? "" :"d-none"} ${this.state.isDirty ? "" :" disabled"}`}
                        onClick={this.saveField}                                
                >
                  <MdCheck size={20}/>
                </button>
                <button type="button"
                        title={this.state.isDirty ? "Cancel the changes" : "There are no changes to be cancelled"}
                        className={`btn btn-link p-0 mr-2 ${this.state.isHovering ? "" :"d-none"} ${this.state.isDirty ? "" :" disabled"}`}
                        onClick={this.cancelChanges}
                >
                  <MdDoNotDisturbAlt size={20}/>
                </button>
              </React.Fragment>
            }

            {/* show child elements */}
            {(this.props.children)}

            {/* show saving progress/result */}
            {actionKey===fieldName && 
              <React.Fragment>
                {Spinner(saving, true)}
                {saveError && (
                <div className="text-danger small"><b>Error:</b> {JSON.stringify(saveError)}</div>
                )}
              </React.Fragment>
            }
        </div>
    )   
  }
}

DisplayFieldEdit.propTypes = {
    render: PropTypes.func.isRequired,
    fieldName: PropTypes.string.isRequired,
}


function mapStateToProps(state) {
  const { selectedDisplay, display, detail, saveError, saving, actionKey } = state.display
  const { user } = state.authentication
  return {
    selectedDisplay, display, detail, user, saveError, saving, actionKey
  }
}

const connectedDisplayFieldEdit = connect(mapStateToProps)(DisplayFieldEdit)
export default connectedDisplayFieldEdit
