import React, { Component } from 'react';
import 'react-dates/initialize';
import _ from 'lodash';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Modal, ModalHeader, ModalBody, // ModalFooter,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  MdClose, MdApps, MdFormatListBulleted, MdVisibility,
} from 'react-icons/md';
import 'moment-timezone';
import moment from 'moment';
import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities'
import { utils } from '../../utils';
import MonitorFilter from "./monitorFilter"

const NOT_SET = Symbol("not set")

export default class MonitorCard extends Component {
  constructor(props) {
    super(props);

    this.handleConfigChange = this.handleConfigChange.bind(this)
    this.getFilteredData = this.getFilteredData.bind(this)

    const user = utils.getUser();
    const adminRole = (_.get(user, "payload.role") === "admin")

    const defaultConfig = _.get(this.props, "cfg", null)
    const dataFiltered = this.getFilteredData(this.props.data, defaultConfig, this.props.loading)
    this.state = {
      params: this.props.params,
      expandStats: false,
      previewSnapshot: null,
      showChart: null,
      adminRole: adminRole,
      config: defaultConfig,
      dataFiltered: dataFiltered,
    }
  }
  componentWillReceiveProps(nextProps) {
    const paramsChanged = (nextProps.params !== this.props.params) ||
      (nextProps.data !== this.props.data);

    if (paramsChanged) {
      this.setState({
        dataFiltered: this.getFilteredData(nextProps.data, this.state.config, nextProps.loading),
      })
    }
  }

  handleConfigChange(newConfig) {
    const newCfg = Object.assign({}, this.state.config, newConfig)
    this.setState({
      config: newCfg,
      dataFiltered: this.getFilteredData(this.props.data, newCfg, this.props.loading),
    })
  }

  toggle = (name, val = NOT_SET) => {
    val = (val === NOT_SET) ? !this.state[name] : val
    this.setState({ [name]: val });
  }

  getDatasetOptions = (key, count) => {
    let r = {
      _datasetKey: key,
      _colorName: "primary",
      label: key,
      borderDash: [],
      borderWidth: 2,
      backgroundColor: 'transparent',
      yAxisID: 'y-axis-scalar',
      xAxisID: 'x-axis-numeric',
      pointHitRadius: count < 300 ? 5 : 2,
      radius: count < 20 ? 2 : 0
    }
    if (key === "lx") {
      r.label = "Photocell"
      r._colorName = "primary"
      r.yAxisID = 'y-axis-log'
    } else if (key === "rx") {
      r.label = "Receiver"
      r._colorName = "warning"
    } else if (key === "md") {
      r.label = "Module"
      r._colorName = "success"
    } else if (key === "wx") {
      r.label = "Weather"
      r._colorName = "info"
      r.backgroundColor = hexToRgba(getStyle('--info'), 10)
    } else if (key === "pc") {
      r.label = "CPU"
      r._colorName = "secondary"
    } else if (key === "delta") {
      r.label = "Delta"
      r._colorName = "danger"
      r.borderWidth = 1
      r.borderDash = [8, 5]
    }
    r.borderColor = getStyle('--' + r._colorName)
    return r
  }

  getCardColor = (val) => {
    val = Array.isArray(val) ? val.length : val
    if (val > 0) return "danger";
    else return "info";
  }

  getFilteredData(displays, filters, loading = false) {
    let data = null;

    if (!loading) {
      data = (displays || [])
      filters = filters || {}
      const filterStatuses = (filters.statuses || [])
      const filterFunc = (item) => filterStatuses.includes(item && item.Status)
      let doFilter = (filterStatuses && filterStatuses.length)
      data = doFilter
        ? filters.filterFn
          ? filters.filterFn(data, filterStatuses)
          : data.filter(filterFunc)
        : data
    }

    return data
  }

  render() {
    const { config, adminRole } = this.state
    const data = _.get(this.state, "dataFiltered")
    const cardColor = this.getCardColor(data)

    const snapUrl = utils.getSnapUrl(_.get(this.state, "previewSnapshot.SnapPath"))
    const closePreview = this.toggle.bind(this, "previewSnapshot", null)
    const closeChart = this.toggle.bind(this, "showChart", null)
    const statusEnum = this.props.statusEnum
    const showMonitorStatus = _.get(this.props, "showMonitorStatus", true)

    const expandButton = (name) => {
      return (
        <Button size="sm"
          className="p-0 btn-transparent float-right"
          title={(!this.state[name]) ? "Show displays list" : "Collapse displays list"}
          onClick={this.toggle.bind(this, name, NOT_SET)}
        >
          {(this.state[name]
            ? <MdApps size={16} className="align-bottom" />
            : <MdFormatListBulleted size={16} className="align-bottom" />)}
        </Button>)
    }
    const closeButton = <button type="button"
      className={"btn btn-transparent p-0 text-default"}
      onClick={this.toggle.bind(this, "previewSnapshot", null)}
      title="Close">
      <MdClose size={20} />
    </button>

    const DataDetails = this.props.dataDetails
    const DataShort = this.props.dataShort
    const Download = this.props.download
    const recent = this.props.highlightRecent

    return (
      <React.Fragment>
        <Card className={`text-white bg-${cardColor}`}>
          <CardHeader>
            {data && data.length > 0 && expandButton("expandStats")}
            {data && data.length > 0 && Download}
            {(this.props.chart) && <Button size="sm" className="p-0 btn-transparent float-right icon-chart mr-1" onClick={this.toggle.bind(this, "showChart", true)}></Button>}
            <div className="text-value text-nowrap overflow-hidden">
              <span className="mr-2 pointer" title={utils.textMultiline(this.props.toolTip)} data-tip={this.props.toolTip} onClick={data && data.length ? this.toggle.bind(this, "expandStats", NOT_SET) : null} >{this.props.title}</span>
              {data && data.length > 0 && !this.props.hideWarning &&
                <Badge color="danger mx-2 shadow font-xs float-right">WARNING</Badge>
              }
            </div>
            <div className="d-inline">
              {data && data.length
                ? (data.length || 0) + " displays"
                : "There are no issues detected"
              }
            </div>
            {
              (config && (config.statuses || (data && data.length > 0))) &&
              <MonitorFilter statusEnum={statusEnum} configCard={config} onConfigChange={this.handleConfigChange} />
            }
          </CardHeader>
          {this.state.expandStats && data &&
            <CardBody className="p-0">
              {data.map(item => {
                const status = (utils.enumToString(statusEnum, item.Status) || "n/a").toUpperCase()
                const displayStatus = item.DisplayStatus ? item.DisplayStatus : null
                const displayVOP = _.get(item, "Status.OperatingPercentage.Visual") || ""
                const [, , displayColor] = utils.getDisplayStatusColor(_.get(item, "DisplayStatus"), displayVOP)
                const lastBeat = typeof (item.UpdatedAt) === "undefined"
                  ? undefined
                  : typeof (item.UpdatedAt) === "number"
                    ? moment.tz(item.UpdatedAt, "UTC").utc().tz(moment.tz.guess())
                    : moment.tz(item.UpdatedAt, "YYYY-MM-DD hh:mm:ss", "UTC").tz(moment.tz.guess())
                const lastBeatAgo = utils.timeAgo(lastBeat, null, "minutes")
                const highlight = recent && (lastBeatAgo.minutes < recent)

                return (
                  <Row key={item.DisplayId} className={"mx-0 mt-2 " + (highlight ? "bg-darker-30 shadow-sm" : "")}>
                    <Col className="px-1">
                      <Link to={`/status/${item.DisplayId}`} className="btn btn-transparent bg-darker-20 text-light text-left text-truncate w-100 p-0" title={item.ProjectName}>{item.ProjectName}</Link>
                      {DataShort && <DataShort item={item} dataKey={this.props.dataShortKey} adminRole={adminRole} />}
                      <div style={{ display: "flow-root" }}>
                        {showMonitorStatus
                          ? <React.Fragment>
                            <Badge className={`h5 shadow-sm bg-lighter-50 alert-${item.Status === 0 ? "success" : "danger"}`} title={`Monitor status: ${item.Status}`}>{status}</Badge>
                            <span className="text-muted mx-1">{lastBeatAgo.description}</span>
                          </React.Fragment>
                          : <span></span>}
                        <div className="d-inline">
                          <button type="button" className={"btn btn-transparent btn-sm float-right mx-1 p-0"} title="Preview Snapshot">
                            <MdVisibility size="16" onClick={this.toggle.bind(this, "previewSnapshot", item)} />
                          </button>
                          {DataDetails && <DataDetails item={item} />}
                          <Badge className={`bg-${displayStatus === "offline" ? "darker-10" : displayColor} ml-2 mt-1 float-right text-muted`} title={`Display status: ${displayStatus}`}>{displayStatus}</Badge>
                        </div>
                      </div>
                      <hr className="my-1" />
                    </Col>
                  </Row>
                )
              }
              )}
            </CardBody>}
        </Card>

        <Modal isOpen={!!this.state.previewSnapshot} toggle={closePreview} className="camera-full-screen">
          <ModalHeader toggle={closePreview} close={closeButton} className="flex-parent">
            <Link to={`/status/${_.get(this.state, "previewSnapshot.DisplayId")}`} className={"no-wrap"} title="Project name">{_.get(this.state, "previewSnapshot.ProjectName")}</Link>
          </ModalHeader>
          <ModalBody>
            <img src={snapUrl} onError={utils.showNoImage} alt="Billboard snapshot" width="67%" onClick={closePreview} />
          </ModalBody>
        </Modal>

        <Modal isOpen={!!this.state.showChart} toggle={closeChart} className="camera-full-screen">
          <ModalHeader toggle={closeChart} className="flex-parent">
            {this.props.chartTitle}
          </ModalHeader>
          <ModalBody>
            <div className="chart-container vhd-100" style={{ height: "60vh" }}>
              {this.props.chart && <this.props.chart data={this.props.chartData} />}
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
};
