import { reqOptions } from '../_helpers';
import { authService } from './';
import axios from 'axios';
import { config } from '../_config';
const apiUrl = config.apiUrl
// const apiUrl = "http://localhost:3002";  // <---- ONLY for testing

export const profileService = {
  getUserProfile,
  putUserProfile,
  postMfaSetup,
  patchMfaSetup
};

// simulate long api call. Usage: .then(wait(2000))
// const wait = ms => (v) => new Promise(resolve => setTimeout(() => resolve(v), ms));

function getUserProfile(username) {
  const requestOptions = reqOptions()
  return axios.get(`${apiUrl}/auth/profile`, requestOptions)
    // .then(wait(2000))
    .then(authService.handleResponseAxios);
}

function putUserProfile(username, profile) {
  const requestOptions = reqOptions()
  return axios.put(`${apiUrl}/auth/profile`, profile, requestOptions)
    // .then(wait(2000))
    .then(authService.handleResponseAxios);
}

function postMfaSetup() {
  const requestOptions = reqOptions()
  return axios.post(`${apiUrl}/auth/mfa-setup`, null, requestOptions)
    // .then(wait(2000))
    .then(authService.handleResponseAxios);
}

function patchMfaSetup(profile) {
  const requestOptions = reqOptions()
  return axios.patch(`${apiUrl}/auth/mfa-setup`, profile, requestOptions)
    // .then(wait(2000))
    .then(authService.handleResponseAxios);
}
