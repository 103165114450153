/* eslint-disable import/first */
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Button, Modal, ModalBody, ModalFooter, ModalHeader, ButtonDropdown, Table, DropdownItem, DropdownToggle, DropdownMenu, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import { brightnessActions } from "../../../api/_actions";
import { utils } from "../../../utils"
import { Line } from 'react-chartjs-2';
// import * as chartjs from 'chart.js';
import 'chartjs-plugin-annotation';
import 'react-dates/initialize';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import 'moment-timezone';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-time-picker/assets/index.css';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'flatpickr/dist/themes/dark.css';
import BrightnessTable from './BrightnessTable';

const Range = createSliderWithTooltip(Slider.Range);
const TooltipSlider = createSliderWithTooltip(Slider);

let timeOptions = {
  annotation: {
    drawTime: 'afterDatasetsDraw', // (default)
    annotations: [{
      id: 'sunrise',
      type: 'line',
      mode: 'vertical',
      scaleID: 'x-axis',
      value: '',
      borderColor: 'gray',
      borderWidth: 2,
      borderDash: [2, 2],
      borderDashOffset: 5,
      label: {
        backgroundColor: 'rgba(255,255,0,0.8)',
        fontFamily: "sans-serif",
        fontSize: 12,
        fontStyle: "bold",
        fontColor: "#000",
        xPadding: 6,
        yPadding: 6,
        cornerRadius: 6,
        position: "top",
        yAdjust: 10,
        enabled: true,
        content: "Sunrise"
      }
    },
    { 
    id: 'sunset',
    type: 'line',
    mode: 'vertical',
    scaleID: 'x-axis',
    value: '',
    borderColor: 'gray',
    borderWidth: 2,
    borderDash: [2, 2],
    borderDashOffset: 5,
    label: {
      backgroundColor: 'rgba(0,255,255,0.8)',
      fontFamily: "sans-serif",
      fontSize: 12,
      fontStyle: "bold",
      fontColor: "#000",
      xPadding: 6,
      yPadding: 6,
      cornerRadius: 6,
      position: "top",
      yAdjust: 10,
      enabled: true,
      content: "Sunset"
    }
  }]
  },
  tooltips: {
    enabled: true,
    displayColors: false,
    callbacks: {
      title: function(tooltipItem, data) {
        return data['labels'][tooltipItem[0]['index']];
      },
      label : function(tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index];
        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        let modifier = 'None';
        if (label && label.Sunrise) modifier = 'Sunrise';
        else if (label && label.Sunset) modifier = 'Sunset';
        return ['Brightness: ' + value + ' %', 'Modifier: ' + modifier];
      }
    }
  },
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    xAxes: [{
      scaleLabel: {
        labelString: 'Time of Day',
        display: true,
        fontColor: '#fff',
      },
      type: 'time',
      time: {
        unit: 'minute',
        unitStepSize: 60,
        parser: 'HH:mm',
        displayFormats: {
          minute: 'HH:mm',
        },
      },
      ticks: {
        fontColor: '#fff',
        min: '00:00',
        max: '23:59'
      },
      distribution: 'linear',
      gridLines: {
        display: true,
        lineWidth: 2
      },
    }],
    yAxes: [{
      scaleLabel: {
        labelString: 'Brightness (%)',
        display: true,
        fontColor: '#fff',
      },
      type: 'linear',
      ticks: {
        stepSize: 10,
        autoSkip: true,
        min: 0,
        max: 100,
        fontColor: '#fff',
      },
      distribution: 'linear',
      gridLines: {
        display: true,
        lineWidth: 2
      },
    }]
  }
};

let photocellOptions = {
  annotation: null,
  tooltips: {
    enabled: true,
    displayColors: false,
    callbacks: {
      title: function(tooltipItem, data) {
        return data['labels'][tooltipItem[0]['index']];
      },
      label : function(tooltipItem, data) {
        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return ['Input Value: ' + value.x + ' lux', 'Brightness: ' + value.y + ' %'];
      }
    }
  },
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    xAxes: [{
      id: 'x-axis',
      scaleLabel: {
        labelString: 'Ambient Brightness (lux)',
        display: true,
        fontColor: '#fff',
      },
      type: 'logarithmic',
      ticks: {
        callback: function(value, index, values) {
          return value;
        },
        min: 1,
        max: 100000,
        autoSkipPadding: 10,
        autoSkip: false,
        fontColor: '#fff',
      },
      distribution: 'linear',
      gridLines: {
        display: true,
        lineWidth: 2
      },
    }],
    yAxes: [{
      id: 'y-axis',
      scaleLabel: {
        labelString: 'Brightness (%)',
        display: true,
        fontColor: '#fff',
      },
      type: 'linear',
      ticks: {
        stepSize: 10,
        autoSkip: true,
        min: 0,
        max: 100,
        fontColor: '#fff',
      },
      distribution: 'linear',
      gridLines: {
        display: true,
        lineWidth: 2
      },
    }]
  }
};

class BrightnessConfig extends Component {

  smallChartRef = {};
  modalChartRef = {};

  componentDidMount() {
    const { selectedDisplay } = this.props;
    if (selectedDisplay) {
      this.props.dispatch(brightnessActions.getConfig(selectedDisplay));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedDisplay, config } = this.props;
    const { editConfig, activeProfileTab } = this.state;

    //hack for the chart.
    if (this.state.editOptions !== prevState.editOptions) {
      if (this.smallChartRef && this.smallChartRef.chartInstance)
      {
        if (this.state.editOptions.annotation !== null && this.smallChartRef.chartInstance.annotation.elements['sunrise'] && this.smallChartRef.chartInstance.annotation.elements['sunset']) {
          this.smallChartRef.chartInstance.annotation.elements['sunrise'].options.value = this.state.editOptions.annotation.annotations[0].value;
          this.smallChartRef.chartInstance.annotation.elements['sunset'].options.value = this.state.editOptions.annotation.annotations[1].value;
        }
        this.smallChartRef.chartInstance.update();
      } 
      if (this.modalChartRef && this.modalChartRef.chartInstance) {
        if (this.state.editOptions.annotation !== null && this.modalChartRef.chartInstance.annotation.elements['sunrise'] && this.modalChartRef.chartInstance.annotation.elements['sunset']) {
          this.modalChartRef.chartInstance.annotation.elements['sunrise'].options.value = this.state.editOptions.annotation.annotations[0].value;
          this.modalChartRef.chartInstance.annotation.elements['sunset'].options.value = this.state.editOptions.annotation.annotations[1].value;
        }
        this.modalChartRef.chartInstance.update();
      }
    }

    //New display selected
    if (selectedDisplay !== prevProps.selectedDisplay) {
      this.props.dispatch(brightnessActions.getConfig(selectedDisplay));
    }
    //New configuration loaded
    if (config !== prevProps.config) {
      this.onMainDatasetChange();
    }
    //Edit Profile selection changed OR switched to backup mode
    if (editConfig && (editConfig.ProfileSelected !== prevState.editConfig.ProfileSelected || activeProfileTab !== prevState.activeProfileTab)) {
      if (editConfig.ProfileSelected === "Time" || (editConfig.ProfileSelected === "Photocell" && activeProfileTab === "time")) {
        if (editConfig.Time && editConfig.Time.Basic && editConfig.Time.Mode === "Basic") {
          this.onEditDatasetChange(this.calculateTimeTable(editConfig.Time.Basic.Min, editConfig.Time.Basic.Max, editConfig.Time.Basic.RiseTime, editConfig.Time.Basic.RiseWindow, editConfig.Time.Basic.SetTime, editConfig.Time.Basic.SetWindow, editConfig.Time.Basic.Track))
        }
        else {
          this.onEditDatasetChange();
        }
      }
      else if (editConfig.ProfileSelected === "Photocell") {
        if (editConfig.Photocell && editConfig.Photocell.Basic && editConfig.Photocell.Mode === "Basic") {
          this.onEditDatasetChange(this.calculatePhotocellTable(editConfig.Photocell.Basic.Min, editConfig.Photocell.Basic.Max, editConfig.Photocell.Basic.Ramp, editConfig.Photocell.Basic.Shift))
        }
        else {
          this.onEditDatasetChange();
        }
      }
      else {
        this.onEditDatasetChange();
      }
      
      this.checkChanges();
    }

    //Photocell config changed
    if (editConfig && editConfig.Photocell &&
        (!prevState.editConfig || !prevState.editConfig.Photocell || (editConfig.Photocell !== prevState.editConfig.Photocell)))
    {
      //if the basic config changed, just update the preview (for speed)
      if (editConfig.Photocell && editConfig.Photocell.Mode === "Basic")
      {
        this.onEditDatasetChange(this.calculatePhotocellTable(editConfig.Photocell.Basic.Min, editConfig.Photocell.Basic.Max, editConfig.Photocell.Basic.Ramp, editConfig.Photocell.Basic.Shift));
      }
      //if we are transitioning to Advanced, persist the basic settings into the advanced table
      else if (editConfig.Photocell && prevState.editConfig.Photocell && editConfig.Photocell.Mode === "Advanced" && prevState.editConfig.Photocell.Mode === "Basic")
      {
        this.updatePhotocellTable(this.calculatePhotocellTable(editConfig.Photocell.Basic.Min, editConfig.Photocell.Basic.Max, editConfig.Photocell.Basic.Ramp, editConfig.Photocell.Basic.Shift));
      }
      else {
        this.onEditDatasetChange();
      }
      this.checkChanges();
    }

    //Time basic config changed
    else if (editConfig && editConfig.Time &&
        (!prevState.editConfig || !prevState.editConfig.Time || (editConfig.Time !== prevState.editConfig.Time)))
    {
      if (editConfig.Time && editConfig.Time.Mode === "Basic")
      {
        this.onEditDatasetChange(this.calculateTimeTable(editConfig.Time.Basic.Min, editConfig.Time.Basic.Max, editConfig.Time.Basic.RiseTime, editConfig.Time.Basic.RiseWindow, editConfig.Time.Basic.SetTime, editConfig.Time.Basic.SetWindow, editConfig.Time.Basic.Track))
      }
      //if we are transitioning to Advanced, persist the basic settings into the advanced table
      else if (editConfig.Time && prevState.editConfig.Time && editConfig.Time.Mode === "Advanced" && prevState.editConfig.Time.Mode === "Basic") {
        this.updateTimeTable(this.calculateTimeTable(editConfig.Time.Basic.Min, editConfig.Time.Basic.Max, editConfig.Time.Basic.RiseTime, editConfig.Time.Basic.RiseWindow, editConfig.Time.Basic.SetTime, editConfig.Time.Basic.SetWindow, editConfig.Time.Basic.Track))
      }
      else
      {
        this.onEditDatasetChange()
      }
      this.checkChanges();
    }

    //hold config changed
    else if (editConfig && editConfig.Hold &&
        (!prevState.editConfig || !prevState.editConfig.Hold || (editConfig.Hold !== prevState.editConfig.Hold))) {
      this.onEditDatasetChange();
      this.checkChanges();
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      focused: null,
      editModal: false,
      line: {
        labels: [],
        datasets: [],
      },
      activeProfileTab: null,
      activeModeTab: null,
      options: timeOptions,
      dropdownOpen: new Array(3).fill(false),
      editConfig: {
        ProfileSelected: null,
        Photocell: null,
        Time: null,
        Hold: null,
      },
      editLine: {
        labels: [],
        datasets: [],
      },
      pendingChanges: false,
      editOptions: timeOptions
    };
    this.loadPhotocellConfig = this.loadPhotocellConfig.bind(this);
    this.loadTimeConfig = this.loadTimeConfig.bind(this);
    this.loadStaticConfig = this.loadStaticConfig.bind(this);
    this.onMainDatasetChange = this.onMainDatasetChange.bind(this);
    this.onEditDatasetChange = this.onEditDatasetChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleDrop = this.toggleDrop.bind(this);
    this.toggleProfile = this.toggleProfile.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.toggleBackupMode = this.toggleBackupMode.bind(this);
    this.loadCurrentConfig = this.loadCurrentConfig.bind(this);
    this.saveConfig = this.saveConfig.bind(this);
    this.checkChanges = this.checkChanges.bind(this);
    this.calculatePhotocellTable = this.calculatePhotocellTable.bind(this);
    this.photocellRange = this.photocellRange.bind(this);
    this.calculateTimeTable = this.calculateTimeTable.bind(this);
    this.formatTime = this.formatTime.bind(this);
    this.toggleTrack = this.toggleTrack.bind(this);
    this.loadTimeDefault = this.loadTimeDefault.bind(this);
    this.loadPhotocellDefault = this.loadPhotocellDefault.bind(this);
    this.handlePhotocellRangeChange = this.handlePhotocellRangeChange.bind(this);
    this.handlePhotocellRampChange = this.handlePhotocellRampChange.bind(this);
    this.handlePhotocellShiftChange = this.handlePhotocellShiftChange.bind(this);
    this.handleTimeRangeChange = this.handleTimeRangeChange.bind(this);
    this.handleRiseTimeChange = this.handleRiseTimeChange.bind(this);
    this.handleRiseWindowChange = this.handleRiseWindowChange.bind(this);
    this.handleSetTimeChange = this.handleSetTimeChange.bind(this);
    this.handleSetWindowChange = this.handleSetWindowChange.bind(this);
    this.handleHoldValueChange = this.handleHoldValueChange.bind(this);
    this.updatePhotocellTable = this.updatePhotocellTable.bind(this);
    this.updateTimeTable = this.updateTimeTable.bind(this);

    this.photocellColumns = [
      {
        dataField: 'Input',
        text: 'Ambient (Lux)',
        formatter: this.inputFormatter
      },
      {
        dataField: 'Output',
        text: 'Brightness (%)',
        formatter: this.outputFormatter
      },
      {
        dataField: 'Controls',
        isDummyField: true,
        text: '',
        formatter: this.controlFormatter
      }
    ];
  }

  updatePhotocellTable(newTable) {
    this.setState({
      editConfig: Object.assign({}, this.state.editConfig, {
        ...this.state.editConfig,
        Photocell: {
          ...this.state.editConfig.Photocell,
          Table: newTable
        }
      })
    });
    this.checkChanges();
  }

  updateTimeTable(newTable) {
    this.setState({
      editConfig: Object.assign({}, this.state.editConfig, {
        ...this.state.editConfig,
        Time: {
          ...this.state.editConfig.Time,
          Table: newTable
        }
      })
    });
    this.checkChanges();
  }

  saveConfig() {
    const {editConfig} = this.state;
    const {config, selectedDisplay} = this.props;

    if (editConfig.Photocell.Mode !== config.Photocell.Mode || editConfig.Photocell.Table !== config.Photocell.Table || editConfig.Photocell.Basic !== config.Photocell.Basic) {
      this.props.dispatch(brightnessActions.putPhotocellConfig(selectedDisplay, editConfig.Photocell));
    }

    if (editConfig.Time.Mode !== config.Time.Mode || editConfig.Time.Table !== config.Time.Table || editConfig.Time.Basic !== config.Time.Basic) {
      this.props.dispatch(brightnessActions.putTimeConfig(selectedDisplay, editConfig.Time));
    }

    if (editConfig.Hold.Output !== config.Hold.Output) {
      this.props.dispatch(brightnessActions.putHoldConfig(selectedDisplay, editConfig.Hold));
    }

    if (editConfig.ProfileSelected !== config.ProfileSelected) {
      this.props.dispatch(brightnessActions.putSelectedProfile(selectedDisplay, editConfig.ProfileSelected));
    }

    //TODO - wait for the requests to return before doing this... spinner?
    this.toggleModal();
  }

  toggleModal() {
    if (this.state.editModal) {
      this.setState({
        editModal: false
      });
    } else {
      this.loadCurrentConfig();
      this.setState({
        editModal: true
      });
    }
  }

  toggleEditMode() {
    const { editConfig, activeProfileTab } = this.state;
    var curProfile = activeProfileTab.charAt(0).toUpperCase() + activeProfileTab.slice(1);
    if (editConfig[curProfile] && curProfile !== "Hold") {
      if (editConfig[curProfile].Mode.toLowerCase() === 'basic') {
        this.setState({
          editConfig: Object.assign({}, this.state.editConfig, {
            ...this.state.editConfig,
            [curProfile]: {
              ...this.state.editConfig[curProfile],
              Mode: 'Advanced',
            }
          }),
          activeModeTab: "advanced"
        });
      } else {
        this.setState({
          editConfig: Object.assign({}, this.state.editConfig, {
            ...this.state.editConfig,
            [curProfile]: {
              ...this.state.editConfig[curProfile],
              Mode: 'Basic',
            }
          }),
          activeModeTab: "basic"
        });
      }
      this.checkChanges();
    }
  }

  toggleBackupMode() {
    const { editConfig, activeProfileTab } = this.state;
    if (activeProfileTab === "time") {
      this.setState({
        activeProfileTab: "photocell",
        activeModeTab: editConfig.Photocell.Mode.toLowerCase()
      });
    } else {
      this.setState({
        activeProfileTab: "time",
        activeModeTab: editConfig.Time.Mode.toLowerCase()
      });
    }
  }

  toggleDrop(i) {
    const newArray = this.state.dropdownOpen.map((element, index) => { return (index === i ? !element : false); });
    this.setState({
      dropdownOpen: newArray,
    });
  }

  toggleProfile(profile) {
    if (this.state.editConfig.ProfileSelected !== profile) {
      this.setState({
        editConfig: Object.assign({}, this.state.editConfig, {
          ...this.state.editConfig,
          ProfileSelected: profile,
        }),
        activeProfileTab: profile.toLowerCase(),
        activeModeTab: (profile !== "Hold") ? this.state.editConfig[profile].Mode.toLowerCase() : "basic"
      });
    }
  }

  toggleTrack() {
    this.setState({
      editConfig: Object.assign({}, this.state.editConfig, {
        ...this.state.editConfig,
        Time: {
          ...this.state.editConfig.Time,
          Basic: {
            ...this.state.editConfig.Time.Basic,
            Track: !this.state.editConfig.Time.Basic.Track,
          }
        }
      })
    });
  }

  loadTimeDefault() {
    this.setState({
      editConfig: Object.assign({}, this.state.editConfig, {
        ...this.state.editConfig,
        Time: {
          ...this.state.editConfig.Time,
          Basic: {
            Min: 3,
            Max: 80,
            RiseTime: "7:00",
            RiseWindow: 30,
            SetTime: "18:00",
            SetWindow: 30,
            Track: true
          }
        }
      })
    });
  }

  loadPhotocellDefault() {
    this.setState({
      editConfig: Object.assign({}, this.state.editConfig, {
        ...this.state.editConfig,
        Photocell: {
          ...this.state.editConfig.Photocell,
          Basic: {
            Min: 3,
            Max: 80,
            Ramp: 10,
            Shift: 1
          }
        }
      })
    });
  }

  handlePhotocellRangeChange(values) {
    let min = values[0];
    let max = values[1];

    if (this.state.editConfig.Photocell.Basic.Min !== min || this.state.editConfig.Photocell.Basic.Max !== max) {
      this.setState({
        editConfig: Object.assign({}, this.state.editConfig, {
          ...this.state.editConfig,
          Photocell: {
            ...this.state.editConfig.Photocell,
            Basic: {
              ...this.state.editConfig.Photocell.Basic,
              Min: min,
              Max: max
            }
          }
        })
      });
    }
  }

  handlePhotocellShiftChange(shift) {
    if (this.state.editConfig.Photocell.Basic.Shift !== shift) {
      this.setState({
        editConfig: Object.assign({}, this.state.editConfig, {
          ...this.state.editConfig,
          Photocell: {
            ...this.state.editConfig.Photocell,
            Basic: {
              ...this.state.editConfig.Photocell.Basic,
              Shift: shift
            }
          }
        })
      });
    }
  }

  handlePhotocellRampChange(ramp) {
    if (this.state.editConfig.Photocell.Basic.Ramp !== ramp) {
      this.setState({
        editConfig: Object.assign({}, this.state.editConfig, {
          ...this.state.editConfig,
          Photocell: {
            ...this.state.editConfig.Photocell,
            Basic: {
              ...this.state.editConfig.Photocell.Basic,
              Ramp: ramp
            }
          }
        })
      });
    }
  }

  handleTimeRangeChange(values) {
    let min = values[0];
    let max = values[1];

    if (this.state.editConfig.Time.Basic.Min !== min || this.state.editConfig.Time.Basic.Max !== max) {
      this.setState({
        editConfig: Object.assign({}, this.state.editConfig, {
          ...this.state.editConfig,
          Time: {
            ...this.state.editConfig.Time,
            Basic: {
              ...this.state.editConfig.Time.Basic,
              Min: min,
              Max: max
            }
          }
        })
      });
    }
  }

  handleRiseTimeChange(selectedDates, dateStr, instance) {
    if (selectedDates && selectedDates[0]) {
      let timeVal = this.formatTime(selectedDates[0]);

      //handle collisions
      let riseTime = new moment('2000-01-01 ' + timeVal); 
      let riseFinish = riseTime.clone().add(parseInt(this.state.editConfig.Time.Basic.RiseWindow,10), 'minutes');
      let setTime = new moment('2000-01-01 ' + this.state.editConfig.Time.Basic.SetTime);
      let setStart = setTime.clone().subtract(parseInt(this.state.editConfig.Time.Basic.SetWindow,10), 'minutes');
      if (riseFinish.isAfter(setStart)) {
        let newSet = setStart.clone().subtract(parseInt(this.state.editConfig.Time.Basic.RiseWindow,10), 'minutes');
        timeVal = this.formatTime(newSet.toDate());
        instance.setDate(timeVal);
      }

      if (this.state.editConfig.Time.Basic.RiseTime !== timeVal) {
        this.setState({
          editConfig: Object.assign({}, this.state.editConfig, {
            ...this.state.editConfig,
            Time: {
              ...this.state.editConfig.Time,
              Basic: {
                ...this.state.editConfig.Time.Basic,
                RiseTime: timeVal
              }
            }
          })
        });
      }
    }
  }

  handleRiseWindowChange(selectedDates, dateStr, instance) {
    if (selectedDates && selectedDates[0]) {
      let windowVal = selectedDates[0].getHours() * 60 + selectedDates[0].getMinutes();
      windowVal = Math.min(windowVal, 180);

      //handle collisions
      let riseTime = new moment('2000-01-01 ' + this.state.editConfig.Time.Basic.RiseTime); 
      let riseFinish = riseTime.clone().add(windowVal, 'minutes')
      let setTime = new moment('2000-01-01 ' + this.state.editConfig.Time.Basic.SetTime);
      let setStart = setTime.clone().subtract(parseInt(this.state.editConfig.Time.Basic.SetWindow,10), 'minutes');
      if (riseFinish.isAfter(setStart)) {
        windowVal = moment.duration(setStart.diff(riseTime)).asMinutes();
        instance.setDate(Math.floor(windowVal/60)+":"+windowVal%60);
      }

      if (this.state.editConfig.Time.Basic.RiseWindow !== windowVal) {
        this.setState({
          editConfig: Object.assign({}, this.state.editConfig, {
            ...this.state.editConfig,
            Time: {
              ...this.state.editConfig.Time,
              Basic: {
                ...this.state.editConfig.Time.Basic,
                RiseWindow: windowVal
              }
            }
          })
        });
      }
    }
  }

  handleSetTimeChange(selectedDates, dateStr, instance) {
    if (selectedDates && selectedDates[0]) {
      let timeVal = this.formatTime(selectedDates[0]);

      //handle collisions
      let riseTime = new moment('2000-01-01 ' + this.state.editConfig.Time.Basic.RiseTime); 
      let riseFinish = riseTime.clone().add(parseInt(this.state.editConfig.Time.Basic.RiseWindow,10), 'minutes');
      let setTime = new moment('2000-01-01 ' + timeVal);
      let setStart = setTime.clone().subtract(parseInt(this.state.editConfig.Time.Basic.SetWindow,10), 'minutes');
      if (riseFinish.isAfter(setStart)) {
        let newSet = riseFinish.clone().add(parseInt(this.state.editConfig.Time.Basic.SetWindow,10), 'minutes');
        timeVal = this.formatTime(newSet.toDate());
        instance.setDate(timeVal);
      }

      if (this.state.editConfig.Time.Basic.RiseTime !== timeVal) {
        this.setState({
          editConfig: Object.assign({}, this.state.editConfig, {
            ...this.state.editConfig,
            Time: {
              ...this.state.editConfig.Time,
              Basic: {
                ...this.state.editConfig.Time.Basic,
                SetTime: timeVal
              }
            }
          })
        });
      }
    }
  }

  handleSetWindowChange(selectedDates, dateStr, instance) {
    if (selectedDates && selectedDates[0]) {
      let windowVal = selectedDates[0].getHours() * 60 + selectedDates[0].getMinutes();
      windowVal = Math.min(windowVal, 180);

      //handle collisions
      let riseTime = new moment('2000-01-01 ' + this.state.editConfig.Time.Basic.RiseTime); 
      let riseFinish = riseTime.clone().add(parseInt(this.state.editConfig.Time.Basic.RiseWindow,10), 'minutes');
      let setTime = new moment('2000-01-01 ' + this.state.editConfig.Time.Basic.SetTime);
      let setStart = setTime.clone().subtract(windowVal, 'minutes');
      if (riseFinish.isAfter(setStart)) {
        windowVal = moment.duration(setTime.diff(riseFinish)).asMinutes();
        instance.setDate(Math.floor(windowVal/60)+":"+windowVal%60);
      }
  
      if (this.state.editConfig.Time.Basic.SetWindow !== windowVal) {
        this.setState({
          editConfig: Object.assign({}, this.state.editConfig, {
            ...this.state.editConfig,
            Time: {
              ...this.state.editConfig.Time,
              Basic: {
                ...this.state.editConfig.Time.Basic,
                SetWindow: windowVal
              }
            }
          })
        });
      }
    }
  }

  handleHoldValueChange(value) {
    if (this.state.editConfig.Hold.Output !== value) {
      this.setState({
        editConfig: Object.assign({}, this.state.editConfig, {
          ...this.state.editConfig,
          Hold: {
            ...this.state.editConfig.Hold,
            Output: value
          }
        })
      });
    }
  }

  checkChanges() {
    const { config } = this.props;
    const { editConfig } = this.state;

    let changes = false;
    changes = changes || (config.ProfileSelected !== editConfig.ProfileSelected);
    changes = changes || (config.Photocell.Mode !== editConfig.Photocell.Mode);
    //only check for changes to basic if basic is the current mode
    changes = changes || (config.Photocell.Basic !== editConfig.Photocell.Basic && config.Photocell.Mode === "Basic");
    changes = changes || (config.Photocell.Table !== editConfig.Photocell.Table);
    changes = changes || (config.Time.Mode !== editConfig.Time.Mode);
    //only check for changes to basic if basic is the current mode
    changes = changes || (config.Time.Basic !== editConfig.Time.Basic && config.Time.Mode === "Basic");
    changes = changes || (config.Time.Table !== editConfig.Time.Table);
    changes = changes || (config.Hold.Output !== editConfig.Hold.Output);

    this.setState({
      pendingChanges: changes
    });
  }

  loadPhotocellConfig(table) {
    let formatteddata = [];
    let maxPoint = {x:0, y:0};

    table.forEach(function(element) {
      //this compares to ensure this is on the selected day based on the display's timezone
      if (element) {
        formatteddata.push({x:element.Input, y:element.Output});
        if (element.Input > maxPoint.x) {
          maxPoint = {x:element.Input, y:element.Output};
        }
      }
    });

    //final point
    formatteddata.push({x:100000, y:maxPoint.y});

    let dataset = {
      steppedLine: 'before',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: formatteddata,
    };

    return [dataset, photocellOptions];
  }

  loadTimeConfig(table) {
    const { display } = this.props;
    let formatteddata = [];
    let rawdata = [];
    let labelset = [];
    let maxBrightness = 0;
    let maxTime = "00:00";
    let riseTime = null;
    let setTime = null;

    table.forEach(function(element) {
      //this compares to ensure this is on the selected day based on the display's timezone
      if (element) {
        rawdata.push(element);
        formatteddata.push(element.Output);
        labelset.push(element.Input);
        if (Date.parse('1 Jan 1970 ' + element.Input) > Date.parse('1 Jan 1970 ' + maxTime)) {
          maxTime = element.Input;
          maxBrightness = element.Output;
        }
        //set rise or set
        if (element.Sunrise) {
          riseTime = element.Input;
        } else if (element.Sunset) {
          setTime = element.Input;
        }
      }
    });

    //final point
    formatteddata.push(maxBrightness);
    labelset.push("23:59");

    let dataset = {
      steppedLine: 'before',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      label: rawdata,
      data: formatteddata,
    };

    //get timezone
    let timezone = (display && display.Timezone) ? display.Timezone : 'UTC';
    let timezoneString = "UTC";
    if (display) {
      timezoneString = moment().tz(timezone).format('z');
    }

    let tmpAnnotation = null;
    if (riseTime && setTime) {
      tmpAnnotation = Object.assign({}, timeOptions.annotation, {
        annotations: [{
          ...timeOptions.annotation.annotations[0],
          value: riseTime,
        },
        {  
          ...timeOptions.annotation.annotations[1],
          value: setTime,
        }]
      });
    }

    let tmpTimeOptions = Object.assign({}, timeOptions, {
      annotation: tmpAnnotation,
      scales: {
        ...timeOptions.scales,
        xAxes: [{
          ...timeOptions.scales.xAxes[0],
          scaleLabel: {
            ...timeOptions.scales.xAxes[0].scaleLabel,
            labelString: 'Time of Day (' + timezoneString + ')',
          }
        }]
      }
    });

    return [dataset, labelset, tmpTimeOptions];
  }


  loadStaticConfig(output) {
    const { display } = this.props;
    let formatteddata = [];
    let rawdata = [];
    let labelset = [];

    if (output) {
        rawdata.push(output);
        formatteddata.push(output);
        labelset.push("00:00");
        formatteddata.push(output);
        labelset.push("23:59");
      }

    let dataset = {
      steppedLine: 'before',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      label: rawdata,
      data: formatteddata,
    };

    //get timezone
    let timezone = (display && display.Timezone) ? display.Timezone : 'UTC';
    let timezoneString = "UTC";
    if (display) {
      timezoneString = moment().tz(timezone).format('z');
    }

    let tmpTimeOptions = Object.assign({}, timeOptions, {
      ...timeOptions,
      annotation: null,
      scales: {
        ...timeOptions.scales,
        xAxes: [{
          ...timeOptions.scales.xAxes[0],
          scaleLabel: {
            ...timeOptions.scales.xAxes[0].scaleLabel,
            labelString: 'Time of Day (' + timezoneString + ')',
          }
        }]
      }
    });

    return [dataset, labelset, tmpTimeOptions];
  }

  onEditDatasetChange(override) {
    const { editConfig, activeProfileTab } = this.state;
    let dataset = {};
    let options = {};
    let labelset = {};
    if (editConfig && editConfig.ProfileSelected) {
      //we are going to choose the active profile, not just the selected one:
      var activeProfile = editConfig.ProfileSelected;
      if (editConfig.ProfileSelected === "Photocell") {
        activeProfile = activeProfileTab.charAt(0).toUpperCase() + activeProfileTab.slice(1);
      }
      switch(activeProfile) {
        case 'Photocell':
          [dataset, options] = (override) ? this.loadPhotocellConfig(override) : this.loadPhotocellConfig(editConfig.Photocell.Table);

          this.setState({
            editLine: Object.assign({}, this.state.editLine, {
              datasets: [dataset],
            }),
            editOptions: options
          });
          break;
        case 'Time':
          [dataset, labelset, options] = (override) ? this.loadTimeConfig(override) : this.loadTimeConfig(editConfig.Time.Table);
          this.setState({
            editLine: Object.assign({}, this.state.editLine, {
              datasets: [dataset],
              labels: labelset
            }),
            editOptions: Object.assign({}, options)
          });

          break;
        case 'Hold':
          [dataset, labelset, options] = this.loadStaticConfig(editConfig.Hold.Output);

          this.setState({
            editLine: Object.assign({}, this.state.editLine, {
              datasets: [dataset],
              labels: labelset
            }),
            editOptions: options
          });
          break;
        default:
          break;
      }
    }
  }

  calculatePhotocellTable(min, max, slope, shift) {
    let A = (slope / 10);       // for user-understandability slope is nominally [1,100]. We are converting this to [0.1,10]
    let B = (shift / 10) + 0.5; // for user-understandability shift input is [-10,10]. This converts to [0,1] with a value of 0.5 if shift = 0
                                //specifically, this centers it on the semilogx() plot but allows shifting the center either way to the x-axis bounds.
    let H = max / 100;          //max in integer-percent converts to actual percent
    let L = min / 100;          //min in integer-percent converts to actual percent
    let N = 1;                  //For future use. Right now we ignore Nu. This controls the slopeiness at the lower inflection point.


    let x = this.photocellRange(50); //using a function to give me 50 logarithmically-spaced values for x.
    let table = [];
    let tableIndex = 0;
    for (let i=0; i < x.length; i++) {
      let y = Math.floor(100 * (L + (H-L)/Math.pow((1+Math.exp(-A*(Math.log(x[i])-Math.log(x[49])*B))),(1/N))));
      //if the output changes, then add.
      if (tableIndex === 0 || table[tableIndex - 1].Output !== y) {
        table[tableIndex] = {Input: x[i], Output: y};
        tableIndex++;
      }
    }

    return table;
  }

  photocellRange(length) {
    let result = [];
    for (let i = 0; i < length; i++) {
      let x = Math.min(10 * Math.pow(1.2, i) - 10,65535);
      result.push(Math.round(x));
    }
    return result
  }

  formatTime(date) {
    let hour = "00" + date.getHours();
    let minute = "00" + date.getMinutes();
    
    return hour.substr(hour.length-2) + ":" + minute.substr(minute.length-2);
  }

  calculateTimeTable(min, max, start, startLength, end, endLength, trackFlag) {
    let retVal = [];
    let N_start = Math.ceil(startLength / 5); //number of steps is the number of 5 minute intervals
    let N_end = Math.ceil(endLength / 5); //number of steps is the number of 5 minute intervals
    let step_start = (max - min) / N_start;
    let step_end = (max - min) / N_end;

    //start with a value for midnight.
    retVal.push({Input: "00:00", Output: min, Sunrise: false, Sunset: false});

    //start to calculate the ramp up.
    let startTime = new Date(2000, 0, 0, parseInt(start.substr(0,2), 10), parseInt(start.substr(3,2), 10));
    let maxTime = new Date(startTime.getTime() + (startLength) * 60000);
    //push initial value
    retVal.push({Input: this.formatTime(startTime), Output: min, Sunrise: trackFlag, Sunset: false});
    for (let i = 1; i <= Math.ceil(startLength / 5); i++) {
      let curTime = new Date(startTime.getTime() + (i * 5) * 60000);
      //cap the time at the end val
      curTime = (curTime.getTime() < maxTime.getTime()) ? curTime : maxTime;
      //cap the output at the max val
      let outputVal = Math.min(min + Math.ceil(i * step_start), max);
      retVal.push({Input: this.formatTime(curTime), Output: outputVal, Sunrise: false, Sunset: false});
    }

    //start to calculate the ramp down.
    let endTime = new Date(2000, 0, 0, parseInt(end.substr(0,2), 10), parseInt(end.substr(3,2), 10));
    let minTime = new Date(endTime.getTime() - (endLength) * 60000);
    //push initial value
    retVal.push({Input: this.formatTime(minTime), Output: max});
    for (let i = 1; i < Math.ceil(endLength / 5); i++) {
      let curTime = new Date(minTime.getTime() + (i * 5) * 60000);
      //cap the time at the end val
      curTime = (curTime.getTime() < endTime.getTime()) ? curTime : endTime;
      //cap the output at the max val
      let outputVal = Math.max(max - Math.ceil(i * step_end), min);
      retVal.push({Input: this.formatTime(curTime), Output: outputVal, Sunrise: false, Sunset: false});
    }
    //push final value
    retVal.push({Input: this.formatTime(endTime), Output: min, Sunrise: false, Sunset: trackFlag});
    return retVal;
  }

  onMainDatasetChange() {
    const { config } = this.props;
    let dataset = {};
    let options = {};
    let labelset = {};
    if (config && config.ProfileSelected) {
      switch(config.ProfileSelected) {
        case 'Photocell':
          [dataset, options] = this.loadPhotocellConfig(config.Photocell.Table);

          this.setState({
            line: Object.assign({}, this.state.line, {
              datasets: [dataset],
            }),
            options: options
          });
          break;
        case 'Time':
          [dataset, labelset, options] = this.loadTimeConfig(config.Time.Table);
          this.setState({
            line: Object.assign({}, this.state.line, {
              datasets: [dataset],
              labels: labelset
            }),
            options: options
          });
          break;
        case 'Hold':
          [dataset, labelset, options] = this.loadStaticConfig(config.Hold.Output);
          this.setState({
            line: Object.assign({}, this.state.line, {
              datasets: [dataset],
              labels: labelset
            }),
            options: options
          });
          break;
        default: 
        break;
      }
    }
  }

  loadCurrentConfig() {
    const {config} = this.props;
    if (config) {
      let tmpPhotocell = {};
      let tmpTime = {};

      //default photocell values
      if (Object.keys(config.Photocell.Basic).length === 0 && config.Photocell.Basic.constructor === Object) {
        tmpPhotocell = {
          Min: 3,
          Max: 80,
          Ramp: 10,
          Shift: 1
        }
      }
      else {
        tmpPhotocell = config.Photocell.Basic;
      }

      //default time values
      if (Object.keys(config.Time.Basic).length === 0 && config.Time.Basic.constructor === Object) {
        tmpTime = {
          Min: 3,
          Max: 80,
          RiseTime: "7:00",
          RiseWindow: 30,
          SetTime: "18:00",
          SetWindow: 30,
          Track: true
        }
      }
      else {
        tmpTime = config.Time.Basic;
      }

      this.setState({
        editConfig: Object.assign({}, this.state.editConfig, {
          ...this.state.editConfig,
          ProfileSelected: config.ProfileSelected,
          Photocell: {
            ...config.Photocell,
            Basic: tmpPhotocell
          },
          Time: {
            ...config.Time,
            Basic: tmpTime
          },
          Hold: config.Hold
        }),
        activeProfileTab: config.ProfileSelected.toLowerCase(),
        activeModeTab: (config.ProfileSelected !== "Hold") ? config[config.ProfileSelected].Mode.toLowerCase() : "basic"
      });
    }
  }

  render() {
    const { editConfig, activeProfileTab, activeModeTab } = this.state;
    const canUpdate = utils.getUser().permissions.canUpdate(utils.Resources.displayBrightness)
    return (
      <div className="animated fadeIn h-100 w-100">
        <Card className="h-100 w-100">
          <CardHeader>
            <div className="d-flex justify-content-between">
            <div><i className="fa fa-align-justify"></i> Brightness Configuration</div>
              <button type="button" className="brightness-btn btn btn-primary btn-sm my-0" onClick={this.toggleModal} disabled={!canUpdate} title={!canUpdate ? "Insufficient access rights" : "Configure brightness for the display"}>
                Edit
              </button>
            </div>
          </CardHeader>
          <CardBody>
            <div className="h-100">
              <Line  ref={(reference) => this.smallChartRef = reference } data={this.state.line} options={this.state.options} />
            </div>
          </CardBody>
        </Card>
        <Modal isOpen={this.state.editModal} toggle={this.toggleModal} className="custom-modal">
          <ModalHeader toggle={this.toggle}>Brightness Configuration</ModalHeader>
          <ModalBody className="d-flex flex-nowrap">
            <div className="align-self-stretch col-8">
              <div className="d-flex justify-content-center"><h2><i>{editConfig.ProfileSelected}{editConfig.ProfileSelected === "Photocell" && activeProfileTab === "time" && " (Backup)"}</i> Brightness Profile</h2></div>
              <Line  ref={(reference) => this.modalChartRef = reference } data={this.state.editLine} options={this.state.editOptions} />
            </div>
            <div className="align-self-stretch col-4 d-flex flex-column">
              <div className="align-self-stretch mb-1">
                  <ButtonDropdown className="brightness-btn my-1 btn-block mx-auto w-100" isOpen={this.state.dropdownOpen[0]} toggle={() => { this.toggleDrop(0); }}>
                    <DropdownToggle className="btn-block mx-auto w-100" caret>{ editConfig.ProfileSelected }{ (editConfig.ProfileSelected === "Photocell" && activeProfileTab === "time") && " (Backup)"}</DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={() => {this.toggleProfile('Photocell')}}>Photocell</DropdownItem>
                      <DropdownItem onClick={() => {this.toggleProfile('Time')}}>Time</DropdownItem>
                      <DropdownItem onClick={() => {this.toggleProfile('Hold')}}>Hold</DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                  {editConfig.ProfileSelected !== "Hold" &&
                  <div className="my-1 d-flex">
                    <div className="w-50 d-flex justify-content-start">
                    {editConfig && (editConfig.ProfileSelected === "Photocell") && 
                      <Button color="primary" className="brightness-btn" onClick={this.toggleBackupMode}>
                        Edit {activeProfileTab === "photocell" && "Backup"}{activeProfileTab === "time" && "Primary"} Table
                      </Button>
                    }
                    </div>
                    <div className="w-50 d-flex justify-content-end">
                      <Button color="primary" className="brightness-btn" onClick={this.toggleEditMode}>
                        Switch to {activeModeTab && activeModeTab === "basic" && "Advanced"}{activeModeTab && activeModeTab === "advanced" && "Basic"} Mode
                      </Button>
                    </div>
                  </div>
                  }
                </div>
              <div className="h-100 edit-pane align-self-stretch my-1 scrollbar-dark">
                {editConfig && editConfig[editConfig.ProfileSelected] &&
                <TabContent className="h-100 border-0" activeTab={activeProfileTab}>
                  <TabPane tabId="photocell" className="h-100 p-0">
                    <TabContent className="h-100 border-0" activeTab={activeModeTab}>
                      <TabPane tabId="basic" className="h-100 p-0">
                        <Table>
                          <tbody>
                          <tr>
                            <td className="pb-4">Range:<br/>
                              <Range
                                min={0}
                                max={100}
                                defaultValue={[0,100]}
                                allowCross={false}
                                count={1}
                                value={[editConfig.Photocell.Basic.Min, editConfig.Photocell.Basic.Max]}
                                onChange={this.handlePhotocellRangeChange}
                                pushable={true}
                                marks={{0:"0",100:"100"}}
                                tipProps={{visible:true, placement:"bottom"}}
                              />
                            </td>
                          </tr>
                          <tr className="pb-2">
                            <td className="pb-4">Ramp:<br/>
                              <TooltipSlider
                                min={5}
                                max={50}
                                defaultValue={0}
                                value={editConfig.Photocell.Basic.Ramp}
                                onChange={this.handlePhotocellRampChange}
                                marks={{5:"5",50:"50"}}
                                tipProps={{visible:true, placement:"bottom"}}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="pb-4">Shift:<br/>
                              <TooltipSlider
                                min={-1}
                                max={3}
                                step={0.2}
                                defaultValue={0}
                                value={editConfig.Photocell.Basic.Shift}
                                onChange={this.handlePhotocellShiftChange}
                                marks={{"-1":"-1",0:"0",3:"3"}}
                                tipProps={{visible:true, placement:"bottom"}}
                              />
                            </td>
                          </tr>
                          <tr className="pb-2">
                            <td className="pb-4">Default Values:<br/>
                             <Button
                               color="primary"
                               onClick={this.loadPhotocellDefault}
                              >
                              Load
                             </Button>
                            </td>
                          </tr>
                          </tbody>
                        </Table>
                      </TabPane>
                      <TabPane tabId="advanced" className="h-100 p-0">
                        <BrightnessTable
                          table={this.state.editConfig.Photocell.Table}
                          onDatasetChange={this.updatePhotocellTable}
                          mode="photocell"
                        />
                      </TabPane>
                    </TabContent>
                  </TabPane>
                  <TabPane tabId="time" className="h-100 p-0">
                    <TabContent className="h-100 border-0" activeTab={ activeModeTab }>
                      <TabPane tabId="basic" className="h-100 p-0">
                        <Table>
                          <tbody>
                          <tr>
                            <td className="pb-4">Range:<br/>
                              <Range
                                min={0}
                                max={100}
                                defaultValue={[0,100]}
                                allowCross={false}
                                count={1}
                                value={[editConfig.Time.Basic.Min, editConfig.Time.Basic.Max]}
                                onChange={this.handleTimeRangeChange}
                                pushable={true}
                                marks={{0:"0",100:"100"}}
                                tipProps={{visible:true, placement:"bottom"}}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="pb-4">Ramp Up Start:<br/>
                            <Flatpickr
                              data-enable-time
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "h:i K",
                                defaultHour: 0,
                                defaultMinute: 0,
                                defaultDate: "0:00 AM"
                              }}
                              value={new Date("2000-01-01 " + editConfig.Time.Basic.RiseTime)}
                              onChange={this.handleRiseTimeChange}
                            />
                            </td>
                          </tr>
                          <tr className="pb-2">
                            <td className="pb-4">Ramp Up Duration:<br/>
                              <Flatpickr
                                data-enable-time
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                  time_24hr: true,
                                  defaultHour: 0,
                                  defaultMinute: 0,
                                  defaultDate: "0:00",
                                  minDate: "0:00",
                                  maxDate: "3:00",
                                }}
                                value={Math.floor(editConfig.Time.Basic.RiseWindow / 60) +":"+editConfig.Time.Basic.RiseWindow % 60}
                                onChange={this.handleRiseWindowChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="pb-4">Ramp Down End:<br/>
                              <Flatpickr
                                data-enable-time
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "h:i K",
                                  defaultHour: 0,
                                  defaultMinute: 0,
                                  defaultDate: "0:00 AM"
                                }}
                                value={new Date("2000-01-01 " + editConfig.Time.Basic.SetTime)}
                                onChange={this.handleSetTimeChange}
                              />
                            </td>
                          </tr>
                          <tr className="pb-2">
                            <td className="pb-4">Ramp Down Duration:<br/>
                              <Flatpickr
                                data-enable-time
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                  time_24hr: true,
                                  defaultHour: 0,
                                  defaultMinute: 0,
                                  defaultDate: "0:00",
                                  minDate: "0:00",
                                  maxDate: "3:00",
                                }}
                                value={Math.floor(editConfig.Time.Basic.SetWindow / 60)+":"+editConfig.Time.Basic.SetWindow % 60}
                                onChange={this.handleSetWindowChange}
                              />
                            </td>
                          </tr>
                          <tr className="pb-2">
                            <td className="pb-4">Track Sun Rise/Set:<br/>
                             <Button
                               color="primary"
                               onClick={this.toggleTrack}
                              >
                              {editConfig.Time.Basic.Track && "Enabled"}{!editConfig.Time.Basic.Track && "Disabled"}
                             </Button>
                            </td>
                          </tr>
                          <tr className="pb-2">
                            <td className="pb-4">Default Values:<br/>
                             <Button
                               color="primary"
                               onClick={this.loadTimeDefault}
                              >
                              Load
                             </Button>
                            </td>
                          </tr>
                          </tbody>
                        </Table>
                      </TabPane>
                      <TabPane tabId="advanced" className="h-100 p-0">
                      <BrightnessTable
                          table={this.state.editConfig.Time.Table}
                          onDatasetChange={this.updateTimeTable}
                          mode="time"
                        />
                      </TabPane>
                    </TabContent>
                  </TabPane>
                  <TabPane tabId="hold" className="h-100">
                    <TooltipSlider
                      min={0}
                      max={100}
                      defaultValue={0}
                      value={editConfig.Hold.Output}
                      onChange={this.handleHoldValueChange}
                      marks={{0:"0",100:"100"}}
                      tipProps={{visible:true, placement:"bottom"}}
                    />

                  </TabPane>
                </TabContent>
                }
              </div>
              <div className="align-self-stretch mt-1">
                <div className="d-flex justify-content-between flex-row">
                  <Button color="primary" className="brightness-btn" onClick={this.loadCurrentConfig} disabled={!this.state.pendingChanges}>Reset</Button>
                  <Button color="primary" className="px-1 brightness-btn" onClick={this.saveConfig} disabled={!this.state.pendingChanges}>Save</Button>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="px-1" onClick={this.toggleModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { selectedDisplay, display } = state.display;
  const { config } = state.brightness;
  return {
    selectedDisplay, display, config
  };

}

const connectedBrightnessConfig = connect(mapStateToProps)(BrightnessConfig);
export default connectedBrightnessConfig;
