import { reqOptions } from '../_helpers';
import axios from 'axios';
import { config } from '../_config';
const apiUrl = config.apiUrl
// const apiUrl = "http://localhost:3006";  // <---- ONLY for testing

export const heatmapService = {
  getHeatmapConfig,
  getHeatmapStatus,
};

function getHeatmapConfig(id) {
  let requestOptions = reqOptions();
  return axios.get(`${apiUrl}/temperature/${id}/heatmapConfig`, requestOptions).then(handleHeatmapConfigResponse);
}

function getHeatmapStatus(id) {
  let requestOptions = reqOptions();
  return axios.get(`${apiUrl}/temperature/${id}/heatmap`, requestOptions).then(handleHeatmapStatusResponse);
}

// Helpers functions ---------------------
function handleHeatmapConfigResponse(response) {
  return response.data;
}

function handleHeatmapStatusResponse(response) {
  const status = response.data
  return response.data;
}