import { authHeader } from '../_helpers';
import { authService } from './';
// import axios from 'axios';
import { config } from '../_config';
const apiUrl = config.apiUrl
// const apiUrl = "http://localhost:3001";  // <---- ONLY for testing

export const alertService = {
  getAlerts,
  getAlertStats
}

function getAlerts(filter = null) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let qs = "" // "deleted=true&topn=10&severity=Critical"
  if (filter) {
    qs = `` +
      `deleted=${filter.deleted || ''}&` +
      `topn=${filter.topn || '10'}&` +
      `severity=${filter.severity || ''}`
  }

  return fetch(`${apiUrl}/alerts?${qs}`, requestOptions)
    .then(authService.handleResponse)
}

function getAlertStats(filter = null) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let qs = "" // "deleted=true&topn=10&severity=Critical"
  if (filter) {
    qs = `` +
      `deleted=${filter.deleted || 'false'}&` +
      `topn=${filter.topn || '5000'}&` +
      `severity=${filter.severity || ''}`
  }

  return fetch(`${apiUrl}/alerts/stats?${qs}`, requestOptions)
    .then(authService.handleResponse)
}