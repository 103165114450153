import { heatmapConstants } from '../_constants';
import { temperature } from './temperature.reducer';

export function heatmap(state = {}, action) {
  switch (action.type) {
    case heatmapConstants.GET_HEATMAP_CONFIG_REQUEST:
      return Object.assign({}, state, {
        configLoading: true
      });
    case heatmapConstants.GET_HEATMAP_CONFIG_SUCCESS:
      return Object.assign({}, state, {
        config: handleConfig(action.config),
        configLoading: false
      });
    case heatmapConstants.GET_HEATMAP_CONFIG_FAILURE:
      return Object.assign({}, state, {
        config: null,
        configLoading: false,
        error: action.error,
      });

    case heatmapConstants.GET_HEATMAP_STATUS_REQUEST:
      return Object.assign({}, state, {
        statusLoading: true
      });
    case heatmapConstants.GET_HEATMAP_STATUS_SUCCESS:
      return Object.assign({}, state, {
        status: handleStatus(action.status),
        statusLoading: false
      });
    case heatmapConstants.GET_HEATMAP_STATUS_FAILURE:
      return Object.assign({}, state, {
        status: null,
        statusLoading: false,
        error: action.error,
      });

    default:
      return state
  }
}

// helper functions
function handleConfig(data) {
  const config = data.ports.flatMap(port => 
    port.receivers.flatMap(receiver => ({
      rstatus_id: receiver.rstatus_id,
      pstatus_id: port.pstatus_id,
      row: receiver.row,
      col: receiver.col,
      hubports: receiver.hubports,
    }))
  )
  return config;
}

function handleStatus(data) {
  const senderMap = new Map();
  const portMap = new Map();
  const receiverMap = new Map();
  const moduleMap = new Map();
  for(const sender of data.senders) {
    const sender_id = sender.sender_id
    if(!senderMap.has(sender_id)) {
      senderMap.set(sender_id, {
        rom_id: sender.rom_id,
        nickname: sender.nickname,
        lastbeat: sender.lastbeat,
        online: sender.online,
        lan: sender.lan,
        photocell: sender.photocell,
      });
    }

    for(const port of sender.ports) {
      const pstatus_id = port.pstatus_id;
      if(!portMap.has(pstatus_id)) {
        portMap.set(pstatus_id, {
          port_number: port.port_number,
          brightness: port.brightness,
        });
      }

      for(const receiver of port.receivers) {
        const rstatus_id = receiver.rstatus_id;
        if(!receiverMap.has(rstatus_id)) {
          let tempr = null;
          if(receiver.temperature && receiver.temperature > -273)
            tempr = receiver.temperature;

          receiverMap.set(rstatus_id, {
            sender_id: sender_id,
            pstatus_id: pstatus_id,
            position: receiver.position,
            lastbeat: receiver.lastbeat,
            photocell: receiver.photocell,
            temperature: tempr,
            online: receiver.online,
            modules: receiver.modules,
          });
        }

        for(const module of receiver.modules) {
          const mstatus_id = module.mstatus_id;
          if(!moduleMap.has(mstatus_id)) {
            moduleMap.set(mstatus_id, {
              temperature: module.temperature,
              position: module.position,
            })
          }
        }
      }

    }
  }
  return {
    senderMap: senderMap,
    portMap: portMap,
    receiverMap: receiverMap,
    moduleMap: moduleMap,
  }
}
