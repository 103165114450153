import { displayConstants } from '../_constants';
import _ from "lodash";

export function display(state = {}, action) {
  switch (action.type) {
    case displayConstants.GETSUMMARY_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case displayConstants.GETSUMMARY_SUCCESS:
      return Object.assign({}, state, {
        displays: action.displays,
        loading: false,
        error: null
      });
    case displayConstants.GETSUMMARY_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        loading: false
      });

    case displayConstants.GETSINGLE_SUMMARY_REQUEST:
      return Object.assign({}, state, {
        loading: true
      });
    case displayConstants.GETSINGLE_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        display: action.display
      });
    case displayConstants.GETSINGLE_SUMMARY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });

    case displayConstants.SELECT_DISPLAY:
      return Object.assign({}, state, {
        selectedDisplay: action.id,
        alerts: null,
        display: null,
        detail: null,
      });
    case displayConstants.DESELECT_DISPLAY:
      return Object.assign({}, state, {
        selectedDisplay: null,
        alerts: null,
        display: null,
        detail: null,
        error: null,
        loading: false
      });

    case displayConstants.GETDETAIL_REQUEST:
      return Object.assign({}, state, {
        loading: true
      });
    case displayConstants.GETDETAIL_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        detail: action.detail
      });
    case displayConstants.GETDETAIL_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });


    case displayConstants.GETALERT_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case displayConstants.GETALERT_SUCCESS:
      const activeAlerts =   (action.alerts || [])
                            .filter(item => (item.Lifecycle || "").toLowerCase()==="firing")
                            .sort(compareAlerts);
      const inactiveAlerts = (action.alerts || [])
                            .filter(item => (item.Lifecycle || "").toLowerCase()!=="firing")
                            .sort(compareAlerts);
    
      return Object.assign({}, state, {
        activeAlerts: activeAlerts,
        inactiveAlertsGrouped: groupAlerts(inactiveAlerts),
        loading: false,
        error: null
      });
    case displayConstants.GETALERT_FAILURE:
      return Object.assign({}, state, {
        activeAlerts: null,
        inactiveAlertsGrouped: null,
        loading: false,
        error: action.error
      });



    case displayConstants.PUT_DISPLAY_REQUEST:
      return Object.assign({}, state, {
        actionKey: action.fieldName,
        saving: true,
        saveError: null
      });
    case displayConstants.PUT_DISPLAY_SUCCESS:
      // update the display in the displays list
      const arr = state.displays && state.displays.map(d => {
        if (d.DisplayId === action.display.DisplayId) 
          d[action.fieldName] = action.display[action.fieldName]
        return d
      })
      const updatedDisplays = arr && arr.length && {displays: arr}

      return Object.assign({}, state, {
        actionKey: action.fieldName,
        saving: false,
        saveError: null,
        display: {
          ...state.display, 
          [action.fieldName]: action.display[action.fieldName]
        },
        ...updatedDisplays
      });
    case displayConstants.PUT_DISPLAY_FAILURE:
      return Object.assign({}, state, {
        actionKey: action.fieldName,
        saving: false,
        saveError: action.error
      });
    

    // Comments ----------------------------------
    case displayConstants.GET_COMMENTS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        errorComment: null,
        newData: false
      });
    case displayConstants.GET_COMMENTS_SUCCESS:
      // update ONLY when revision has changed (someone else update the DB)
      const actionComments = sortComments(action.comments)
      const newData = !isSame(state.comments, actionComments)
      return (newData) 
        ? Object.assign({}, state, {
          comments: (newData ? actionComments : state.comments) || [],
          loading: false,
          errorComment: null,
          newData: newData
        })
        : state  // do nothing
    case displayConstants.GET_COMMENTS_FAILURE:
      return Object.assign({}, state, {
        comments: null,
        loading: false,
        errorComment: action.error,
        newData: false
      });

    case displayConstants.GET_COMMENTS_HISTORY_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        errorComment: null
      });
    case displayConstants.GET_COMMENTS_HISTORY_SUCCESS:
      let idx = (state.comments || []).indexOf(action.comment)
      let newArr 
      if (idx >= 0) {
        action.comment._history = action.commentHistory
        state.comments[idx]._history = action.commentHistory
        newArr = state.comments.slice()
      }
      return Object.assign({}, state, {
        comments: newArr || state.comments || [],
        loading: false,
        errorComment: null
      });
    case displayConstants.GET_COMMENTS_HISTORY_FAILURE:
      return Object.assign({}, state, {
        comments: null,
        loading: false,
        errorComment: action.error
      });
    
    case displayConstants.POST_COMMENT_REQUEST:
      return Object.assign({}, state, {
        newComment: null,
        loading: true,
        errorComment: null
      });
    case displayConstants.POST_COMMENT_SUCCESS:
      // prepend Comment
      if (state) {
        state.comments = (state.comments || []).slice()
        state.comments.unshift(action.comment)
      }
      return Object.assign({}, state, {
        newComment: action.comment,
        loading: false,
        errorComment: null
      });
    case displayConstants.POST_COMMENT_FAILURE:
      return Object.assign({}, state, {
        newComment: null,
        loading: false,
        errorComment: action.error
      });
      
    case displayConstants.PUT_COMMENT_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        errorComment: null
      });
    case displayConstants.PUT_COMMENT_SUCCESS:
      // replace existing comment with the result
      if (state) {
        // change single element
        let newArr = (state.comments || []).slice()
        if (newArr && newArr.length) {
          let idx = newArr.findIndex(item=>item.MsgInfo===action.comment.MsgInfo)
          newArr[idx] = action.comment
        } else {
          newArr = [action.comment]
        }
        state.comments = newArr
      }
      return Object.assign({}, state, {
        loading: false,
        errorComment: null
      });
    case displayConstants.PUT_COMMENT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errorComment: action.error
      });
      
    case displayConstants.DELETE_COMMENT_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        errorComment: null
      });
    case displayConstants.DELETE_COMMENT_SUCCESS:
      // find the deleted comment and [remove || update] it
      if (state.comments && state.comments.length) {
        if (action.doUpdate) {
          const idx = state.comments.findIndex(item => item.MsgInfo===action.deletedComment.MsgInfo)
          if (idx >= 0) {
            state.comments[idx] = action.deletedComment
            state.comments = state.comments.slice()
          }
        } else {
          state.comments = state.comments.filter(item=>item.MsgInfo!==action.deletedComment.MsgInfo)
        }
      }
      return Object.assign({}, state, {
        loading: false,
        errorComment: null
      });
    case displayConstants.DELETE_COMMENT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errorComment: action.error
      });      
      
    case displayConstants.UNDELETE_COMMENT_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        errorComment: null
      });
    case displayConstants.UNDELETE_COMMENT_SUCCESS:
      if (state) {
        // change single element (from deleted to undeleted)
        let newArr = (state.comments || []).slice()
        if (newArr && newArr.length) {
          let idx = newArr.findIndex(item=>item.MsgInfo===action.comment.MsgInfo)
          newArr[idx] = action.comment
        } else {
          newArr = [action.comment]
        }
        state.comments = newArr
      }
      return Object.assign({}, state, {
        loading: false,
        errorComment: null
      });
    case displayConstants.UNDELETE_COMMENT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errorComment: action.error
      });      

    default:
      return state
  }
}

// Helper functions ---------------------------------
function isSame(a, b) {
  if (!a || !b || a.length !== b.length) {
    return false
  }

  for (let j = 0; j < a.length; j++) {
    let matchFound = false
    for (let k = 0; k < b.length; k++) {
      matchFound = a[j].MsgInfo === b[k].MsgInfo
      if (matchFound) {
        if (a[j].Revision !== b[k].Revision || a[j].tsTTL !== b[k].tsTTL) {
          return false
        } else {
          break
        }
      }
    }
    if (!matchFound) {return false}
  }

  return true
}
function sortComments(a) {
  if (!a || a.length<=0) {return a}

  let active = []
  let deleted = []
  for (let i = 0; i < a.length; i++) {
    if (a[i].tsTTL > 0) {
      deleted.push(a[i])
    } else {
      active.push(a[i])
    }
  }

  return [].concat(active, deleted)
}

function compareAlerts(a,b, order="desc") {
  let aNA = (!a || !a.ExpiryTime || a.ExpiryTime === "nil" || a.ExpiryTime === "");
  let bNA = (!b || !b.ExpiryTime || b.ExpiryTime === "nil" || b.ExpiryTime === "");

  if (aNA && bNA) {
    return 0;
  } else if (bNA) {
    return -1;
  } else if (aNA) {
    return 1;
  } else {
    if (order === 'asc') {
      return a.ExpiryTime - b.ExpiryTime
    } else {
      return b.ExpiryTime - a.ExpiryTime
    }
  }
}

const groupAlerts = array => {
  return array.reduce((accumulator, obj) => {
          const groupTitle = _.get(obj, "Description.Title");
          accumulator[groupTitle] = (accumulator[groupTitle] || []).concat(obj);
          return accumulator;
        }, {})
}