import { brightnessConstants } from '../_constants';

export function brightness(state = {}, action) {
  switch (action.type) {
    case brightnessConstants.GETHISTORY_REQUEST:
      return Object.assign({}, state, {
        loading: true
      });
    case brightnessConstants.GETHISTORY_SUCCESS:
      return Object.assign({}, state, {
        history: action.history
      });
    case brightnessConstants.GETHISTORY_FAILURE:
      return Object.assign({}, state, {
        error: action.error
      });
    case brightnessConstants.GETHISTORY_CANCEL:
      return Object.assign({}, state, {
        history: null,
        cancelMsg: action.cancelMsg
      });

    case brightnessConstants.GETCONFIG_REQUEST:
    return Object.assign({}, state, {
      loading: true
    });
    case brightnessConstants.GETCONFIG_SUCCESS:
      return Object.assign({}, state, {
        config: action.config
      });
    case brightnessConstants.GETCONFIG_FAILURE:
      return Object.assign({}, state, {
        error: action.error
      });
    case brightnessConstants.GETCONFIG_CANCEL:
      return Object.assign({}, state, {
        config: null,
        cancelMsg: action.cancelMsg
      });

    case brightnessConstants.PUT_TIME_CONFIG_REQUEST:
      return Object.assign({}, state, {
        saving: true
      });
    case brightnessConstants.PUT_TIME_CONFIG_SUCCESS:
      return Object.assign({}, state, {
        config: {
        ...state.config,
        Time: action.time
        }
      });
    case brightnessConstants.PUT_TIME_CONFIG_FAILURE:
      return Object.assign({}, state, {
        error: action.error
      });

    case brightnessConstants.PUT_PHOTOCELL_CONFIG_REQUEST:
      return Object.assign({}, state, {
        saving: true
      });
    case brightnessConstants.PUT_PHOTOCELL_CONFIG_SUCCESS:
      return Object.assign({}, state, {
        config: {
          ...state.config,
          Photocell: action.photocell
          }
      });
    case brightnessConstants.PUT_PHOTOCELL_CONFIG_FAILURE:
      return Object.assign({}, state, {
        error: action.error
      });

    case brightnessConstants.PUT_HOLD_CONFIG_REQUEST:
      return Object.assign({}, state, {
        saving: true
      });
    case brightnessConstants.PUT_HOLD_CONFIG_SUCCESS:
      return Object.assign({}, state, {
        config: {
          ...state.config,
          Hold: action.hold
          }
      });
    case brightnessConstants.PUT_HOLD_CONFIG_FAILURE:
      return Object.assign({}, state, {
        error: action.error
      });

    case brightnessConstants.PUT_PROFILE_REQUEST:
    return Object.assign({}, state, {
      saving: true
    });
  case brightnessConstants.PUT_PROFILE_SUCCESS:
    return Object.assign({}, state, {
      config: {
        ...state.config,
        ProfileSelected: action.profile.ProfileSelected
        }
    });
  case brightnessConstants.PUT_PROFILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error
    });

    default:
      return state
  }
}
