import { commentsConstants } from '../_constants';

export function comments(state = {}, action) {
  switch (action.type) {
    case commentsConstants.GET_COMMENTS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case commentsConstants.GET_COMMENTS_SUCCESS:
      return Object.assign({}, state, {
        comments: action.comments,
        loading: false,
        error: null
      });
    case commentsConstants.GET_COMMENTS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        loading: false     
      });
    
      default:
      return state
  }
}
