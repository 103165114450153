// import _ from 'lodash';
import axios from 'axios';
import { authHeader } from '../_helpers';
import { authService } from './';
import { config } from '../_config';
const apiUrl = config.apiUrl
// const apiUrl = "http://localhost:3001";  // <---- ONLY for testing

export const monitorService = {
    getStats,
    getPCStats,
    getIspStats,
    getRouterStats,
};

async function getStats() {
    const headers = authHeader()
    const instance = axios.create({
        baseURL: apiUrl,
        timeout: 30000, // 30s
        headers,
    });

    return Promise.all([
        instance.get("/monitor/camera/status/").then(authService.handleResponseAxios),
        instance.get("/monitor/pc/status/").then(authService.handleResponseAxios),
        instance.get("/monitor/router/status/").then(authService.handleResponseAxios),
        instance.get("/monitor/isp/status/").then(authService.handleResponseAxios),
        instance.get("/alerts/stats?deleted=false&topn=5000&severity=Critical,Error").then(authService.handleResponseAxios),
    ]
    ).then(data => {
        return {
            CameraMonitor:  data[0],
            PcHealth:       data[1],
            Router:         data[2],
            Isp:            data[3],
            Alerts:         data[4]
        }
    })
}
async function getPCStats(displayId) {
    return getDisplayStats("/monitor/pc/status/", displayId, "PcHealth")
}
async function getIspStats(displayId) {
    return getDisplayStats("/monitor/isp/status/", displayId, "Isp")
}
async function getRouterStats(displayId) {
    return getDisplayStats("/monitor/router/status/", displayId, "Router")
}
async function getDisplayStats(url, displayId, propName) {
    const headers = authHeader()
    const requestOptions = {
        baseURL: apiUrl,
        timeout: 1000,
        headers,
    };
    return (!displayId)
            ? Promise.resolve(null)
            : axios.get(url + displayId, requestOptions)
                .then(response => {
                    return {[propName]: response.data}
                })
}
