import React from 'react';
import {
    // Card, CardBody, CardHeader,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    // Button, Modal, ModalHeader, ModalBody, ModalFooter,
  } from 'reactstrap';

export class BasicDropDown extends React.Component {
    /**
     * A generic dropdown with some minor features
     */
    constructor(props) {
      super(props);
  
      this.toggle = this.toggle.bind(this);
      this.state = {
        isOpen: false,
        selectedIndex: (props.selectedIndex||0),
        selectedVal: props.selectedVal,
      };
    }
   
    toggle() {
      this.setState(prevState => ({
        isOpen: !prevState.isOpen
      }));
    }
  
    handleSelect(selectedIndex) {
      const { data } = this.props;
      const selectedVal = data[selectedIndex]
      this.setState({ selectedIndex, selectedVal });
      this.props.onSelect(selectedVal);
    }
  
    render() {
      const { data } = this.props;
      const { selectedIndex, selectedVal } = this.state;
      const value = selectedIndex >= 0 ? data[selectedIndex] : selectedVal
      let { direction } = this.props;
      direction = (direction) ? direction : "up"

      return (
        <Dropdown direction={direction} isOpen={this.state.isOpen} toggle={this.toggle} title={this.props.title}>
          <DropdownToggle caret className={this.props.className}>
            {value}
          </DropdownToggle>
          <DropdownMenu>
            {data.map((item, i) => {
              return (
                <DropdownItem key={item} onClick={this.handleSelect.bind(this, i)} title={item}>
                  {item}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </Dropdown>
      );
    }
  }
  