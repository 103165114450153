import React, { Component } from 'react';
import 'react-dates/initialize';
import { Line, Chart } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import _ from 'lodash';
import { utils } from '../utils';

import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities'

export default class ChartWrap  extends Component  {
  componentWillMount() {
    Chart.Legend.prototype.afterFit = function() {
      this.height = this.height + 15;
    };
  }
  componentWillUnmount() {
    if (this.chartReference) {
      _.set(this.chartInstance, "config.data.datasets", null)
      this.chartReference.destroy()
      this.chartReference = null;
    }
  }

  render() {
    const {data, xLabel, yLabel, loading} = this.props
    const chartOptions = mainChartOpts
    if (xLabel !== undefined) {
      _.set(chartOptions,"scales.xAxes[0].scaleLabel.labelString",xLabel)
      _.set(chartOptions,"scales.xAxes[1].scaleLabel.labelString",xLabel)
    }
    if (yLabel !== undefined) {
      _.set(chartOptions,"scales.yAxes[0].scaleLabel.labelString",yLabel)
    }

    // add Annotations
    if (data && data.annotations) {
      let a = []
      data.annotations.forEach(item => {
        a.push(Object.assign({}, annotationDaylight, {
          id: item.id,
          value: item.value,
          label: Object.assign({}, annotationDaylight.label, {
            content: item.content,
            enabled: item.enabled,
            backgroundColor: (item.id.startsWith("sr-") ? 'rgba(255,255,0,0.6)' : 'rgba(0,255,255,0.6)'),
            xAdjust: (item.id.startsWith("sr-") ? 24 : -22),
          }),
        }))
      })
      if (a.length) {
        chartOptions.annotation = {
            drawTime: 'afterDatasetsDraw', // (default)
            annotations: a
        }
      }
    } else {
      delete chartOptions.annotation
    }

    // disable unused Axes
    let xAxesIds = [], yAxesIds = []
    if (data && data.datasets) {
      data.datasets.forEach(ds => {
        if (ds.xAxisID) xAxesIds.push(ds.xAxisID)
        if (ds.yAxisID) yAxesIds.push(ds.yAxisID)
      })
      chartOptions.scales.xAxes.forEach(axis => axis.display = xAxesIds.includes(axis.id))
      chartOptions.scales.yAxes.forEach(axis => axis.display = yAxesIds.includes(axis.id))
    }
    
    // set ticks Min/Max only for `x-axis-time` axes
    if (data && data.labels && data.labels.length && xAxesIds.includes("x-axis-time")) {
      const min = data.labels[0]
      const max = data.labels[data.labels.length-1]
      _.set(chartOptions,"scales.xAxes[0].ticks.min",min)
      _.set(chartOptions,"scales.xAxes[0].ticks.max",max)
    }
    

    return (
      <React.Fragment>
        {(!data) 
          ? (loading) 
            ? ""
            : <small className="text-danger">No data</small> 
          : <Line data={data} options={chartOptions} ref={ref => this.chartReference = ref && ref.chartInstance}/>
        }
      </React.Fragment>
    )
  }
}

// Helpers -------------------------------
const brandLight = getStyle('--light')
const annotationDaylight = {
  type: 'line',
  mode: 'vertical',
  scaleID: 'x-axis-time',
  drawTime: 'afterDraw',
  borderColor: 'rgba(128,128,128,0.6)',
  borderWidth: 2,
  borderDash: [2, 2],
  borderDashOffset: 5,
  label: {
    backgroundColor: 'rgba(255,255,0,0.6)',
    fontFamily: "sans-serif",
    fontSize: 10,
    fontStyle: "bold",
    fontColor: "#000",
    xPadding: 3,
    yPadding: 3,
    cornerRadius: 3,
    position: "top",
    yAdjust: 4,
    enabled: false,
    // content: "Sunrise"
  }
  // id: 'sunrise',
  // value: '2020-09-09 04:00',
}

const mainChartOpts = {
  tooltips_DISABLED: {
    // intersect: false,
    // mode: 'index',
    // mode: 'x',
    //axis: 'x',
    intersect: false,
    mode: 'x',
    backgroundColor: 'rgba(0,0,0,0.7)',
    callbacks: {
      label: function(tooltipItem, myData) {
        var label = myData.datasets[tooltipItem.datasetIndex].label || '';
        if (label) {
          label += ': ';
        }
        label += parseFloat(tooltipItem.value).toFixed(2);
        return label;
      }
    }
  },
  tooltips: {
    caretOffsetY: 15,
    enabled: false,
    mode: 'x',
    intersect: true,
    position: 'nearest', //'average','nearest',
    custom: CustomTooltips,
    backgroundColor: 'rgba(0,0,0,0.6)',
    callbacks: {
      label: function (tooltipItem, data) {
        const unit = data.datasets[tooltipItem.datasetIndex].unit;
        const label = data.datasets[tooltipItem.datasetIndex].label;
        const value = tooltipItem.value ; // data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        const suffix = (label === "Photocell") ? " lx" : (unit || utils.htmlDecode(' &deg;C'))
        return [`${label}: ${value}${suffix}`]
      },
      labelColor: function (tooltipItem, chart) {
        return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false, // true
  legend: {
    display: true,
    labels: {
      fontColor: hexToRgba(brandLight, 40),
      cursor: "pointer",
    },
    onHover: function (event, legendItem) {
        if (event && legendItem) {
          event.target.style.cursor = 'pointer';
          event.target.style.color = "#FFFFFF";
        }
    },
    onLeave: function (event, legendItem) {
      if (event && legendItem) {
        event.target.style.cursor = 'default';
        event.target.style.color = hexToRgba(brandLight, 40);
      }
    },
  },
  scales: {
    xAxes: [
      {
        id: 'x-axis-time',
        display: false,
        scaleLabel: {
          display: true,
          labelString: 'Time of Day',
          fontColor: hexToRgba(brandLight, 40),
        },
        type: 'time',
        time: {
          unit: 'minute',
          unitStepSize: 60,
          displayFormats: {minute: 'HH:mm zzz'},
          parser: 'YYYY-MM-DD HH:mm:ss',
        },
        ticks: {
          fontColor: hexToRgba(brandLight, 40),
        },
        distribution: 'linear', // 'linear': data are spread according to their time (distances can vary)
        gridLines: {
          drawOnChartArea: false,
        },
      },
      {
        id: 'x-axis-numeric',
        display: false,
        scaleLabel: {
          display: true,
          fontColor: hexToRgba(brandLight, 40),
        },
        ticks: {
          fontColor: hexToRgba(brandLight, 40),
        },
        distribution: 'linear', // 'linear': data are spread according to their time (distances can vary)
        gridLines: {
          drawOnChartArea: false,
        },
      }
    ],
    yAxes: [
      {
        id: 'y-axis-scalar',
        display: true,
        type: 'linear',
        distribution: 'linear',
        scaleLabel: {
          labelString: `Temperature (${utils.htmlDecode('&deg;C')})`, 
          display: true,
          fontColor: hexToRgba(brandLight, 40),
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          max: 100,
          stepSize: Math.ceil(100 / 5), // 10
          fontColor: hexToRgba(brandLight, 40),
        },
        gridLines: {display: true},
      },
      {
        id: 'y-axis-log',
        display: true,
        type: 'logarithmic',
        distribution: 'linear',
        position: 'right',
        gridLines: {display: false},
        labels: {show: true},
        scaleLabel: {
          labelString: 'Photocell value (Lux)',
          display: true,
          fontColor: hexToRgba(brandLight, 40),
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 5,
          stepSize: Math.ceil(800000 / 10),
          min: 0,
          fontColor: hexToRgba(brandLight, 40),
          callback: function (value, index, values) {
            return Number(value.toString()).toLocaleString("en-US");//pass tick values as a string into Number function
          },
        },
      },
      {
        id: 'y-axis-log-MB',
        display: true,
        type: 'logarithmic',
        distribution: 'linear',
        position: 'left',
        gridLines: {display: false},
        labels: {show: true},
        scaleLabel: {
          labelString: 'Data Usage (MB)',
          display: true,
          fontColor: hexToRgba(brandLight, 40),
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          stepSize: Math.ceil(800000 / 20),
          min: 0,
          fontColor: hexToRgba(brandLight, 40),
          callback: function (value, index, values) {
            return Number(value.toString()).toLocaleString("en-US");//pass tick values as a string into Number function
          },
        },
      }
    ],
  },
  elements: {
    point: {
      radius: 0, //0,
      hoverBorderWidth: 3, //3,
      hoverBackgroundColor: '#fff',
    },
  },
};


function CustomTooltips(tooltipModel) {
  //ref: import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
  var _this = this;

  // Add unique id if not exist
  var _setCanvasId = function _setCanvasId() {
    var _idMaker = function _idMaker() {
      var _hex = 16;
      var _multiplier = 0x10000;
      return ((1 + Math.random()) * _multiplier | 0).toString(_hex);
    };

    var _canvasId = "_canvas-" + (_idMaker() + _idMaker());

    _this._chart.canvas.id = _canvasId;
    return _canvasId;
  };

  var ClassName = {
    ABOVE: 'above',
    BELOW: 'below',
    CHARTJS_TOOLTIP: 'chartjs-tooltip',
    NO_TRANSFORM: 'no-transform',
    TOOLTIP_BODY: 'tooltip-body',
    TOOLTIP_BODY_ITEM: 'tooltip-body-item',
    TOOLTIP_BODY_ITEM_COLOR: 'tooltip-body-item-color',
    TOOLTIP_BODY_ITEM_LABEL: 'tooltip-body-item-label',
    TOOLTIP_BODY_ITEM_VALUE: 'tooltip-body-item-value',
    TOOLTIP_HEADER: 'tooltip-header',
    TOOLTIP_HEADER_ITEM: 'tooltip-header-item'
  };
  var Selector = {
    DIV: 'div',
    SPAN: 'span',
    TOOLTIP: (this._chart.canvas.id || _setCanvasId()) + "-tooltip"
  };
  var tooltip = document.getElementById(Selector.TOOLTIP);

  if (!tooltip) {
    tooltip = document.createElement('div');
    tooltip.id = Selector.TOOLTIP;
    tooltip.className = ClassName.CHARTJS_TOOLTIP;

    this._chart.canvas.parentNode.appendChild(tooltip);
  } // Hide if no tooltip


  if (tooltipModel.opacity === 0) {
    tooltip.style.opacity = 0;
    return;
  } // Set caret Position


  tooltip.classList.remove(ClassName.ABOVE, ClassName.BELOW, ClassName.NO_TRANSFORM);

  if (tooltipModel.yAlign) {
    tooltip.classList.add(tooltipModel.yAlign);
  } else {
    tooltip.classList.add(ClassName.NO_TRANSFORM);
  } // Set Text


  if (tooltipModel.body) {
    var titleLines = tooltipModel.title || [];
    var tooltipHeader = document.createElement(Selector.DIV);
    tooltipHeader.className = ClassName.TOOLTIP_HEADER;
    titleLines.forEach(function (title) {
      var tooltipHeaderTitle = document.createElement(Selector.DIV);
      tooltipHeaderTitle.className = ClassName.TOOLTIP_HEADER_ITEM;
      tooltipHeaderTitle.innerHTML = title;
      tooltipHeader.appendChild(tooltipHeaderTitle);
    });
    var tooltipBody = document.createElement(Selector.DIV);
    tooltipBody.className = ClassName.TOOLTIP_BODY;
    var tooltipBodyItems = tooltipModel.body.map(function (item) {
      return item.lines;
    });
    tooltipBodyItems.forEach(function (item, i) {
      var tooltipBodyItem = document.createElement(Selector.DIV);
      tooltipBodyItem.className = ClassName.TOOLTIP_BODY_ITEM;
      var colors = tooltipModel.labelColors[i];
      var tooltipBodyItemColor = document.createElement(Selector.SPAN);
      tooltipBodyItemColor.className = ClassName.TOOLTIP_BODY_ITEM_COLOR;
      tooltipBodyItemColor.style.backgroundColor = colors.backgroundColor;
      tooltipBodyItem.appendChild(tooltipBodyItemColor);

      if (item[0].split(':').length > 1) {
        var tooltipBodyItemLabel = document.createElement(Selector.SPAN);
        tooltipBodyItemLabel.className = ClassName.TOOLTIP_BODY_ITEM_LABEL;
        tooltipBodyItemLabel.innerHTML = item[0].split(': ')[0];
        tooltipBodyItem.appendChild(tooltipBodyItemLabel);
        var tooltipBodyItemValue = document.createElement(Selector.SPAN);
        tooltipBodyItemValue.className = ClassName.TOOLTIP_BODY_ITEM_VALUE;
        tooltipBodyItemValue.innerHTML = item[0].split(': ').pop();
        tooltipBodyItem.appendChild(tooltipBodyItemValue);
      } else {
        var _tooltipBodyItemValue = document.createElement(Selector.SPAN);

        _tooltipBodyItemValue.className = ClassName.TOOLTIP_BODY_ITEM_VALUE;
        _tooltipBodyItemValue.innerHTML = item[0];
        tooltipBodyItem.appendChild(_tooltipBodyItemValue);
      }

      tooltipBody.appendChild(tooltipBodyItem);
    });
    tooltip.innerHTML = '';
    tooltip.appendChild(tooltipHeader);
    tooltip.appendChild(tooltipBody);
  }

  var positionY = this._chart.canvas.offsetTop;
  var positionX = this._chart.canvas.offsetLeft; // Display, position, and set styles for font
  const caretOffsetX = this._chart.options.tooltips.caretOffsetX || 0
  const caretOffsetY = this._chart.options.tooltips.caretOffsetY || 0

  tooltip.style.opacity = 1;
  tooltip.style.left = positionX + caretOffsetX + tooltipModel.caretX + "px";
  tooltip.style.top = positionY + caretOffsetY + tooltipModel.caretY + "px";
  tooltip.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
}
