import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';

import Aside from './Aside';
import Header from './Header';
import { utils } from '../../utils';
import { authActions } from './../../api/_actions';


class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageReloading: this.props.history.action === "POP",
      showSide: this.allowAside()
    }    

    // WHEN page is reloaded from the browser (app start)
    // THEN refresh the token
    if (this.state.pageReloading) {
      this.props.dispatch(authActions.refreshToken())
      .catch(reason => console.log("# LAYOUT ERROR: ", reason))
      .finally(() => {
        this.setState({pageReloading: false})
      })
    }
  }
  allowAside = () => {
    const path = (this.props && this.props.location && this.props.location.pathname) || ""
    return path.indexOf("/status") >= 0
  } 
  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined) return false;
    const paramsChanged = this.props.location.pathname !== prevProps.location.pathname;
    if (paramsChanged) {
      this.setState({showSide: this.allowAside()})
    }    
  }
  render() {
    let allowedRoutes = routes
    const user = utils.getUser()
    if (!user.permissions.canView(utils.Resources.all)) {
      navigation.items=navigation.items.filter(val => user.permissions.canView(val.name))
      allowedRoutes = routes.filter(val => user.permissions.canView(val.name))
    }
    const { dispatch, ...otherProps } = this.props

    return (
      <div className="app">
        <AppHeader fixed>
          <Header />
        </AppHeader>
        {!this.state.pageReloading &&
        <div className="app-body">
          <AppSidebar fixed minimized display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation} {...otherProps} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main p-3">
            <Container fluid className="p-0">
              <Switch>
                {allowedRoutes.map((route, idx) => {
                    return route.component 
                      ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} />
                      )} />)
                      : (null);
                  },
                )}
                {/*TODO: add dashboard <Redirect from="/" to="/dashboard" />*/}
                <Redirect from="/" to="/cameras" />
              </Switch>
            </Container>
          </main>
          {this.state.showSide && 
          <AppAside fixed>
            <Aside />
          </AppAside>
          }
        </div>
        }
      </div>
    );
  }
}

const connectedLayout = connect(null)(Layout);
export default connectedLayout;
