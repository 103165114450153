export const authConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGIN_MFA_REQUEST: 'USERS_LOGIN_MFA_REQUEST',
  LOGIN_MFA_SUCCESS: 'USERS_LOGIN_MFA_SUCCESS',
  LOGIN_MFA_FAILURE: 'USERS_LOGIN_MFA_FAILURE',

  LOGIN_PWD_RESET_REQUEST: 'USERS_LOGIN_PWD_RESET_REQUEST',
  LOGIN_PWD_RESET_SUCCESS: 'USERS_LOGIN_PWD_RESET_SUCCESS',
  LOGIN_PWD_RESET_FAILURE: 'USERS_LOGIN_PWD_RESET_FAILURE',

  LOGIN_FORGOT_PWD_REQUEST: 'USERS_LOGIN_FORGOT_PWD_REQUEST',
  LOGIN_FORGOT_PWD_SUCCESS: 'USERS_LOGIN_FORGOT_PWD_SUCCESS',
  LOGIN_FORGOT_PWD_FAILURE: 'USERS_LOGIN_FORGOT_PWD_FAILURE',

  LOGIN_VERIFY_EMAIL_REQUEST: 'USERS_LOGIN_VERIFY_EMAIL_REQUEST',
  LOGIN_VERIFY_EMAIL_SUCCESS: 'USERS_LOGIN_VERIFY_EMAIL_SUCCESS',
  LOGIN_VERIFY_EMAIL_FAILURE: 'USERS_LOGIN_VERIFY_EMAIL_FAILURE',
  LOGIN_VERIFY_EMAIL_CLEANUP: 'USERS_LOGIN_VERIFY_EMAIL_CLEANUP',

  LOGIN_CONFIRM_EMAIL_REQUEST: 'USERS_LOGIN_CONFIRM_EMAIL_REQUEST',
  LOGIN_CONFIRM_EMAIL_SUCCESS: 'USERS_LOGIN_CONFIRM_EMAIL_SUCCESS',
  LOGIN_CONFIRM_EMAIL_FAILURE: 'USERS_LOGIN_CONFIRM_EMAIL_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',
  REFRESH_TOKEN_DISABLE: 'REFRESH_TOKEN_DISABLE'
};
