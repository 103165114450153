import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { connect } from 'react-redux';
import {displayActions} from "../../../api/_actions";
import {withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const GoogleMapsComponent = withScriptjs(withGoogleMap((props) => {
    return (
      <GoogleMap defaultZoom={11} defaultCenter={props.location}>
        <Marker position={props.location}>
        </Marker>
      </GoogleMap>
    );
  }
));

class DisplayLocation extends Component {

  componentDidMount() {
    this.interval = setInterval(() =>
    {
      const { selectedDisplay } = this.props;
      if (selectedDisplay) {
        this.props.dispatch(displayActions.getDetail(selectedDisplay));
        this.props.dispatch(displayActions.getSingleSummary(selectedDisplay));
      }
    }, 30000);

    const { selectedDisplay } = this.props;
    if (selectedDisplay) {
      this.props.dispatch(displayActions.getDetail(selectedDisplay));
      this.props.dispatch(displayActions.getSingleSummary(selectedDisplay));
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedDisplay } = this.props;
    if (selectedDisplay !== prevProps.selectedDisplay) {
      this.props.dispatch(displayActions.getDetail(selectedDisplay));
      this.props.dispatch(displayActions.getSingleSummary(selectedDisplay));
    }
  }
  render() {
    const { display } = this.props;
    return (
      <div className="animated fadeIn h-100 pb-2">
            <Card className="h-100 mb-0">
              <CardHeader>
                <i className="fa fa-align-justify" data-toggle="tooltip" data-placement="top" title={display && display.DisplayId}></i>Display Location
              </CardHeader>
              <CardBody>
                <Table responsive size="sm">
                  <thead>
                  <tr className="card-header">
                    <th colSpan={2}>Address</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Street</td>
                    <td>{display && display.Address && display.Address.Street}</td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>{display && display.Address && display.Address.City}</td>
                  </tr>
                  <tr>
                    <td>{display && display.Address && display.Address.Country === "Canada" ? "Province" : "State"}</td>
                    <td>{display && display.Address && display.Address.Province}</td>
                  </tr>
                  <tr>
                    <td>{display && display.Address && display.Address.Country === "Canada" ? "Postal" : "Zip"} Code</td>
                    <td>{display && display.Address && display.Address.Postal}</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{display && display.Address && display.Address.Country}</td>
                  </tr>
                  <tr className="card-header">
                    <th colSpan={2}>Map</th>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {display && display.Address &&
                      <GoogleMapsComponent
                        location={{lat: parseFloat(display.Address.Latitude), lng: parseFloat(display.Address.Longitude)}}
                        key="map"
                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyASp-XlkBLCO9RgyKCaFG9yFx2RWejayN0"
                        loadingElement={<div style={{height: `100%`}}/>}
                        containerElement={<div style={{height: `400px`}}/>}
                        mapElement={<div style={{height: `100%`}}/>}
                      />
                      }
                    </td>
                  </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>

    );
  }
}

function mapStateToProps(state) {
  const { selectedDisplay, display, detail } = state.display;
  return {
    selectedDisplay, display, detail
  };

}

const connectedDisplayLocation = connect(mapStateToProps)(DisplayLocation);
export default connectedDisplayLocation;
