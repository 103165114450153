import React from 'react';
import 'react-dates/initialize';
import _ from 'lodash';
import { WeatherInfo } from './detailsTempShort'


const DetailsWebCam = (props) => {
  const item = _.get(props, "item")

  return (
    (!item) 
      ? <div>No Camera item</div>
      : <div className="align-top ml-2 float-right">
          <WeatherInfo display={item} />
        </div>
  );
}

export default DetailsWebCam;
