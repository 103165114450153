import { authConstants } from '../_constants';
import { utils } from '../../utils';


let user = utils.getUser()
const initialState = (user && user.payload && user.payload.username) ? { loggedIn: true, errorVerifyEmail: null, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: utils.getUser(action.user)
      };
    case authConstants.LOGIN_FAILURE:
      return {
        error: action.error
      };

    case authConstants.LOGIN_MFA_REQUEST:
      return {
        loggingIn: true,
        user: state.user
      };
    case authConstants.LOGIN_MFA_SUCCESS:
      return {
        loggedIn: true,
        user: utils.getUser(action.user)
      };
    case authConstants.LOGIN_MFA_FAILURE:
      return {
        error: action.error,
        user: state.user
      };

    case authConstants.LOGIN_FORGOT_PWD_REQUEST:
      return { loggedIn: state.loggedIn, callingForgotPwd: true, user: state.user };
    case authConstants.LOGIN_FORGOT_PWD_SUCCESS:
      return { loggedIn: state.loggedIn, calledForgotPwd: true, user: state.user };
    case authConstants.LOGIN_FORGOT_PWD_FAILURE:
      return { loggedIn: state.loggedIn, errorForgotPwd: action.error, user: state.user };
      
    case authConstants.LOGIN_PWD_RESET_REQUEST:
      return { loggedIn: state.loggedIn, callingResetPwd: true, user: state.user };
    case authConstants.LOGIN_PWD_RESET_SUCCESS:
      return { loggedIn: state.loggedIn, calledResetPwd: true, user: state.user };
    case authConstants.LOGIN_PWD_RESET_FAILURE:
      return { loggedIn: state.loggedIn, errorResetPwd: action.error, user: state.user };
      
    case authConstants.LOGIN_VERIFY_EMAIL_REQUEST:
      return { loggedIn: state.loggedIn, callingVerifyEmail: true, user: state.user };
    case authConstants.LOGIN_VERIFY_EMAIL_SUCCESS:
      return { loggedIn: state.loggedIn, calledVerifyEmail: true, user: state.user };
    case authConstants.LOGIN_VERIFY_EMAIL_FAILURE:
      return { loggedIn: state.loggedIn, errorVerifyEmail: action.error, user: state.user };
    case authConstants.LOGIN_VERIFY_EMAIL_CLEANUP:
      return { 
        loggedIn: state.loggedIn, 
        callingVerifyEmail: false,
        calledVerifyEmail: false,
        errorVerifyEmail: null,
        user: state.user,
      };
      
    case authConstants.LOGIN_CONFIRM_EMAIL_REQUEST:
      return { loggedIn: state.loggedIn, callingConfirmEmail: true, user: state.user };
    case authConstants.LOGIN_CONFIRM_EMAIL_SUCCESS:
      return { loggedIn: state.loggedIn, calledConfirmEmail: true, user: state.user };
    case authConstants.LOGIN_CONFIRM_EMAIL_FAILURE:
      return { loggedIn: state.loggedIn, errorConfirmEmail: action.error, user: state.user };
      
    case authConstants.LOGOUT:
      return {};
    default:
      return state
  }
}
