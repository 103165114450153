import { cameraConstants } from '../_constants';
import { cameraService } from '../_services';

export const cameraActions = {
  getHistory,
  getHistoryZip,
  getSnapshotStats,
  getLatestSnapshots,
  newSnapshot
};

function getHistory(id, params) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request());
    cameraService.getHistory(id, params)
      .then(
        history => {
          dispatch(success(history));
          resolve(history)
          },
        error => {
          dispatch(failure(error));
          reject(error)
        }
      );
  });

  function request() { return { type: cameraConstants.GETHISTORY_REQUEST } }
  function success(history) { return { type: cameraConstants.GETHISTORY_SUCCESS, history } }
  function failure(error) { return { type: cameraConstants.GETHISTORY_FAILURE, error } }
}

function getSnapshotStats(params) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request());
    cameraService.getSnapshotStats(params)
      .then(
        snapshotStats => {
          dispatch(success(snapshotStats));
          resolve(snapshotStats)
          },
        error => {
          dispatch(failure(error))
          resolve()
          // reject(error)
        }
      );
  });

  function request() { return { type: cameraConstants.GET_SNAPSHOTS_STATS_REQUEST } }
  function success(snapshotStats) { return { type: cameraConstants.GET_SNAPSHOTS_STATS_SUCCESS, snapshotStats} }
  function failure(error) { return { type: cameraConstants.GET_SNAPSHOTS_STATS_FAILURE, error } }
}

function getLatestSnapshots(params) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request());
    cameraService.getLatestSnapshots(params)
      .then(
        latestSnapshots => {
          dispatch(success(latestSnapshots));
          resolve(latestSnapshots)
          },
        error => {
          dispatch(failure(error))
          resolve()
          // reject(error)
        }
      );
  });

  function request() { return { type: cameraConstants.GETLATEST_SNAPSHOTS_REQUEST } }
  function success(latestSnapshots) { return { type: cameraConstants.GETLATEST_SNAPSHOTS_SUCCESS, latestSnapshots} }
  function failure(error) { return { type: cameraConstants.GETLATEST_SNAPSHOTS_FAILURE, error } }
}

function newSnapshot(id) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request());
    cameraService.newSnapshot(id)
      .then(
        snapshot => {
          dispatch(success(snapshot));
          resolve(snapshot)
          },
        error => {
          dispatch(failure(error));
          reject(error)
        })
  });

  function request() { return { type: cameraConstants.NEW_SNAPSHOT_REQUEST } }
  function success(snapshot) { return { type: cameraConstants.NEW_SNAPSHOT_SUCCESS, snapshot } }
  function failure(error) { return { type: cameraConstants.NEW_SNAPSHOT_FAILURE, error } }

}

function getHistoryZip(id, params) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request());
    cameraService.getHistory(id, params)
      .then(
        historyZip => {
          dispatch(success(historyZip));
          resolve(historyZip)
          },
        error => {
          dispatch(failure(error));
          reject(error)
        }
      );
  });

  function request() { return { type: cameraConstants.GETHISTORY_ZIP_REQUEST } }
  function success(historyZip) { return { type: cameraConstants.GETHISTORY_ZIP_SUCCESS, historyZip } }
  function failure(error) { return { type: cameraConstants.GETHISTORY_ZIP_FAILURE, error } }
}
