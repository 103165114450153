import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardTitle, CardBody, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, UncontrolledTooltip
} from 'reactstrap';
import { SpinnerDots } from '../../../_components/SpinnerDots'
import _ from "lodash"
import TemperatureGrid from './TemperatureGrid';
import { heatmapActions } from "../../../api/_actions";


const REFRESH_TIME = 3 * 60000      // 3 min

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      initialLoading: false,
    };
  }

  interval = null;

  componentDidMount() {
    this.getData();
    this.setInterval();
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  componentDidUpdate(prevProps) {
    if (_.get(this.props, "selectedDisplay") !== _.get(prevProps, "selectedDisplay")) {
      this.getData();
      this.clearInterval();
      this.setInterval();
      this.setState({ initialLoading: true });
    }
  }

  setInterval = () => {
    this.interval = setInterval(this.getData, REFRESH_TIME);
  }

  clearInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  getData = () => {
    const { selectedDisplay } = this.props;
    if (selectedDisplay) {
      this.props.dispatch(heatmapActions.getHeatmapConfig(selectedDisplay))
      .catch(reason => console.warn("# getHeatmapConfig error: ", reason));

      this.props.dispatch(heatmapActions.getHeatmapStatus(selectedDisplay))
      .catch(reason => console.warn("# getHeatmapStatus error: ", reason));
    }
    if (this.state.initialLoading) {
      this.setState({ initialLoading: false });
    }
  }

  toggle = () => {
    this.setState({modal: !this.state.modal})
  }

  render() {
    const { display, configLoading, config, status, statusLoading } = this.props;
    const currentTemperature = _.get(display, "StatsTemperature.Stats.TempWeather.Value")

    const expandButton =  configLoading ? <SpinnerDots loading={configLoading} className="ml-3" />
                                  : <Button color="outline-secondary" 
                                      className="btn-sm ml-3"
                                      title={(this.state.modal) ? "Collapse" : "Expand"} 
                                      onClick={this.toggle}>
                                        <i className={this.state.modal ? "icon-size-actual" : "icon-size-fullscreen"}></i>
                                    </Button>
    const closeButton = <div className="text-nowrap">
                          {expandButton}
                        </div>

    const receiverGrid = this.createReceiverGrid(config, status);
    const senderGrid = this.createSenderGrid(config, status);
    const moduleGrid = this.createModuleGrid(config, status);

    return (
      <div className="animated fadeIn h-100 w-100">
        <Card>
          <CardBody>
            <Row>
              <Col sm="6">
                <CardTitle className="mb-0" title={`Temperature heat map of the display`}>Temperature Heatmap</CardTitle>
              </Col>
              <Col sm="6" className="d-none d-sm-inline-block">
                <div className="text-nowrap d-inline-flex float-right">
                  {expandButton}
                </div>
              </Col>
            </Row>
            <div className="chart-wrapper" style={{ overflowY: "auto", overflowX:"hidden", marginTop: 40 + 'px',  maxHeight: '400px'}}>
              <TemperatureGrid
                receiverGrid={receiverGrid}
                senderGrid={senderGrid} 
                moduleGrid={moduleGrid} 
                smallScreen={true} 
                status={status} 
                loading={(configLoading || statusLoading) && this.state.initialLoading} 
                display={display}
                currentTemperature={currentTemperature}
              />
            </div>
          </CardBody>
        </Card>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className="custom-modal">
          <ModalHeader toggle={this.toggle} close={closeButton}>
            Temperature Heatmap
            <small className="text-muted d-block">
              {display && display.CompanyName} {display && display.ProjectName}
            </small>
          </ModalHeader>
          <ModalBody>
            <TemperatureGrid 
              receiverGrid={receiverGrid} 
              senderGrid={senderGrid} 
              moduleGrid={moduleGrid} 
              smallScreen={false} 
              status={status} 
              loading={(configLoading || statusLoading) && this.state.initialLoading} 
              display={display}
              currentTemperature={currentTemperature}
            />
          </ModalBody>
        </Modal>
        
      </div>
    );
  }

  // helper function
  
  createModuleGrid(config, status) {
    if(!config) 
      return [[]];

    const maxRow = config.reduce((max, r) => Math.max(max, r.row), -Infinity);
    const maxCol = config.reduce((max, r) => Math.max(max, r.col), -Infinity);
    const grid = Array.from({ length: maxRow + 1 }, () => Array(maxCol + 1).fill(null));

    config.forEach(receiver => {
      let moduleList = [];
      for(const hubport in receiver.hubports) {
        let pos = 0;
        receiver.hubports[hubport].forEach(([row, col, mstatus_ids]) => {
          const module = {
            mstatus_id: mstatus_ids,
            hubport: hubport,
            temperature: [],
            position: pos,
            row: row,
            col: col,
          };
          moduleList.push(module);
          pos += 1;
        })
      }

      let maxModuleRow = 0;
      let maxModuleCol = 0;

      moduleList.forEach(m => {
        const { row, col } = m;
        if (row > maxModuleRow) maxModuleRow = row;
        if (col > maxModuleCol) maxModuleCol = col;
      });

      // Initialize the grid
      const moduleGrid = Array.from({ length: maxModuleRow + 1 }, () => Array(maxModuleCol + 1).fill(null));

      moduleList.forEach(m => {
        const { mstatus_id, row, col, hubport, position } = m;
        const module = {
          mstatus_id: mstatus_id,
          hubport: hubport,
          temperature: [],
          position: position,
        }
        mstatus_id.forEach(mstatus_id => {
          if (status && status.moduleMap && status.moduleMap.get(mstatus_id)) {
            const { temperature } = status.moduleMap.get(mstatus_id);
            module.temperature.push(temperature);
          } else {
            module.temperature.push(null);
          }
        });
        moduleGrid[row][col] = module;
      });

      grid[receiver.row][receiver.col] = moduleGrid;
    });
    return grid;
  }

  /*  
    create receiver grid double array, map receivers to the correct row and col
    each cell contains 2 receivers
    data structure of each cell: 
    {
      receivers: [{
        rstatus_id
        pstatus_id
        sender_id
        temperature
        position
      }, ...]
    }
  */
  createReceiverGrid(config, status) {

    if(!config) return [[]];

    const maxRow = Math.max(...config.map(r => r.row));
    const maxCol = Math.max(...config.map(r => r.col));
    const grid = Array.from({ length: maxRow + 1 }, () => Array(maxCol + 1).fill(null));

    config.forEach(receiver => {
      const receiverCell = []
      for(const r_id of receiver.rstatus_id) {
        let p_id = null;
        let s_id = null;
        let tempr = null;
        let pos = null;
        let online = null;
        let lastbeat = null;
        let photocell = null;
        if(status && status.receiverMap && status.receiverMap.get(r_id)) {
          p_id = status.receiverMap.get(r_id).pstatus_id;
          s_id = status.receiverMap.get(r_id).sender_id;
          tempr = status.receiverMap.get(r_id).temperature;
          pos = status.receiverMap.get(r_id).position;
          online = status.receiverMap.get(r_id).online;
          lastbeat = status.receiverMap.get(r_id).lastbeat;
          photocell = status.receiverMap.get(r_id).photocell;
        }

        if(!online) online = 0;
        receiverCell.push({
          rstatus_id: r_id,
          pstatus_id: p_id,
          sender_id: s_id,
          temperature: tempr,
          position: pos,
          online: online,
          lastbeat: lastbeat,
          photocell: photocell,
        });
      }
      if(receiverCell.length === 1) receiverCell.push(null)
      grid[receiver.row][receiver.col] = {
        receivers: receiverCell,
      }
    });
    return grid;
  }

  /*
    create sender grid double array using receiver grid
    data structure for each cell:
    {
      rowSpan,
      colSpan,
      pstatus_id [id1, id2],
      sender_id [id1, id2],
      receiverList [{
        rstatus_id
        pstatus_id
        sender_id
        temperature
        position
      }, ...]
    }
  */
  createSenderGrid(config, status) {
    const receiverGrid = this.createReceiverGrid(config, status);
    const maxRow = receiverGrid.length - 1;
    const maxCol = receiverGrid[0].length - 1;

    // Create a temporary table for horizontal merging
    const tempTable = Array.from({ length: maxRow + 1 }, () => Array(maxCol + 1).fill(null));

    // Horizontal merging
    for (let row = 0; row <= maxRow; row++) {
      for (let col = 0; col <= maxCol; col++) {
        if (receiverGrid[row][col]) {
          if(receiverGrid[row][col] === "null") {
            tempTable[row][col] = "null";
            continue;
          }
          if(receiverGrid[row][col].receivers[0]) {

            let pstatus_id = [null, null];
            let sender_id = [null, null];

            if(receiverGrid[row][col].receivers[0] && receiverGrid[row][col].receivers[0].pstatus_id) {
              pstatus_id[0] = receiverGrid[row][col].receivers[0].pstatus_id;
              sender_id[0] = receiverGrid[row][col].receivers[0].sender_id;
            }

            if(receiverGrid[row][col].receivers[1] && receiverGrid[row][col].receivers[1].pstatus_id) {
              pstatus_id[1] = receiverGrid[row][col].receivers[1].pstatus_id;
              sender_id[1] = receiverGrid[row][col].receivers[1].sender_id;
            }

            let colSpan = 1;

            while (col + colSpan <= maxCol && receiverGrid[row][col + colSpan]) {
              // if pstatus equals -> they belong to same port
              if(receiverGrid[row][col + colSpan].receivers[0] 
                && receiverGrid[row][col + colSpan].receivers[0].pstatus_id 
                && pstatus_id.includes(receiverGrid[row][col + colSpan].receivers[0].pstatus_id))
              {
                // merge the cell
                receiverGrid[row][col].receivers = receiverGrid[row][col].receivers.concat(receiverGrid[row][col + colSpan].receivers);
                // update pstatus_id if it is missing
                if(!pstatus_id[1] && receiverGrid[row][col + colSpan].receivers[1])
                  pstatus_id[1] = receiverGrid[row][col + colSpan].receivers[1].pstatus_id;
                // update sender_id if it is missing
                if(!sender_id[1] && receiverGrid[row][col + colSpan].receivers[1])
                  sender_id[1] = receiverGrid[row][col + colSpan].receivers[1].sender_id;

                // set the current cell to null
                receiverGrid[row][col + colSpan] = "null";
                colSpan++;
              } else if(receiverGrid[row][col + colSpan].receivers[1] 
                && receiverGrid[row][col + colSpan].receivers[1].pstatus_id
                && pstatus_id.includes(receiverGrid[row][col + colSpan].receivers[1].pstatus_id)) 
              {
                // merge the cell
                receiverGrid[row][col].receivers = receiverGrid[row][col].receivers.concat(receiverGrid[row][col + colSpan].receivers);
                // update pstatus_id if it is missing
                if(!pstatus_id[0] && receiverGrid[row][col + colSpan].receivers[0])
                  pstatus_id[0] = receiverGrid[row][col + colSpan].receivers[0].pstatus_id;
                // update sender_id if it is missing
                if(!sender_id[0] && receiverGrid[row][col + colSpan].receivers[0])
                  sender_id[0] = receiverGrid[row][col + colSpan].receivers[0].sender_id;

                // set the current cell to null
                receiverGrid[row][col + colSpan] = "null";
                colSpan++;
              } else {
                break;
              }
            }
            // Assign colSpan to each cell in the horizontal block
            tempTable[row][col] = { colSpan: colSpan, pstatus_id: pstatus_id, sender_id: sender_id, receiverList: receiverGrid[row][col].receivers };
          } else {
            tempTable[row][col] = { colSpan: 1, pstatus_id: null, sender_id: null, receiverList: [] };
          }
        }
      }
    }

    // Vertical merging using tempTable
    const senderGrid = Array.from({ length: maxRow + 1 }, () => Array(maxCol + 1).fill(null));
    for (let row = 0; row <= maxRow; row++) {
      for (let col = 0; col <= maxCol; col++) {
        if (tempTable[row][col]) {
          if(tempTable[row][col] === "null") {
            senderGrid[row][col] = "null";
            continue;
          }
          if(tempTable[row][col].receiverList[0] || tempTable[row][col].receiverList[1]) {
            let { pstatus_id, sender_id } = tempTable[row][col];
            let rowSpan = 1;
            // Find the end of the current sender block vertically
            while (row + rowSpan <= maxRow 
                  && tempTable[row + rowSpan][col]
                  && tempTable[row + rowSpan][col].colSpan === tempTable[row][col].colSpan) {

              if(tempTable[row + rowSpan][col].pstatus_id[0] 
                && tempTable[row + rowSpan][col].pstatus_id[0]
                && pstatus_id.includes(tempTable[row + rowSpan][col].pstatus_id[0]))
              {
                tempTable[row][col].receiverList = tempTable[row][col].receiverList.concat(tempTable[row + rowSpan][col].receiverList);
                // update pstatus_id if it is missing
                if(!pstatus_id[1] && tempTable[row + rowSpan][col].pstatus_id[1])
                  pstatus_id[1] = tempTable[row + rowSpan][col].pstatus_id[1];
                // update sender_id if it is missing
                if(!sender_id[1] && tempTable[row + rowSpan][col].sender_id[1])
                  sender_id[1] = tempTable[row + rowSpan][col].sender_id[1];

                // set the current cell to null
                tempTable[row + rowSpan][col] = "null";
                rowSpan++;
              } else if(tempTable[row + rowSpan][col].pstatus_id[1]
                && tempTable[row + rowSpan][col].pstatus_id[1]
                && pstatus_id.includes(tempTable[row + rowSpan][col].pstatus_id[1])) 
              {
                tempTable[row][col].receiverList = tempTable[row][col].receiverList.concat(tempTable[row + rowSpan][col].receiverList);
                // update pstatus_id if it is missing
                if(!pstatus_id[0] && tempTable[row + rowSpan][col].pstatus_id[0])
                  pstatus_id[0] = tempTable[row + rowSpan][col].pstatus_id[0];
                // update sender_id if it is missing
                if(!sender_id[0] && tempTable[row + rowSpan][col].sender_id[0])
                  sender_id[0] = tempTable[row + rowSpan][col].sender_id[0];

                // set the current cell to null
                tempTable[row + rowSpan][col] = "null";
                rowSpan++;
              } else {
                break;
              }
            }
            senderGrid[row][col] = { rowSpan, ...tempTable[row][col] };
          }
          else {
            senderGrid[row][col] = { rowSpan: 1, ...tempTable[row][col] };
          }
        }
      }
    }
    return senderGrid;
  }

}


function mapStateToProps(state) {
  const { selectedDisplay, display } = state.display;
  const { config, configLoading, status, statusLoading } = state.heatmap;

  return {
    selectedDisplay, display, config, configLoading, status, statusLoading
  };
}

const connectedHeatmap = connect(mapStateToProps)(HeatMap);
export default connectedHeatmap;
