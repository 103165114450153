import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import 'moment-timezone';
import { 
  Badge, Card, CardBody, Collapse, Input, Button 
} from 'reactstrap';
import { 
  MdRestore, MdDelete, MdDirectionsRun, MdDirectionsWalk, MdAccountCircle
} from 'react-icons/md';
import { utils } from "../../../utils"

var ESCAPE_KEY = 27;
var ENTER_KEY = 13;

class DisplayComment extends Component {
  constructor(props) {
    super(props);

    this.msgInput = React.createRef()

    this.toggleCollapse = this.toggleCollapse.bind(this)
    const {item, create} = this.props

    this.state = {
      create,
      collapse: true,
      editing: false,
      isDirty: create,
      deleted: (!create && (item && item.tsTTL)),
      editText: (create ? "" : item && item.Message),
      saveCommentError: ""
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {item, create, newData} = this.props;
    if (item !== prevProps.item) {
      const updateComplete = (item && item.Revision) !== (prevProps.item && prevProps.item.Revision)
      
      if (create) {
        // wait for the parent to close it        
      } else if (updateComplete) {
        this.setState({
          editing: false,
          saveCommentError: "",
          deleted: (!create && (item && item.tsTTL))
        })
      } else if (newData) {
        this.setState({
          collapse: true,
          editing: false,
          saveCommentError: "",
          deleted: (!create && (item && item.tsTTL))
        })
      }
    } 
    if (create && this.props.isVisible && this.props.isVisible !== prevProps.isVisible) {
      this.msgInput.current.focus()
    }
  }
  toggleCollapse(e) {
    e && e.preventDefault && e.preventDefault()
    this.setState({collapse: !this.state.collapse});
    this.props.onHistory && this.props.onHistory()
  }
  toggleEdit = () => {
    this.setState({
      editing: !this.state.editing,
      saveCommentError: "",
    });
  }
  handleClose = (event) => {
    this.setState({
      editing: false,
      saveCommentError: ""
    })
    this.props.onClose && this.props.onClose()
  }
  handleChange = (event) => {
    if (this.props.create || this.state.editing) {
      const editText = event.target.value
      const isDirty = (this.props.create || (this.props.item && this.props.item.Message !== editText))
      this.setState({editText,isDirty}) 
    }
  }
  handleSubmit = (e) => {
    let {item} = this.props
    const {isDirty, editText} = this.state
    e && e.preventDefault && e.preventDefault();
    if(isDirty) {
      item.Message = editText.trim()
      if (item.Message) {
        this.setState({editText: item.Message})
        this.props && this.props.onSave(item)
      } else {
        this.props.onDelete && this.props.onDelete(item)
      }
    }    
  }
  handleKeyDown = (event) => {
    if (event.which === ESCAPE_KEY) {
      const {create} = this.state
      this.setState({editText: this.props.item.Message});
      if (create) {
        this.props.onClose && this.props.onClose();
      } else {
        this.toggleEdit()
      }
    } else if (event.ctrlKey && event.which === ENTER_KEY) {
      this.handleSubmit(event);
    }
  }

  render() {
    const {item} = this.props
    const {create, editing, isDirty, saveCommentError, deleted} = this.state
    const timeAgo = (item && item.UpdatedAt) ? moment(item.UpdatedAt).fromNow() : ""
    const user = utils.getUser()
    const admin = user.payload.role === "admin"
    const allowWrite = user.permissions.canCreate(utils.Resources.displayComments)

    return (
    <Card className={"mb-3 card-alert " + (deleted ? "border-dark" : "navbar-divider")}>
      <CardBody key={item && item.MsgInfo} className={`text-secondary p-2`}>
        {!create && 
          <div className="mt-minus-1 text-secondary">
            <strong title="Username">
              <MdAccountCircle size="16" className="mb-1 mr-1"/>
              {(item && item.Username) || <React.Fragment>&nbsp;</React.Fragment>}
            </strong>
            {admin && 
              <CopyToClipboard text={item && item.MsgInfo}>
                  <Button color="ghost-dark" className="btn-sm text-muted ml-3 py-0">
                    <small>{item && item.MsgInfo}</small>
                  </Button>
              </CopyToClipboard>
            }
            <div className="float-right">
              {deleted && 
              <Badge className="detail-badge m-1 p-1 mr-4 text-secondary" color="dark">
                deleted
              </Badge>}
              <small title="Time since the last edit">{timeAgo}</small>
              {allowWrite && (
               deleted 
                ? <a className={`text-secondary set-btn p-0`} title="Restore (undelete)" onClick={this.props.onUnDelete}><MdRestore className="ml-2" size="16"/></a>
                : <a className={`text-secondary set-btn p-0`} title="Delete" onClick={this.props.onDelete}><MdDelete className="ml-2 mr-1" size="16"/></a>
              )}
            </div>
          </div>
        }
        {(editing || create) && allowWrite
          ? <div className="my-2">
            <div className="mb-2">
                <Input type="textarea" rows="4"
                      innerRef={this.msgInput} 
                      title={'Edit comment \n (ctrl+enter to Save)'}
                      placeholder={create ? "Create new comment..." : "Comment..."}
                      defaultValue={item && item.Message}
                      onChange={this.handleChange}
                      onKeyDown={this.handleKeyDown}
                      autoFocus={true}/>
            </div>
            <div className="d-inline-flex">
                <small className="text-danger">{saveCommentError}</small>
            </div>
            <div className="pull-right mb-1">
              <Button color="secondary" className="ml-2"   onClick={create ? this.handleClose : (!deleted ? this.toggleEdit : undefined)}>Cancel</Button>
              <Button color="primary" className="ml-2"     onClick={this.handleSubmit} disabled={!isDirty}>Save</Button>
            </div>
            </div>
          : <pre className={"mb-2 " + (deleted ? "text-secondary" : "text-light")} onClick={!deleted ? this.toggleEdit : undefined}>
              {item && item.Message} 
            </pre>
        }
        <div className="mt-minus-2">
          <a className={"card-header-action set-btn float-right"} data-target="#collapseAlertItems" title="Activity">
            {!editing && item && item.Revision > 1 && 
              <React.Fragment>
                <small className="pull-right mt-minus-1" title="Activity count" onClick={this.toggleCollapse}>{item.Revision}</small>
                {this.state.collapse
                  ? <MdDirectionsRun  className="float-right" size="16" onClick={this.toggleCollapse}/>
                  : <MdDirectionsWalk className="float-right" size="16" onClick={this.toggleCollapse}/>
                }
              </React.Fragment>
            }
          </a>
        </div>
        {!this.state.collapse && 
          <hr className="mr-5 mt-3 mb-1 border-dark" />
        }
        <Collapse isOpen={!this.state.collapse} id="collapseAlertItems">
          <Card className="mb-0 border-0 bg-transparent d-block">
            <CardBody className="p-2 mb-minus-2">
              {item && item._history
              ? item._history.map(rev => {
                const revTimeAgo = (rev && rev.UpdatedAt) ? moment(rev.UpdatedAt).fromNow() : ""
                return (
                <Card key={rev.MsgInfo} className="card-alert mb-2 shadow">
                  <CardBody className={"p-2 " + (deleted ? "" : "navbar-divider")}>
                  <div className="mt-minus-1">
                    <strong title="Username">
                      <MdAccountCircle size="16" className="mb-1 mr-1"/>
                      {rev && rev.Username}
                    </strong>
                    {admin && 
                    <CopyToClipboard text={rev && rev.MsgInfo}>
                        <Button color="ghost-dark" className="btn-sm text-muted ml-3 py-0">
                          <small>{rev && rev.MsgInfo}</small>
                        </Button>
                    </CopyToClipboard>
                    }
                    <div className="float-right">
                      <small title="Time since the last edit">{revTimeAgo}</small>
                    </div>
                  </div>
                  <pre className="text-secondary">
                    {rev && rev.Message} 
                  </pre>
                  </CardBody>
                </Card>
                )}
              )
              : <small className="text-muted">Comment history is empty</small>}
            </CardBody>
          </Card>
        </Collapse>
      </CardBody>
    </Card>
    )
  }
}

DisplayComment.defaultProps = {
    create: false,
    newData: false
}
DisplayComment.propTypes = {
    displayId: PropTypes.number.isRequired,
    create: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    newData: PropTypes.bool.isRequired,
    key: PropTypes.string,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onUnDelete: PropTypes.func,
    onHistory: PropTypes.func
}

function mapStateToProps(state) {
  const { selectedDisplay, display, comments, errorComments } = state.display;
  const { user } = state.authentication
  return {
    selectedDisplay, display, comments, errorComments, user
  };

}

const connectedDisplayComment = connect(mapStateToProps)(DisplayComment);
export default connectedDisplayComment;