import React from 'react'
import 'moment-timezone';
import moment from 'moment';
import _ from 'lodash'

import { utils } from '../utils';

export const COLORS_ENUM = {
  brightgreen:  "#4c1",
  green:        "#97ca00",
  yellowgreen:  "#a4a61d",
  yellow:       "#dfb317",
  orange:       "#fe7d37",
  red:          "#e05d44",
  blue:         "#007ec6",
  lightgrey:    "#9f9f9f",
  lightgreen:   {color: "lightgreen", fill1: "#ccc", fill2: "#333"},

  success:      "#4c1",       // BrightGreen
  important:    "#fe7d37",    // Orange
  critical:     "#e05d44",    // Red
  info:         "#007ec6",    // Blue
  informational:"#007ec6",    // Blue
  inactive:     "#9f9f9f",    // LightGrey
  blueviolet:   "Blueviolet", // Blueviolet
}

export const GitBadge = (label, val, color = COLORS_ENUM.Green) => {
  // TODO:  to make this work we need to calculate the text width
  //        for now we use direct `shields.io` url to create the badage on the fly
  if (!color) color = "success"
  let colors = {
    color: COLORS_ENUM.Green,
    fill1: "#010101",
    fill2: "#fff",
  }
  if (color === "lightgreen") colors = COLORS_ENUM.LightGreen
  else colors = Object.assign(colors, (typeof color === 'object') ? color : {color: (COLORS_ENUM[color.toLowerCase()] || color)})

  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="118" height="20" role="img" aria-label={`${label}: ${val}`}>
      <title>{label}: {val}</title>
      <linearGradient id="s" x2="0" y2="100%">
        <stop offset="0" stopColor="#bbb" stopOpacity=".1"/>
        <stop offset="1" stopOpacity=".1"/>
      </linearGradient>
      <clipPath id="r">
        <rect width="118" height="20" rx="3" fill="#fff"/>
      </clipPath>
      <g clipPath="url(#r)">
        <rect width="75" height="20" fill="#555"/>
        <rect x="75" width="43" height="20" fill={colors.color}/>
        <rect width="118" height="20" fill="url(#s)"/>
      </g>
      <g fill="#fff" textAnchor="middle" fontFamily="Verdana,Geneva,DejaVu Sans,sans-serif" textRendering="geometricPrecision" fontSize="110">
        <text aria-hidden="true" x="385" y="150" fill="#010101" fillOpacity=".3" transform="scale(.1)" textLength="650">{label}</text>
        <text x="385" y="140" transform="scale(.1)" fill="#fff" textLength="650">{label}</text>
        <text aria-hidden="true" x="955" y="150" fill={colors.fill1} fillOpacity=".3" transform="scale(.1)" textLength="330">{val}</text>
        <text x="955" y="140" transform="scale(.1)" fill={colors.fill2} textLength="330">{val}</text>
      </g>
    </svg>
  )  
}

export const StatusBadge = ({label, value, className, hideLabel, hideSince}) => {
  // expected value to be array of [online, lastSeen] or Object with array properties {[cam_ID]: [online, lastseen]}
  label = (label || "").toUpperCase()
  if (Array.isArray(value)) value = {"":value}
  if (typeof hideLabel === 'undefined') hideLabel = false

  return <React.Fragment>
    {!!value &&
      Object.keys(value).map(key => {
        const lbl = (label + ((key && key.length && Object.keys(value).length > 1) ? " " + key : "")).toLowerCase()
        const online = Number(_.get(value[key], "[0]", -1))

        const val =  online === 1 ? "online" 
                    : online === 0 ? "offline"
                    : null 
        const color = val === "online" ? "informational" // "primary" 
                    : val === "offline"? "critical"      // "danger"
                    : "inactive"                         // "secondary"
        const sinceMs = value[key][1] * 1000             // convert sec to ms 
        const timeAgo = sinceMs ? utils.timeAgo(moment(sinceMs)) : {}
        const sinceText = sinceMs ? `(since ${timeAgo.description})` : ""

        return  val && (<div className={`col p-0 ${className ||""}`} key={key} >
                          {/* {GitBadge(label, val, color)} */}
                          <img src={`https://img.shields.io/static/v1?label=${hideLabel ? "" : lbl}&message=${val}&color=${color}`} alt={`${lbl} : ${val}`} title={`${lbl} : ${val} ${sinceText}`}></img>
                          {sinceMs && !hideSince ? <span className="text-muted ml-2" title={`Since: ${sinceMs}`}>since {timeAgo.description}</span> : ""}
                        </div>
                      )
      })
    }
    </React.Fragment>
}