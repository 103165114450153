import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import _ from 'lodash';
import { utils } from '../../utils';
import { history } from '../../api/_helpers';
import { authActions } from '../../api/_actions';
import { SpinnerDots } from '../../_components/SpinnerDots'


const LoginReset = () => {
  // SCENARIOS:
  //    - First time login              (loggedIn + user.resetPwd is 1)
  //        [username (from auth), pwd]
  //    - User password change          (loggedIn + user.resetPwd is 0)
  //        [username (from auth), pwd]
  //    - Email link with key parameter (key param exists)
  //        [username (from  key), pwd]
  // ------------------------------------
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.authentication) || {}
  const { callingResetPwd, calledResetPwd, errorResetPwd }  = auth
  
  const [oldPwd, setOldPwd] = useState("")
  const [newPwd, setNewPwd] = useState("")
  const [confirmPwd, setConfirmPwd] = useState("")
  const [pwdMissmatch, setPwdMissmatch] = useState(false)
  const { username, key } = getParams(auth)
  const loggedIn = _.get(auth, "loggedIn")
  const firstTimeLogin = _.get(auth, "user.resetPwd", 0)

  const handleSubmit = () => {
    const pwdMissmatch = (newPwd !== confirmPwd)
    setPwdMissmatch(pwdMissmatch)
    if (!pwdMissmatch) {
      dispatch(authActions.resetPassword(oldPwd, newPwd, key))
    }
  }

  const handleKeyPress = (e) => {
    if(e.key === 'Enter'){
      handleSubmit(e)
    }
  }
  
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6" >
            <CardGroup>
              <Card className="text-white bg-primary p-4">
                <CardBody>
                  <h1 className="text-nowrap">Change password</h1>
                  <p className="text-muted">Enter new password.</p>
                  <Form>
                    <InputGroup className="mb-3 op-60">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText><i className="icon-user"></i></InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" name="username" value={username} placeholder="Username" readOnly className="text-muted" />
                    </InputGroup>
                    {!key && (
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText><i className="icon-lock"></i></InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" name="oldPwd" value={oldPwd} autoComplete="new-password" placeholder="Old Password" onKeyPress={handleKeyPress} onChange={e => setOldPwd(e.target.value)} autoFocus/>
                    </InputGroup>
                    )}
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText><i className="icon-lock"></i></InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" name="newPwd" value={newPwd} autoComplete="new-password" placeholder="New Password" onKeyPress={handleKeyPress} onChange={e => setNewPwd(e.target.value)} autoFocus={!!key}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText><i className="icon-lock"></i></InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" name="confirmPwd" value={confirmPwd} autoComplete="new-password" placeholder="Confirm Password" onKeyPress={handleKeyPress} onChange={e => setConfirmPwd(e.target.value)} />
                    </InputGroup>
                    {!!errorResetPwd && 
                    <p className="text-error mb-1 text-center">Error: {"" + errorResetPwd}</p>
                    }
                    {!!pwdMissmatch && 
                    <p className="text-error mb-1 text-center">New Password and Confirm Password do not match!</p>
                    }
                    {!!calledResetPwd && 
                    <p className="text-error mb-1 text-center">Password reset successfully!</p>
                    }
                    <Row className={"mt-4"}>
                      <Col xs="6" className="text-left">
                        {(loggedIn && !firstTimeLogin) && (
                        <Button color="" className="px-0 text-light align-middle" onClick={() => history.goBack()} >Cancel</Button>
                        )}
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button className="px-4" onClick={handleSubmit}>
                          Submit
                          <SpinnerDots loading={callingResetPwd} className="ml-3" />                                
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

// Helper functions ------------------------------
function getParams(auth) {
  let username = ""
  const { key } = utils.parseQueryString(_.get(window, "location.search"))
  if (key) {
    username = _.get(decodeKey(key), "username")      // get username from URL key parametar
  }  else {
    username = _.get(auth, "user.payload.username")   // get username from Auth token
  }
  return { username, key }
}
function decodeKey(key, prefix) {
  if (!key) return false
  const parts = key.split(".")
  if (parts.length !== 2) return false
  const [payload, keyBase64] = parts
  let usr = null
  try {
      usr = JSON.parse(fromBase64(payload));
  } catch(e) {
      return false
  }
  if (!usr || !usr.username) return false          // username is required
  if (!keyBase64) return false
  const username = usr.username
  const session_key = fromBase64(keyBase64).trimStart()
  if (prefix && !session_key.startsWith(prefix)) return false // key must start with `eml` prefix

  return {username, session_key}
}
const fromBase64 = (str) => Buffer.from(str, "base64").toString("ascii")
// END Helper functions ------------------------------

export default LoginReset
