export const cameraConstants = {
  GETHISTORY_REQUEST: 'CAMERA_GET_HISTORY_REQUEST',
  GETHISTORY_SUCCESS: 'CAMERA_GET_HISTORY_SUCCESS',
  GETHISTORY_FAILURE: 'CAMERA_GET_HISTORY_FAILURE',

  GETLATEST_SNAPSHOTS_REQUEST: 'CAMERA_GET_LATEST_SNAPSHOTS_REQUEST',
  GETLATEST_SNAPSHOTS_SUCCESS: 'CAMERA_GET_LATEST_SNAPSHOTS_SUCCESS',
  GETLATEST_SNAPSHOTS_FAILURE: 'CAMERA_GET_LATEST_SNAPSHOTS_FAILURE',

  NEW_SNAPSHOT_REQUEST: 'CAMERA_NEW_SNAPSHOT_REQUEST',
  NEW_SNAPSHOT_SUCCESS: 'CAMERA_NEW_SNAPSHOT_SUCCESS',
  NEW_SNAPSHOT_FAILURE: 'CAMERA_NEW_SNAPSHOT_FAILURE',

  GETHISTORY_ZIP_REQUEST: 'CAMERA_GET_HISTORY_ZIP_REQUEST',
  GETHISTORY_ZIP_SUCCESS: 'CAMERA_GET_HISTORY_ZIP_SUCCESS',
  GETHISTORY_ZIP_FAILURE: 'CAMERA_GET_HISTORY_ZIP_FAILURE',

  GET_SNAPSHOTS_STATS_REQUEST: 'CAMERA_GET_SNAPSHOT_STATS_REQUEST',
  GET_SNAPSHOTS_STATS_SUCCESS: 'CAMERA_GET_SNAPSHOT_STATS_SUCCESS',
  GET_SNAPSHOTS_STATS_FAILURE: 'CAMERA_GET_SNAPSHOT_STATS_FAILURE',
  
};
