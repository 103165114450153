import { temperatureConstants } from '../_constants';
import { temperatureService } from '../_services';
import axios from 'axios';

export const temperatureActions = {
  getHistoryRange,
};

function getHistoryRange(id, min, max) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request());

    temperatureService.getHistoryRange(id, min, max)
      .then(
        history => {
          dispatch(success(history));
          resolve(history)
          },
        error => {
          if (axios.isCancel(error)) {
            dispatch(cancel(error.message));
            resolve(null)
          } else {
            dispatch(failure(error));
            reject(error)
          }
        }
      );
  });

  function request() { return { type: temperatureConstants.GET_TEMPERATURE_HISTORY_REQUEST } }
  function success(history) { return { type: temperatureConstants.GET_TEMPERATURE_HISTORY_SUCCESS, history } }
  function failure(error) { return { type: temperatureConstants.GET_TEMPERATURE_HISTORY_FAILURE, error } }
  function cancel(cancelMsg) { return { type: temperatureConstants.GET_TEMPERATURE_HISTORY_CANCEL, cancelMsg } }
}