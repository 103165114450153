export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      title: true,
      name: 'Diagnostics',
    },
    {
      name: 'Status',
      url: '/status',
      icon: 'icon-screen-desktop',
    },
    {
      name: 'Cameras',
      url: '/cameras',
      icon: 'icon-camera',
    },
    {
      name: 'Events',
      url: '/events',
      icon: 'icon-bell',
    },
    {
      name: 'Tools',
      url: '/tools',
      icon: 'icon-wrench',
    },
    /*{
      title: true,
      name: 'Admin',
    },
    {
      name: 'Users',
      url: '/users',
      icon: 'icon-user',
    },*/
  ]
};
