import { maintenanceConstants } from '../_constants';

export function maintenance(state = {}, action) {
  switch (action.type) {
    case maintenanceConstants.SUCCESS:
      return {
        type: 'maintenance-success',
        message: action.message
      };
    case maintenanceConstants.ERROR:
      return {
        type: 'maintenance-danger',
        message: action.message
      };
    case maintenanceConstants.CLEAR:
      return {
        maintenance: state.maintenance
      };


    case maintenanceConstants.GET_MAINTENANCE_MSG_ALL_REQUEST:
      return {
        maintenance: action.maintenance,
        loading: true,
        error: null,
      }
    case maintenanceConstants.GET_MAINTENANCE_MSG_ALL_SUCCESS:
      return {
        maintenance: action.maintenance,
        loading: false,
        error: null,
      }
    case maintenanceConstants.GET_MAINTENANCE_MSG_ALL_FAILURE:
      return {
        maintenance: null,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}