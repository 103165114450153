
export const ISP_MONITOR_STATUS = {
  "FIRST_TIME_BOOTUP":        -1, // When the app is started for the first time
  "OK":                        0, // No issue (OK)
  "BOOTUP":                    1, // 
  "PAUSED":                    2, // 
  "DISABLED":                  3, // 
  "HIGH_DATA_USAGE":           4, // when TotalData/UptimeDays > 1 GB
  "HIGH_DATA_USAGE_DAILY":     5, // when PrevDayUsage > DailyLimit
  "HIGH_DATA_USAGE_MONTHLY":   6, // when CurrentMonthlyUsage/DayofMonth * 30 > MonthlyLimit
  "RECENTLY_REBOOTED":         7, // Device was rebooted recently
  "DATA_MONITOR_DISABLED":     8, // Data usage monitoring is not enabled
  "ERROR_PARSING_DATA":        9, // Error while parsing results
}
export const SIM_STATUS = {
    UNKNOWN: 2,
    DEACTIVATED: 1,
    ACTIVATED: 0,
    ACTIVATION_READY: -1,
    INVENTORY: -2,
    PURGED: -3,
    REPLACED: -4,
    RETIRED: -5,
    TEST_READY: -6,
}
  
export const SIM_INDEX = {
    ONLINE: 0,
    SINCE: 1,
    IP: 2,
    ISP: 3,
    APN: 4,
}

const fmtUptime = min => {
  if (!min) return "n/a"
  
  var years = Math.floor(min / (365*24*60));                  min = (years)   ? min % (365*24*60): min;
  var months = Math.floor((min - years) / (31*24*60));        min = (months)  ? min % (31*24*60): min;
  var days = Math.floor((min - years - months) / (24*60));    min = (days)    ? min % (24*60): min;
  var hours = Math.floor((min - years - months - days) / 60); min = (hours)   ? min % (60): min;
  var minutes = min

  var result = ""
  if (years) result += ", " + years + " years"
  if (months) result += ", " + months + " months"
  if (days) result += ", " + days + " days"
  if (hours) result += ", " + hours + " hours"
  if (minutes) result += ", " + minutes + " minutes"

  return result ? result.substring(2) : result;
}

const fmtToMB = bytes => (typeof bytes === 'undefined') ? "n/a" : Number((Math.round(bytes*100 / (1024 * 1024))/100).toString()).toLocaleString("en-US") + " MB";

export const ISP_FIELDS = {
  "General": {
    "iccid":                {lbl: "SIM ID (ICCID)",         format: null, tip: "ICCID (Integrated Circuit Card ID) an 18- or 19-digit identification number used to identify the SIM itself"},
    "imsi":                 {lbl: "IMSI",                   format: null, tip: "International Mobile Subscriber Identity"},
    "msisdn":               {lbl: "MSISDN",                 format: null, tip: null},
    "imei":                 {lbl: "imei",                   format: null, tip: null},
    "status":               {lbl: "Status",                 format: null, tip: null},
    "ratePlan":             {lbl: "Rate Plan",              format: null, tip: null},
    "communicationPlan":    {lbl: "Communication Plan",     format: null, tip: null},
  },
  
  "Details": {
    "dateAdded":            {lbl: "Date Added",             format: null, tip: null},
    "dateShipped":          {lbl: "Date Shipped",           format: null, tip: null},
    "dateUpdated":          {lbl: "Date Updated",           format: null, tip: null},
    "dateActivated":        {lbl: "Date Activated",         format: null, tip: null},
    "euiccid":              {lbl: null,                     format: null, tip: null},
    "operatorCustom2":      {lbl: null,                     format: null, tip: null},
    "operatorCustom1":      {lbl: null,                     format: null, tip: null},
    "operatorCustom4":      {lbl: null,                     format: null, tip: null},
    "operatorCustom3":      {lbl: null,                     format: null, tip: null},
    "customerCustom1":      {lbl: null,                     format: null, tip: null},
    "customerCustom2":      {lbl: null,                     format: null, tip: null},
    "globalSimType":        {lbl: null,                     format: null, tip: null},
    "customerCustom5":      {lbl: null,                     format: null, tip: null},
    "customerCustom3":      {lbl: null,                     format: null, tip: null},
    "customerCustom4":      {lbl: null,                     format: null, tip: null},
    "modemID":              {lbl: null,                     format: null, tip: null},
    "fixedIPAddress":       {lbl: null,                     format: null, tip: null},
    "endConsumerId":        {lbl: null,                     format: null, tip: null},
    "operatorCustom5":      {lbl: null,                     format: null, tip: null},
    "deviceID":             {lbl: null,                     format: null, tip: null},
    "accountCustom3":       {lbl: null,                     format: null, tip: null},
    "accountId":            {lbl: null,                     format: null, tip: null},
    "accountCustom2":       {lbl: null,                     format: null, tip: null},
    "accountCustom10":      {lbl: null,                     format: null, tip: null},
    "accountCustom5":       {lbl: null,                     format: null, tip: null},
    "accountCustom4":       {lbl: null,                     format: null, tip: null},
    "accountCustom1":       {lbl: null,                     format: null, tip: null},
    "accountCustom7":       {lbl: null,                     format: null, tip: null},
    "accountCustom6":       {lbl: null,                     format: null, tip: null},
    "accountCustom9":       {lbl: null,                     format: null, tip: null},
    "customer":             {lbl: null,                     format: null, tip: null},
    "accountCustom8":       {lbl: null,                     format: null, tip: null},
    "simNotes":             {lbl: null,                     format: null, tip: null},
  },

  "Usage": {
    "ctdSMSUsage":          {lbl: "SMS Usage",              format: fmtToMB, tip: null},
    "overageLimitReached":  {lbl: "Average Limit Reached",  format: null, tip: null},
    "averageLimitReached":  {lbl: "Average Limit Reached",  format: null, tip: null},
    "ctdVoiceUsage":        {lbl: "Voice Usage",            format: fmtToMB, tip: null},
    "ctdSessionCount":      {lbl: "Session Count",          format: null, tip: null},
    "ctdDataUsage":         {lbl: "Data Usage",             format: fmtToMB, tip: null},
    "overageLimitOverride": {lbl: "Average Limit Override", format: null, tip: null},
    "averageLimitOverride": {lbl: "Average Limit Override", format: null, tip: null},
  },

  "Session": {
    "Status":                {lbl: "Status",                format: null, tip: null},
    "Since":                 {lbl: "Since",                 format: fmtUptime, tip: null},
  }
}