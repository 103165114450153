export const profileConstants = {
  PROFILE_GET_REQUEST: 'USER_PROFILE_GET_REQUEST',
  PROFILE_GET_SUCCESS: 'USER_PROFILE_GET_SUCCESS',
  PROFILE_GET_FAILURE: 'USER_PROFILE_GET_FAILURE',

  PROFILE_PUT_REQUEST: 'USER_PROFILE_PUT_REQUEST',
  PROFILE_PUT_SUCCESS: 'USER_PROFILE_PUT_SUCCESS',
  PROFILE_PUT_FAILURE: 'USER_PROFILE_PUT_FAILURE',

  PROFILE_MFA_POST_REQUEST: 'USER_PROFILE_MFA_POST_REQUEST',
  PROFILE_MFA_POST_SUCCESS: 'USER_PROFILE_MFA_POST_SUCCESS',
  PROFILE_MFA_POST_FAILURE: 'USER_PROFILE_MFA_POST_FAILURE',

  PROFILE_MFA_PATCH_REQUEST: 'USER_PROFILE_MFA_PATCH_REQUEST',
  PROFILE_MFA_PATCH_SUCCESS: 'USER_PROFILE_MFA_PATCH_SUCCESS',
  PROFILE_MFA_PATCH_FAILURE: 'USER_PROFILE_MFA_PATCH_FAILURE',

  PROFILE_MFA_DISABLE_REQUEST: 'USER_PROFILE_MFA_DISABLE_REQUEST',
  PROFILE_MFA_DISABLE_SUCCESS: 'USER_PROFILE_MFA_DISABLE_SUCCESS',
  PROFILE_MFA_DISABLE_FAILURE: 'USER_PROFILE_MFA_DISABLE_FAILURE',
};
