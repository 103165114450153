import React from 'react';
import 'react-dates/initialize';
import _ from 'lodash';
import { StatusBadge } from '../StatusBadge'


const DetailsPingShort = (props) => {
  const data = _.get(props, "item.Stats")

  return (
    (!data) 
      ? <div>No data</div>
      : <React.Fragment>
          <div className="my-1">
            {(!data) 
              ? <div>No Ping item</div>
              : <React.Fragment>
                  <StatusBadge label="Teamviewer" value={data.Teamviewer} />
                  <StatusBadge label="Router" value={data.Router} />
                  {Object.keys(data.WebCams || {}).map(key => {
                    return (<StatusBadge key={`sb-webcam-${key}`} label="WebCam" value={data.WebCams[key]} />)
                  })}
                  {Object.keys(data.SIM || {}).map(key => {
                    return (<StatusBadge key={`sb-sim-${key}`} label="SIM" value={data.SIM[key]} />)
                  })}
                </React.Fragment>
            }
          </div>
        </React.Fragment>
  );
}

export default DetailsPingShort;
