import React, { useState, useEffect } from 'react';
import 'react-dates/initialize';
import _ from 'lodash';
import {
  Table, Badge,
  Popover, PopoverBody,
} from 'reactstrap';
import {
  MdSystemUpdate, MdInfoOutline
} from 'react-icons/md';
import 'moment-timezone';
import { utils } from '../../utils';
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { SpinnerDots } from '../SpinnerDots'
import { monitorActions } from "../../api/_actions"
import { ROUTER_FIELDS, ROUTER_MONITOR_STATUS } from "../../api/_constants/monitor.router.constants"

function fetchData(display) {
  const id = _.get(display, "DisplayId", null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch();
  const refData = React.useRef(null);
  refData.current = useSelector(state => {
                      return _.get(state, `monitor.${id}.Router`)
                    }, shallowEqual)
  
  useEffect(() => {
    setLoading(true)
    refData.current = null
    dispatch(monitorActions.getRouterStats(id))
      .then(res => setLoading(false))
      .catch(err => {
        setError(err)
        setLoading(false)
      })
  }, [ id ])
  return [ loading, error, refData.current ]
}
const getValue = (field, data) => {
  if (!field || !data) {return "n/a"}
  
  var val = typeof data === 'object' ? data[field.id] : data
  let result = val || "n/a";
  if (field.format) {
    if (typeof field.format === "object") {
      result = field.format[val] || "n/a"
    } else if (typeof field.format === "function") {
      result = field.format(val, data);
    }
  }
  return result
}
const showValue = (field, data) => {
  if (!field || !data) {return "n/a"}
  
  const val = getValue(field, data)
  const badge = typeof field.expected !== 'undefined' && val !== field.expected

  return badge ? (<Badge className="detail-badge mx-1 p-1 mr-4" color="danger" title={`Expected: ${getValue(field, field.expected)}`}>{val}</Badge>)
               : val
}
const InfoButton = (item, timeZone) => { 
  const [popoverOpen, setPopoverOpen] = useState(false);
  const rtr_id = "rtr-popover-" + (item && (item.DisplayId || item.id))
  const toggle = () => setPopoverOpen(!popoverOpen)

  return (
    <React.Fragment>
      <MdInfoOutline size="16" className="ml-1 mt-1 float-right pointer" id={rtr_id} onClick={toggle} />
      {(!item) 
        ? ""
        : <Popover
            placement="top"
            isOpen={popoverOpen}
            target={rtr_id}
            toggle={toggle}
            className="popover-dark"
            innerClassName="dashboard"
            boundariesElement="viewport"
          >
            <PopoverBody>
              {DetailsRouterTable(item, timeZone)}
            </PopoverBody>
          </Popover>
      }
    </React.Fragment>
  )
}
const DetailsRouterTable = (router, timeZone) => {

  const data = router && router.Stats
  const status = router && router.Status
  const fields = utils.getProps(ROUTER_FIELDS)
  const statusTxt = utils.enumToString(ROUTER_MONITOR_STATUS, status)
  const updatedAt = utils.unixUtcToTzStr(router && router.UpdatedAt, timeZone)

  return (
    <Table responsive size="sm" className="mb-0 overflow-hidden text-nowrap">
      <thead>
        <tr className="card-header">
          <th colSpan="2">
            Router info
            {updatedAt ? <small className="text-muted ml-2" title="Time when router stats were received"> @ {updatedAt} {timeZone}</small> : ""}
            {status ? <Badge className="detail-badge w-auto mx-1 p-1 float-right text-uppercase" color={status ? "danger" : "success"} title={status}>{statusTxt}</Badge>: ""}
          </th>
        </tr>
      </thead>
      <tbody>
      {fields.map(f =>
        <React.Fragment key={f.id}>
          {(f.id ===   773) && <tr key={`header-${f.id}`}><td colSpan="2" className="card-header font-weight-bolder">Cell info</td></tr>}
          {(f.id ===  5113) && <tr key={`header-${f.id}`}><td colSpan="2" className="card-header font-weight-bolder">Security</td></tr>}
          {(f.id === 10710) && <tr key={`header-${f.id}`}><td colSpan="2" className="card-header font-weight-bolder">Cell Slot 1</td></tr>}
          {(f.id === 10750) && <tr key={`header-${f.id}`}><td colSpan="2" className="card-header font-weight-bolder">Cell Slot 2</td></tr>}
          <tr key={f.id}>
            <td title={f.tip}>
              <MdSystemUpdate size={20} color="gray" className="mr-1"/> 
              {f.lbl}
            </td>
            <td title={data && data[f.id]}>
              {showValue(f, data)}                  
            </td>
          </tr>
        </React.Fragment>
      )}
      </tbody>
    </Table>
  )
}

export const DetailsRouter = ({item, display}) => {
  const displayDetailsView = !item
  const [loading, error, router] = (item) ? [false, null, item]
                                          : fetchData(display)
  const timeZone =  _.get(display, "Timezone")                                          
  return (
    (router ? displayDetailsView ? DetailsRouterTable(router, timeZone)
                                 : InfoButton(router, timeZone)
            : loading ? <SpinnerDots loading={loading} className="ml-3 float-right" />
                      : <small className="text-danger"> 
                        {(error) ? <span title={error}>Error loading data!</span> 
                                 : "No data found"} 
                        </small>
    )
  )
}
