import { temperatureConstants } from '../_constants';

export function temperature(state = {}, action) {
  switch (action.type) {
    case temperatureConstants.GET_TEMPERATURE_HISTORY_REQUEST:
      return Object.assign({}, state, {
        loading: true
      });
    case temperatureConstants.GET_TEMPERATURE_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        history: action.history,
        loading: false
      });
    case temperatureConstants.GET_TEMPERATURE_HISTORY_FAILURE:
      return Object.assign({}, state, {
        history: null,
        loading: false,
        error: action.error,
      });
    case temperatureConstants.GET_TEMPERATURE_HISTORY_CANCEL:
      return Object.assign({}, state, {
        history: null,
        cancelMsg: action.cancelMsg,
      });

    default:
      return state
  }
}
