import React, { useState, useEffect } from 'react';
import 'react-dates/initialize';
import _ from 'lodash';
import {
  Table, Badge,
  Popover, PopoverBody,
} from 'reactstrap';
import {
  MdSystemUpdate, MdInfoOutline
} from 'react-icons/md';
import 'moment-timezone';
import { utils } from '../../utils';
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { SpinnerDots } from '../SpinnerDots'
import { monitorActions } from "../../api/_actions"
import { ISP_FIELDS, SIM_STATUS } from "../../api/_constants/monitor.isp.constants"

function fetchData(display) {
  const id = _.get(display, "DisplayId", null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch();
  const refData = React.useRef(null);
  refData.current = useSelector(state => {
                      return _.get(state, `monitor.${id}.Isp`)
                    }, shallowEqual)
  
  useEffect(() => {
    setLoading(true)
    refData.current = null
    dispatch(monitorActions.getIspStats(id))
      .then(res => setLoading(false))
      .catch(err => {
        setError(err)
        setLoading(false)
      })
  }, [ id ])
  return [ loading, error, refData.current ]
}
const getValue = (key, field, data) => {
  if (!field || !data) {return "n/a"}
  
  var val = typeof data === 'object' ? data[key] : data
  let result = (typeof val === 'undefined') ? "n/a" : val || ""
  if (field.format) {
    if (typeof field.format === "object") {
      result = field.format[val] || "n/a"
    } else if (typeof field.format === "function") {
      result = field.format(val, data);
    }
  }
  return result
}
const showValue = (key, field, data) => {
  if (!field || !data) {return "n/a"}
  
  const val = getValue(key, field, data)
  const badge = typeof field.expected !== 'undefined' && val !== field.expected

  return badge ? (<Badge className="detail-badge mx-1 p-1 mr-4" color="danger" title={`Expected: ${getValue(field, field.expected)}`}>{val}</Badge>)
               : val
}
const parseData = (data) => {
  if (!data) return {}

  const { "details": _details, "usage": _usage, ...General } = data;
  const Details = data.details
  const Usage = data.usage

  return {
    General,
    Details,
    Usage,
  }
}
const InfoButton = (item, timeZone) => { 
  const [popoverOpen, setPopoverOpen] = useState(false);
  const isp_id = "isp-popover-" + item.id
  const toggle = () => setPopoverOpen(!popoverOpen)

  return (
    <React.Fragment>
      <MdInfoOutline size="16" className="ml-1 mt-1 float-right pointer" id={isp_id} onClick={toggle} />
      {(!item) 
        ? ""
        : <Popover
            placement="top"
            isOpen={popoverOpen}
            target={isp_id}
            toggle={toggle}
            className="popover-dark"
            innerClassName="dashboard"
            boundariesElement="viewport"
          >
            <PopoverBody>
              {DetailsIspTable(item, timeZone)}
            </PopoverBody>
          </Popover>
      }
    </React.Fragment>
  )
}
const DetailsIspTable = (isp, timeZone) => {
  const data = parseData(isp && isp.Stats)
  const status = isp && isp.Status
  const statusTxt = utils.enumToString(SIM_STATUS, status)
  const updatedAt = utils.unixUtcToTzStr(isp && isp.UpdatedAt, timeZone)

  return (
    <Table responsive size="sm" className="mb-0 overflow-hidden text-nowrap">
      <thead>
        <tr className="card-header">
          <th colSpan="2">
            ISP info
            {updatedAt ? <small className="text-muted ml-2" title="Time when ISP stats were received"> @ {updatedAt} {timeZone}</small> : ""}
            {(typeof status !== 'undefined') ? <Badge className="detail-badge w-auto mx-1 p-1 float-right text-uppercase" color={status ? "danger" : "success"} title={status}>{statusTxt}</Badge>: ""}
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(data).map(headerKey => {
          const section = data[headerKey]
          return (
            <React.Fragment>              
              {(headerKey!=="General") && <tr key={`header-${headerKey}`}><td colSpan="2" className="card-header font-weight-bolder">{headerKey}</td></tr>}
              {Object.keys(ISP_FIELDS[headerKey] || {}).map(key => {
                const f = ISP_FIELDS[headerKey][key]
                return (
                  <React.Fragment key={key}>
                    <tr key={key}>
                      <td title={f.tip}>
                        <MdSystemUpdate size={20} color="gray" className="mr-1"/> 
                        {f.lbl || key}
                      </td>
                      <td title={section && section[key]}>
                        {showValue(key, f, section)}                  
                      </td>
                    </tr>
                  </React.Fragment>
                )}
              )}
            </React.Fragment>
          )
        })}
      </tbody>
    </Table>
  )
}

export const DetailsISP = ({item, display}) => {
  const displayDetailsView = !item
  const [loading, error, isp] = (item) ? [false, null, item]
                                       : fetchData(display)
  const timeZone =  _.get(display, "Timezone")                                          
  console.log("DetailsISP:", item, isp, display)
  return (
    (isp ? displayDetailsView ? DetailsIspTable(isp, timeZone)
                              : InfoButton(isp, timeZone)
            : loading ? <SpinnerDots loading={loading} className="ml-3 float-right" />
                      : <small className="text-danger"> 
                        {(error) ? <span title={error}>Error loading data!</span> 
                                 : "No data found"} 
                        </small>
    )
  )
}