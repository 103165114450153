import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// Containers
import { Layout } from './containers';
// Pages
import { Login, LoginReset, ConfirmEmail, Page404, Page500 } from './views/Pages';
// import { Register } from './views/Pages';
import { history } from './api/_helpers';
import { alertActions } from './api/_actions';
import { PrivateRoute } from './_components';

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    this.unlisten = history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }
  componentWillUnmount() {
    this.unlisten && this.unlisten();  // clean up
  }

  render() {
    const { alert } = this.props;
    return (
      <div>
          {alert.message &&
          <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
            <Switch>
              {/* <Route exact path="/register" name="Register Page" component={Register} /> */}
              <Route exact path="/login" name="Login Page" component={Login} />
              <Route exact path="/resetpwd" name="Reset Password Page" component={LoginReset} />
              <Route exact path="/confirmEmail" name="Confirm Email Page" component={ConfirmEmail} />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <PrivateRoute path="/" name="Home" component={Layout} />
            </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedApp = connect(mapStateToProps)(App);
export default connectedApp;

//export default App;
