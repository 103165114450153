import { monitorConstants } from '../_constants';
import { monitorService } from '../_services';

export const monitorActions = {
    getStats,
    getPCStats,
    getRouterStats,
    getIspStats,
};

function getStats() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());
        monitorService.getStats()
            .then(
                stats => {
                    dispatch(success(stats));
                    resolve(stats)
                },
                error => {
                    dispatch(failure(error));
                    reject(error)
                }
            );
    });

    function request() { return { type: monitorConstants.GET_STATS_REQUEST } }
    function success(stats) { return { type: monitorConstants.GET_STATS_SUCCESS, stats } }
    function failure(error) { return { type: monitorConstants.GET_STATS_FAILURE, error } }
}

function getPCStats(displayId) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch(request(displayId));
        monitorService.getPCStats(displayId)
            .then(
                stats => {
                    dispatch(success(displayId, stats));
                    resolve(stats)
                },
                error => {
                    dispatch(failure(displayId, error));
                    reject(error)
                }
            );
    });

    function request(displayId) { return { type: monitorConstants.GET_PC_REQUEST, displayId } }
    function success(displayId, stats) { return { type: monitorConstants.GET_PC_SUCCESS, displayId, stats } }
    function failure(displayId, error) { return { type: monitorConstants.GET_PC_FAILURE, displayId, error } }
}

function getRouterStats(displayId) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch(request(displayId));
        monitorService.getRouterStats(displayId)
            .then(
                stats => {
                    dispatch(success(displayId, stats));
                    resolve(stats)
                },
                error => {
                    dispatch(failure(displayId, error));
                    reject(error)
                }
            );
    });

    function request(displayId) { return { type: monitorConstants.GET_ROUTER_REQUEST, displayId } }
    function success(displayId, stats) { return { type: monitorConstants.GET_ROUTER_SUCCESS, displayId, stats } }
    function failure(displayId, error) { return { type: monitorConstants.GET_ROUTER_FAILURE, displayId, error } }
}

function getIspStats(displayId) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch(request(displayId));
        monitorService.getIspStats(displayId)
            .then(
                stats => {
                    dispatch(success(displayId, stats));
                    resolve(stats)
                },
                error => {
                    dispatch(failure(displayId, error));
                    reject(error)
                }
            );
    });

    function request(displayId) { return { type: monitorConstants.GET_ISP_REQUEST, displayId } }
    function success(displayId, stats) { return { type: monitorConstants.GET_ISP_SUCCESS, displayId, stats } }
    function failure(displayId, error) { return { type: monitorConstants.GET_ISP_FAILURE, displayId, error } }
}
