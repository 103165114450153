import axios from 'axios';
import { authHeader } from '../_helpers';
import { authActions } from '../_actions';
import { reqOptions } from '../_helpers';
import { utils } from '../../utils';
import { config } from '../_config';
const apiUrl = config.apiUrl
// const apiUrl = "http://localhost:3002";  // <---- ONLY for testing

export const authService = {
  login,
  logout,
  loginMfa,
  refreshToken,
  resetPassword,
  forgotPassword,
  verifyEmail,
  confirmEmail,
  handleResponse,
  handleResponseBlob, 
  handleResponseAxios
};

// simulate long api call. Usage: .then(wait(2000))
// const wait = ms => (v) => new Promise(resolve => setTimeout(() => resolve(v), ms));

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  };

  // login successful if there's a jwt token in the response
  return fetch(`${apiUrl}/auth/login`, requestOptions)
    // .then(wait(20000))
    .then(handleResponse)
    .then(saveUser);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function loginMfa(mfaToken, mfa_code) {
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + mfaToken 
    },
    body: JSON.stringify({ mfa_code })
  };

  // login successful if there's a jwt token in the response
  return fetch(`${apiUrl}/auth/login-otp`, requestOptions)
    .then(handleResponse)
    .then(saveUser);
}

function refreshToken(user) {
  const requestOptions = {
    method: 'POST',
    headers:  { 'Content-Type': 'application/json', ...authHeader(user.refreshToken) },
    body: JSON.stringify({refreshToken: user.refreshToken})
  }

  // get refresh token
  return fetch(`${apiUrl}/auth/token`, requestOptions)
    .then(handleResponse)
    .then(saveUser);
}

function resetPassword(oldPwd, newPwd, key) {
  const requestOptions = reqOptions()
  return axios.post(`${apiUrl}/auth/reset`, {oldPwd, newPwd, key}, requestOptions)
    // .then(wait(2000))
    .then(authService.handleResponseAxios);

}

function forgotPassword(email) {
  const requestOptions = reqOptions()
  return axios.post(`${apiUrl}/auth/forgot`, {email}, requestOptions)
    // .then(wait(20000))
    .then(authService.handleResponseAxios);

}

function verifyEmail(email) {
  const requestOptions = reqOptions()
  return axios.post(`${apiUrl}/auth/email/verify`, {email}, requestOptions)
    // .then(wait(2000))
    .then(authService.handleResponseAxios);

}

function confirmEmail(key) {
  const requestOptions = reqOptions()
  return axios.post(`${apiUrl}/auth/email/confirm`, {key}, requestOptions)
    // .then(wait(2000))
    .then(authService.handleResponseAxios);

}

function handleResponse(response) {
  if (!response.ok && response.status === 404) {
    const error = response.statusText || "Not Found";
    return Promise.reject(error);
  }
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        if (((response && response.url) || "").indexOf("/auth/login") >= 0) {
          logout();
          //window.location.reload(true); // Not required in the Auth service
        } else {
          authActions.logout()
          // history.push('/');
          // window.location.reload(true);
        }
      } 

      const error = (data && (data.message || data.error)) || response.statusText;
      return Promise.reject(error);
    }

    // decode displays array from binary to int
    if (data.payload)
      data.payload.displays = utils.displays_decode(data.payload)
    return data;
  });
}
function handleResponseBlob(response) {
  return response.blob().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        if (((response && response.url) || "").indexOf("/auth/login") >= 0) {
          logout();
          //window.location.reload(true); // Not required in the Auth service
        } else {
          authActions.logout()
          // history.push('/');
          // window.location.reload(true);
        }
      }

      const error = (data && (data.message || data.error)) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
function handleResponseAxios(response) {
  if (response.status === 200) {
    return response.data
  } else if (response.status === 401) {
    // auto logout if 401 response returned from api
    if (((response && response.url) || "").indexOf("/auth/login") >= 0) {
      logout();
      //window.location.reload(true); // Not required in the Auth service
    } else {
      authActions.logout()
      // history.push('/');
      // window.location.reload(true);
    }
  }
  const error = (response.data && (response.data.message || response.data.error)) || response.statusText;
  return Promise.reject(error);
}

function saveUser(user) {
  if (user.token) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user', JSON.stringify(user));
  }
  return user;
}
