import React, { Component } from 'react';
import { Container, Table, Button, ButtonGroup, Row, Col, UncontrolledTooltip, Badge, Tooltip, Popover, PopoverBody, PopoverHeader, Form, FormGroup, Label, Input } from 'reactstrap';
import { Spinner } from '../../../_components/Spinner';
import { FiFilter } from "react-icons/fi";
import { BiCircleHalf } from "react-icons/bi";
import moment from 'moment';
import { utils } from '../../../utils'
import { WeatherInfo } from '../../../_components/monitor/detailsTempShort';

// Define table number
const TABLE = {
  Sender: 1,
  Receiver: 2,
  Module: 3
};

// Define the port name
const PORT = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D'
}

// Define online status
const ONLINE_STATUS = {
  0: "Offline",
  1: "Online"
}

// Define the gradient colors
const LOW_COLOR = [8, 218, 230]; // LightBlue rgb(8, 218, 230)
const MID_COLOR = [255, 222, 89]; // Yellow rgb(255, 222, 89)
const HIGH_COLOR = [255, 0, 0]; // Red

// Defind the default minTemp and maxTemp
const DEFAULT_MIN_TEMP = 45;
const DEFAULT_MAX_TEMP = 75;

// Define the height of each receiver cell
const ELEMENT_HEIGHT = 69;

// Define border color
const BORDER_COLOR = 'rgba(255, 255, 255, 0.5)'

class TemperatureGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tableSelected: TABLE.Sender,
      showFilter: false,
      minTemp: DEFAULT_MIN_TEMP,
      maxTemp: DEFAULT_MAX_TEMP,
      hoveredModuleCell: null,
      moduleTooltipTarget: null,
      isPhotocellHovered: false,
    };
  }

  setTableSelected = (tableSelected) => {
    this.setState({ tableSelected });
  };

  toggleFilter = () => {
    this.setState(prevState => ({ showFilter: !prevState.showFilter }));
  };

  handleTempInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: parseInt(value)
    });
  };

  renderReceiverCell(cell, rowLength) {
    const { sender, port, position } = this.getPortInfo(cell);
    const { rom_id } = this.getSenderInfo(sender);
    const primSender = rom_id[0] ? rom_id[0] : rom_id[1];
    const primPort = port[0] || port[0] === 0 ? port[0] : port[1];
    const primPosition = position[0] || position[0] === 0 ? position[0] : position[1];
    const hasPhotocell = cell[0] && cell[1] && (cell[0].photocell || cell[1].photocell);

    return (
      <>
        {
          (rowLength > 8 && this.props.smallScreen) || (rowLength > 30)? (
            cell[0] && cell[1] && !cell[0].temperature && !cell[1].temperature ? (
              <div
                className='h-100 text-dark justify-content-center align-items-center d-flex bg-light'
                id={this.generateReceiverElementId(cell)}
              >
              </div>
            ):(
              <div
                style={{
                  backgroundColor: this.getReceiverColor(cell),
                }}
                className='h-100 text-dark justify-content-end align-items-start d-flex'
                id={this.generateReceiverElementId(cell)}
              >
                {hasPhotocell
                  ? this.renderPhotocellIcon(cell, [cell[0].photocell, cell[1].photocell])
                  : null
                }
              </div>
            )
            
          ) : (
            <Table 
              borderless 
              className="h-100 overflow-hidden m-0"
              style={{ tableLayout: 'fixed' }} 
              size='sm' 
              id={this.generateReceiverElementId(cell)}
            >

              {(rowLength > 4 && this.props.smallScreen) || (rowLength > 14)?
                (
                  <tbody
                    style={{
                      backgroundColor: this.getReceiverColor(cell),
                      position: 'relative'
                    }}
                  >
                    <tr>
                      <td className='text-truncate text-dark'>
                        {hasPhotocell
                          ? <div style={{ position: 'absolute', top:'0px', right: '2px' }}>{this.renderPhotocellIcon(cell, [cell[0].photocell, cell[1].photocell])}</div>
                          : null
                        }
                        {`${primSender}:${PORT[primPort]}:${primPosition}`}
                      </td>
                    </tr>
                      <tr>
                        {cell[0] && cell[1] && !cell[0].temperature && !cell[1].temperature ? (
                            <td className={'bg-light text-dark text-center'}>
                              -
                            </td>
                          ) : (
                            <td className={`text-truncate text-dark`}>
                              {`${cell[0] && cell[1]? ((cell[0].temperature + cell[1].temperature)/2).toFixed(1) : cell[0]? cell[0].temperature : cell[1].temperature}°C`}
                            </td>
                          )
                        }
                      </tr>
                  </tbody>
                ) : (
                  <tbody
                    style={{
                      backgroundColor: this.getReceiverColor(cell),
                      position: 'relative'
                    }}
                  >
                    <tr>
                      <td colSpan={2} 
                        className='text-truncate text-dark'
                      >
                        {hasPhotocell
                          ? <div style={{ position: 'absolute', top:'0px', right: '2px' }}>{this.renderPhotocellIcon(cell, [cell[0].photocell, cell[1].photocell])}</div>
                          : null
                        }
                        {`${primSender}:${PORT[primPort]}:${primPosition}`}
                      </td>
                    </tr>
                    <tr>
                      {cell && cell[0] && cell[0].temperature ? (
                          <td className={`text-truncate text-dark`}>
                            {cell[0].temperature}°C
                          </td>
                        ) : (
                          <td className={'bg-light text-dark text-center'}>
                            -
                          </td>
                        )
                      }
                      {cell && cell[1] && cell[1].temperature ? (
                          <td className={`text-truncate text-dark`}>
                            {cell[1].temperature}°C
                          </td>
                        ) : (
                          <td className={'bg-light text-dark text-center'}>
                            -
                          </td>
                        )
                      }
                    </tr>
                  </tbody>
                )
              }
            </Table>
          )
        }
        {this.renderReceiverTooltip(cell)}
      </>
    )
  }

  renderPhotocellIcon(cell, photocell) {
    return (
      <>
        <BiCircleHalf 
          style={{ transform: 'rotate(270deg)', outline:"none"}} 
          id={this.generatePhotocellElementId(cell)}
          onMouseEnter={this.handleMouseEnterPhotocell}
          onMouseLeave={this.handleMouseLeavePhotocell}
        />
        {this.props.smallScreen
          ? null
          : <UncontrolledTooltip
              placement="top"
              target={this.generatePhotocellElementId(cell)}
              delay={50}
            >
              Photocell: {photocell[0]} {photocell[1]}
            </UncontrolledTooltip>
        }
      </>
    )
  }

  handleMouseEnterPhotocell = () => {
    this.setState({
      isPhotocellHovered: true
    });
  };

  handleMouseLeavePhotocell = () => {
    this.setState({
      isPhotocellHovered: false
    });
  };

  renderReceiverTooltip(cell) {
    if(this.props.smallScreen) return null;

    const { sender, port, position, brightness } = this.getPortInfo(cell);
    const { rom_id } = this.getSenderInfo(sender);
    const lastbeat = [cell[0].lastbeat, cell[1].lastbeat];
    const ago = this.calculateLastbeat(lastbeat);

    const avgTemperature = ((cell[0] && cell[1] && cell[0].temperature && cell[1].temperature
      ? ((cell[0].temperature + cell[1].temperature) / 2).toFixed(1)
      : cell[0] && cell[0].temperature
        ? cell[0].temperature
        : cell[1].temperature
      ) || "-") + "°C";

    if((cell[0]&&cell[0].rstatus_id)||(cell[1]&&cell[1].rstatus_id)) {
      return !this.state.isPhotocellHovered && (<UncontrolledTooltip
          placement="top"
          target={this.generateReceiverElementId(cell)}
          delay={50}
          style={{ maxWidth: 'none', width: '310px' }}
        >
          <Table style={{ tableLayout: 'fixed' }} className="m-0 text-left overflow-hidden">
            <thead>
              <tr className='text-muted'>
                <th style={{borderTop: "none"}}>
                  <Badge color="warning" className={"p-2"}>
                      AVG: {avgTemperature}
                  </Badge>
                  
                </th>
                <th style={{borderTop: "none"}}>
                  Primary
                </th>
                <th style={{borderTop: "none"}}>
                  Backup
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='text-muted'>
                  <div className='pb-1'>Temperature</div>
                  <div className='pb-1'>Sender</div>
                  <div className='pb-1'>Port</div>
                  <div className='pb-1'>Position</div>
                  <div className='pb-1'>Status</div>
                  <div className='pb-1'>Brightness</div>
                  <div className='pb-1'>photocell</div>
                  <div className='pb-1'>Last Seen</div>
                </td>
                <td>
                  <div className='pb-1'>{cell[0].temperature != null ? `${cell[0].temperature}°C` : '-'}</div>
                  <div className='pb-1'>{rom_id[0] || '-'}</div>
                  <div className='pb-1'>{PORT[port[0]] || '-'}</div>
                  <div className='pb-1'>{position[0] !== null ? position[0] : '-'}</div>
                  <div className='pb-1'>{ONLINE_STATUS[cell[0].online] || '-'}</div>
                  <div className='pb-1'>{brightness[0] !== null ? brightness[0] : '-'}</div>
                  <div className='pb-1'>{cell[0].photocell != null ? `${cell[0].photocell}` : '-'}</div>
                  <div className='pb-1'>{`${ago[0].description}`}</div>
                </td>
                <td>
                  <div className='pb-1'>{cell[1].temperature != null ? `${cell[1].temperature}°C` : '-'}</div>
                  <div className='pb-1'>{rom_id[1] || '-'}</div>
                  <div className='pb-1'>{PORT[port[1]] || '-'}</div>
                  <div className='pb-1'>{position[1] !== null ? position[1] : '-'}</div>
                  <div className='pb-1'>{ONLINE_STATUS[cell[1].online] || '-'}</div>
                  <div className='pb-1'>{brightness[1] !== null ? brightness[1] : '-'}</div>
                  <div className='pb-1'>{cell[1].photocell != null ? `${cell[1].photocell}` : '-'}</div>
                  <div className='pb-1'>{`${ago[1].description}`}</div>
                </td>
              </tr>
            </tbody>
          </Table>
        </UncontrolledTooltip>
      )
    }

    return null;
  }

  renderReceiverTable() {
    const grid = this.props.receiverGrid;
    return (
      <Table bordered style={{ tableLayout: 'fixed', borderColor: BORDER_COLOR }} className='mb-0 overflow-hidden'>
        <tbody>
          {grid.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, colIndex) => 
                cell? (
                  (cell.receivers[0]) ? (
                    <td
                      key={colIndex}
                      className={`text-center text-truncate p-0 m-0`}
                      style={{ 
                        height: `${row.length > 30 ? ELEMENT_HEIGHT*0.5 : grid.length > 3?ELEMENT_HEIGHT:ELEMENT_HEIGHT*1.5}px`, 
                        borderColor: BORDER_COLOR
                      }}
                    >
                      {this.renderReceiverCell(cell.receivers, row.length)}
                    </td>
                  ) : (
                    <td className={'bg-light text-dark text-center'} style={{borderColor: BORDER_COLOR}}>
                      -
                    </td>
                  )
                ) : (
                  <td className={'text-dark text-center'} style={{borderColor: BORDER_COLOR}} key={colIndex}>
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  renderSenderTable() {
    const grid = this.props.senderGrid;
    return (
      <Table bordered style={{ tableLayout: 'fixed', borderColor: BORDER_COLOR }} className='mb-0 overflow-hidden'>
        <tbody>
          {grid.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, colIndex) => {
                if(!cell) {
                  return (<td className={'text-dark text-center'} style={{borderColor: BORDER_COLOR}} key={colIndex}></td>);
                } 
                if(cell === "null") return null;
                const { port } = this.getPortInfo(cell.receiverList);
                const { photocell } = this.getSenderInfo(cell.sender_id);
                const hasPhotocell = photocell[0] || photocell[1];
                const primPort = port[0] || port[0] === 0 ? port[0] : port[1];
                return cell.receiverList && this.calculateAvgTemp(cell.receiverList) !== 0 ? (
                  <td
                    key={colIndex}
                    rowSpan={cell.rowSpan}
                    colSpan={cell.colSpan}
                    className={`text-center text-dark text-truncate p-0 m-0`}
                    style={{
                      height: `${cell.rowSpan * (row.length > 30 ? ELEMENT_HEIGHT*0.5 : grid.length > 3?ELEMENT_HEIGHT:ELEMENT_HEIGHT*1.5)}px`,
                      width: `${(cell.colSpan / row.length * 100)}%`,
                      backgroundColor: this.getColor(this.calculateAvgTemp(cell.receiverList)),
                      borderColor: BORDER_COLOR,
                      position: 'relative'
                    }}
                    id={this.generateSenderElementId(cell.receiverList)}
                  >
                    {hasPhotocell
                      ? <div style={{ position: 'absolute', top:'0px', right: '2px' }}>{this.renderPhotocellIcon(cell.receiverList, photocell)}</div>
                      : null
                    }
                    {`${this.getSenderName(cell.sender_id)} - PORT ${PORT[primPort]}`}
                    <br className='m-0 p-0'/>
                    {this.calculateAvgTemp(cell.receiverList)}°C
                    {this.renderSenderTooltip(cell)}
                  </td>
                ) : (
                  <td 
                    key={colIndex}
                    rowSpan={cell.rowSpan}
                    colSpan={cell.colSpan}
                    className={'bg-light text-dark text-center overflow-hidden'}
                    style={{
                      height: `${cell.rowSpan * (row.length > 30 ? ELEMENT_HEIGHT*0.5 : grid.length > 3?ELEMENT_HEIGHT:ELEMENT_HEIGHT*1.5)}px`,
                      borderColor: BORDER_COLOR
                    }}
                    id={this.generateSenderElementId(cell.receiverList)}
                  >
                    -
                  {this.renderSenderTooltip(cell)}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  renderSenderTooltip(sender) {
    if(this.props.smallScreen) return null;

    const { rom_id, nickname, lastbeat, online, lan, photocell } = this.getSenderInfo(sender.sender_id);
    const ago = this.calculateLastbeat(lastbeat);
    
    if((sender.receiverList[0]&&sender.receiverList[0].rstatus_id)||(sender.receiverList[1]&&sender.receiverList[1].rstatus_id)) {
      return !this.state.isPhotocellHovered && (<UncontrolledTooltip
          placement="top"
          target={this.generateSenderElementId(sender.receiverList)}
          delay={50}
          style={{ maxWidth: 'none', width: '350px' }}
        >
          <Table style={{ tableLayout: 'fixed' }} className="m-0 text-left overflow-hidden">
            <thead>
              <tr className='text-muted'>
                <th style={{borderTop: "none"}}>
                  <Badge color="warning" className={"p-2"}>
                      AVG: {this.calculateAvgTemp(sender.receiverList) || "-"}°C
                  </Badge>
                </th>
                <th style={{borderTop: "none"}}>
                  Primary
                </th>
                <th style={{borderTop: "none"}}>
                  Backup
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='text-muted'>
                  <div className='pb-1'>ROM_id</div>
                  <div className='pb-1'>Name</div>
                  <div className='pb-1'>Lan</div>
                  <div className='pb-1'>Status</div>
                  <div className='pb-1'>Photocell</div>
                  <div className='pb-1'>Last Seen</div>
                </td>
                <td>
                  <div className='pb-1'>{rom_id[0] || '-'}</div>
                  <div className='pb-1 text-truncate'>{nickname[0] || '-'}</div>
                  <div className='pb-1'>{lan[0] || '-'}</div>
                  <div className='pb-1'>{ONLINE_STATUS[online[0]] || '-'}</div>
                  <div className='pb-1'>{photocell[0] || '-'}</div>
                  <div className='pb-1'>{`${ago[0].description}`}</div>
                </td>
                <td>
                  <div className='pb-1'>{rom_id[1] || '-'}</div>
                  <div className='pb-1 text-truncate'>{nickname[1] || '-'}</div>
                  <div className='pb-1'>{lan[1] || '-'}</div>
                  <div className='pb-1'>{ONLINE_STATUS[online[1]] || '-'}</div>
                  <div className='pb-1'>{photocell[1] || '-'}</div>
                  <div className='pb-1'>{`${ago[1].description}`}</div>
                </td>
              </tr>
            </tbody>
          </Table>
        </UncontrolledTooltip>
      )
    }

    return null;
  }
  
  handleMouseEnterModule = (cell, event) => {
    this.setState({
      hoveredModuleCell: cell,
      moduleTooltipTarget: event.currentTarget.id,
    });
  };

  handleMouseLeaveModule = () => {
    this.setState({
      hoveredModuleCell: null,
      moduleTooltipTarget: null,
    });
  };

  renderModuleTooltip() {
    const { hoveredModuleCell, moduleTooltipTarget } = this.state;

    // if not hovered, doesn't render
    if (!hoveredModuleCell || !moduleTooltipTarget) return null;

    // if it is small screen, doesn't render
    if(moduleTooltipTarget.endsWith("s")) return null;

    // if module has empty data, doesn't render
    if(moduleTooltipTarget.startsWith("null")) return null;

    return (!!document.getElementById(moduleTooltipTarget)
      ? (<UncontrolledTooltip
          placement="top"
          target={moduleTooltipTarget}
          isOpen={!!hoveredModuleCell}
          delay={50}
        >
          <div className="text-left">Temperature {this.getModuleTemperature(hoveredModuleCell.temperature)}°C</div>
          <div className="text-left">Hubport {hoveredModuleCell.hubport}</div>
          <div className="text-left">Position {hoveredModuleCell.position}</div>
        </UncontrolledTooltip>)
      : null
    );
  }

  renderModules(modules) {
    return (
      <Table borderless style={{ tableLayout: 'fixed' }} className="module-table">
        <tbody>
          {modules.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, colIndex) =>
                cell ? (
                  <td
                    key={colIndex}
                    className={`module-cell`}
                    id={this.generateModuleElementId(cell)}
                    style={{
                      backgroundColor: this.state.moduleTooltipTarget === this.generateModuleElementId(cell) && !this.props.smallScreen && !this.state.moduleTooltipTarget.startsWith("null")
                        ? 'grey'
                        : this.getColor(this.getModuleTemperature(cell.temperature))
                    }}
                    onMouseEnter={(event) => this.handleMouseEnterModule(cell, event)}
                    onMouseLeave={this.handleMouseLeaveModule}
                  >
                    {this.state.moduleTooltipTarget === this.generateModuleElementId(cell) && this.renderModuleTooltip()}
                  </td>
                ) : (
                  <td key={colIndex} className={'empty-cell'}></td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  renderModuleTable() {
    const grid = this.props.moduleGrid;

    return (
      <Table bordered style={{ tableLayout: 'fixed' }} className="mb-0 overflow-hidden">
        <tbody>
          {grid.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, colIndex) =>
                cell ? (
                  <td
                    key={colIndex}
                    className={`text-center text-truncate p-0 m-0`}
                    style={{ height: `${row.length > 30 ? ELEMENT_HEIGHT*0.5 : grid.length > 3?ELEMENT_HEIGHT:ELEMENT_HEIGHT*1.5}px`, borderColor: BORDER_COLOR }}
                  >
                    {this.renderModules(cell)}
                  </td>
                ) : (
                  <td className={'text-dark text-center'} style={{ borderColor: BORDER_COLOR }} key={colIndex}></td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  renderTemperatureLagend() {
    const gradientStyle = {
      background: `linear-gradient(to top, rgb(${LOW_COLOR.join(', ')}), rgb(${MID_COLOR.join(', ')}), rgb(${HIGH_COLOR.join(', ')}))`,
      height: '100%',
      width: '30px',
      minHeight: '120px'
    };

    let { minTemp, maxTemp } = this.state;
    if(!minTemp && minTemp !== 0)
      minTemp = DEFAULT_MIN_TEMP;
    if(!maxTemp && maxTemp !== 0)
      maxTemp = DEFAULT_MAX_TEMP;

    // Calculate temperature range and interval
    const range = maxTemp - minTemp;
    const numLabels = 5;
    const interval = range / (numLabels - 1);
    let middleCols = [];
    for (let i = numLabels - 2; i > 0; i--) {
      middleCols.push(
        <Col key={i} className="d-flex align-items-center justify-content-center text-dark">
          <small>{Math.round(minTemp + i * interval)}°C</small>
        </Col>
      );
    }

    return (
      <div className="d-flex flex-column align-items-center justify-content-between h-100">
        <div style={gradientStyle} className="d-flex align-items-center justify-content-center">
          <Row className="h-100">
            <Col className="d-flex justify-content-center text-dark pt-1"><small>{maxTemp}°C</small></Col>
            {middleCols}
            <Col className="d-flex align-items-end justify-content-center text-dark pb-1"><small>{minTemp}°C</small></Col>
          </Row>
        </div>
      </div>
    );
  }

  renderSenderStats(display, currentTemperature, smallScreen) {
    return <Row className="text-center d-flex">
      <Col sm={12} md className="mb-sm-2 mb-0 d-flex pl-0 justify-content-center align-items-center">
        {display
          ? (
            smallScreen
            ? (
              <span className={`align-self-end badge badge-transparent text-muted p-0`}>
                <WeatherInfo display={{...{DisplayId: display.DisplayId + "s", Address: display.Address}}} />
              </span>
            )
            : (
              <span className={`align-self-end badge badge-transparent text-muted p-0`}>
                <WeatherInfo display={{...{DisplayId: display.DisplayId + "l", Address: display.Address}}} />
              </span>
            )
              
          )
          : null
        }
        
        <div className="text-muted pr-1">Weather</div>
        <strong title={"Weather temperature"}>
          {currentTemperature != null ? `${utils.Round(currentTemperature)}°C` : '-'}
        </strong>
      </Col>
    </Row>
  }

  renderStats(display, currentTemperature, temperatureStats, smallScreen) {
    const { avg, min, max, mid, outliers } = temperatureStats;
    return <Row className="text-center d-flex">
      <Col sm={12} md className="mb-sm-2 mb-0 d-flex justify-content-center align-items-center">
        {display
          ? (
            smallScreen
            ? (
              <span className={`align-self-end badge badge-transparent text-muted p-0`}>
                <WeatherInfo display={{...{DisplayId: display.DisplayId + "s", Address: display.Address}}} />
              </span>
            )
            : (
              <span className={`align-self-end badge badge-transparent text-muted p-0`}>
                <WeatherInfo display={{...{DisplayId: display.DisplayId + "l", Address: display.Address}}} />
              </span>
            )
              
          )
          : null
        }
        
        <div className="text-muted pr-1">Weather</div>
        <strong title={"Weather temperature"}>
          {currentTemperature != null ? `${utils.Round(currentTemperature)}°C` : '-'}
        </strong>
      </Col>
      <Col sm={12} md className="mb-sm-2 mb-0 d-flex pl-0 justify-content-center align-items-center">
        <div className="text-muted pr-1">Avg</div>
        <strong title={"Average receiver temperature"}>
          {avg != null ? `${avg}°C` : '-'}
        </strong>
      </Col>
      <Col sm={12} md className="mb-sm-2 mb-0 d-flex pl-0 justify-content-center align-items-center">
        <div className="text-muted pr-1">Mid</div>
        <strong title={"Median receiver temperature"}>
          {mid != null ? `${mid}°C` : '-'}
        </strong>
      </Col>
      <Col sm={12} md className="mb-sm-2 mb-0 d-flex pl-0 justify-content-center align-items-center">
        <div className="text-muted pr-1">Max</div>
        <strong title={"Maximum receiver temperature"}>
          {max != null ? `${max}°C` : '-'}
        </strong>
      </Col>
      <Col sm={12} md className="mb-sm-2 mb-0 d-flex pl-0 justify-content-center align-items-center">
        <div className="text-muted pr-1">Min</div>
        <strong title={"Minimum receiver temperature"}>
          {min != null ? `${min}°C` : '-'}
        </strong>
      </Col>
      <Col sm={12} md className="mb-sm-2 mb-0 d-flex pl-0 justify-content-center align-items-center">
        <div className="text-muted pr-1">Outlier</div>
        <strong title={"Number of receiver temperature outliers"}>
          {outliers && outliers.length != null ? outliers.length : '-'}
        </strong>
      </Col>
    </Row>
  }

  render() {
    const { display, currentTemperature, smallScreen, senderGrid, moduleGrid, receiverGrid } = this.props;
    const receiverTemperatureStats = this.calculateReceiverStats(receiverGrid);
    const moduleTemperatureStats = this.calculateModuleStats(moduleGrid);
    return (
      <>
        {this.state.tableSelected === TABLE.Sender 
          ? this.renderSenderStats(display, currentTemperature, smallScreen)
          : this.state.tableSelected === TABLE.Receiver 
            ? this.renderStats(display, currentTemperature, receiverTemperatureStats, smallScreen) 
            : this.renderStats(display, currentTemperature, moduleTemperatureStats, smallScreen)
        }
        <Row className={'mb-1 mt-1'}>
          <Col className={'justify-content-center align-items-center d-flex'}>
            {this.props.loading?(
              <Spinner loading={this.props.loading} button={false}></Spinner>
            ):(
              this.state.tableSelected === TABLE.Sender ? this.renderSenderTable() : this.state.tableSelected === TABLE.Receiver ? this.renderReceiverTable() : this.renderModuleTable()
            )}
          </Col>
          <Col className={"col-sm-auto pl-0"}>
            {this.renderTemperatureLagend()}
          </Col>
        </Row>
        
        {this.state.tableSelected === TABLE["Sender"]
          ? (<small className="text-muted d-block mb-3">* Sender Temperature is the average of its receivers.</small>)
          : (<small className="text-muted d-block mb-3">&nbsp;</small>)
        }

        <div className="d-flex justify-content-center mb-3">
          <ButtonGroup>
            <Button
              outline
              onClick={() => this.setTableSelected(TABLE.Sender)}
              active={this.state.tableSelected === TABLE.Sender}
            >
              Sender
            </Button>
            <Button
              outline
              onClick={() => this.setTableSelected(TABLE.Receiver)}
              active={this.state.tableSelected === TABLE.Receiver}
            >
              Receiver
            </Button>
            <Button
              outline
              onClick={() => this.setTableSelected(TABLE.Module)}
              active={this.state.tableSelected === TABLE.Module}
            >
              Module
            </Button>
          </ButtonGroup>
        </div>
        
      </>
    );
  }

  // helper function

  getReceiverColor(cell) {
    return cell[0] && cell[1] && cell[0].temperature && cell[1].temperature
    ? this.getColor(((cell[0].temperature + cell[1].temperature)/2).toFixed(1)) 
    : cell[0] && cell[0].temperature
      ? this.getColor(cell[0].temperature) 
      : this.getColor(cell[1].temperature)
  }

  calculateStats(temperatures, statusMap) {
    const result = {
      avg: null,
      min: null,
      max: null,
      mid: null,
      outliers: new Set(),
    };
    // If there are no temperatures, return the result with null values
    if (temperatures.length === 0) {
      return result;
    }
  
    // Calculate the average temperature
    const sum = temperatures.reduce((acc, temp) => acc + temp, 0);
    result.avg = (sum / temperatures.length).toFixed(1);
  
    // Find the minimum and maximum temperatures
    result.min = Math.min(...temperatures);
    result.max = Math.max(...temperatures);
  
    // Determine the median temperature
    temperatures.sort((a, b) => a - b);
    const midIndex = Math.floor(temperatures.length / 2);
    result.mid = temperatures[midIndex];

    // Identify outliers using the interquartile range (IQR) method
    const q1 = temperatures[Math.floor((temperatures.length / 4))];
    const q3 = temperatures[Math.floor((temperatures.length * 3) / 4)];
    const iqr = q3 - q1;
    const lowerBound = q1 - 1.5 * iqr;
    const upperBound = q3 + 1.5 * iqr;

    // unique rstatus_ids for outliers
    temperatures
      .filter(temp => temp < lowerBound || temp > upperBound)
      .forEach(temp => statusMap.get(temp).forEach(status_id => result.outliers.add(status_id)));

    // Convert the Set to an array for the final result
    result.outliers = Array.from(result.outliers);
  
    return result;
  }

  getModuleTemperatureandId(module) {
    const [temp1, temp2] = module.temperature;
    let temperature = null;
    let id = null;
    if (temp1 !== null && temp2 !== null) {
      temperature = (temp1 + temp2) / 2; // Return the average if both exist
      id = module.mstatus_id[0]; //set the id to be first mstatus_id
    } else if (temp1 !== null) {
      temperature = temp1; // Return the first if it exists
      id = module.mstatus_id[0]; //set the id to be first mstatus_id
    } else if (temp2 !== null) {
      temperature = temp2; // Return the second if it exists
      id = module.mstatus_id[1]; //set the id to be first mstatus_id
    }
    return {
      temperature: temperature,
      mstatus_id: id,
    }
  }

  calculateModuleStats(moduleGrid) {
    // Extract all temperatures and corresponding mstatus_ids from the grid
    const temperatures = [];
    const mstatusMap = new Map();
    for (const row of moduleGrid) {
      for (const receiver of row) {
        if(receiver) { //if receiver exists
          for (const innerRow of receiver) {
            for (const module of innerRow) {
              if(module && module.temperature) { //if module exists
                //push the temperature-mid set in the map
                //if first module temperature exists
                const { temperature, mstatus_id } = this.getModuleTemperatureandId(module);
                if(temperature || temperature === 0) {
                  temperatures.push(temperature);
                  if (!mstatusMap.has(temperature)) {
                    mstatusMap.set(temperature, []);
                  }
                  mstatusMap.get(temperature).push(mstatus_id);
                }
              }
            }
          }
        }
      }
    }

    return this.calculateStats(temperatures, mstatusMap);

  }

  calculateReceiverStats(receiverGrid) {
    // Extract all temperatures and corresponding rstatus_ids from the grid
    const temperatures = [];
    const rstatusMap = new Map();
    
    for (const row of receiverGrid) {
      for (const cell of row) {
        if(cell) {
          for (const receiver of cell.receivers) {
            if(receiver.temperature || receiver.temperature === 0) {
              temperatures.push(receiver.temperature);
              if (!rstatusMap.has(receiver.temperature)) {
                rstatusMap.set(receiver.temperature, []);
              }
              rstatusMap.get(receiver.temperature).push(receiver.rstatus_id);
            }
          }
        }
      }
    }
    return this.calculateStats(temperatures, rstatusMap);
  }

  // get background color
  getColor = (temperature) => {

    let { minTemp, maxTemp } = this.state;
    if(!minTemp && minTemp !== 0)
      minTemp = DEFAULT_MIN_TEMP;
    if(!maxTemp && maxTemp !== 0)
      maxTemp = DEFAULT_MAX_TEMP;

    const midTemp = (minTemp + maxTemp) / 2;

    // Helper function to interpolate between two colors
    const interpolateColor = (color1, color2, factor) => {
      const result = color1.slice();
      for (let i = 0; i < 3; i++) {
        // linear interpolation for each number: y = a + factor * (b - a)
        //factor between 0-1, represents the distance between a and b
        result[i] = Math.round(result[i] + factor * (color2[i] - result[i]));
      }
      return result;
    };

    // Helper function to convert RGB array to hex color string
    const rgbToHex = (rgb) => {
      return '#' + rgb.map(value => {
        const hex = value.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      }).join('');
    };

    if (temperature === null) {
      return '#FFFFFF'; //white
    }

    if(temperature <= minTemp)
      return rgbToHex(LOW_COLOR);

    if(temperature >= maxTemp)
      return rgbToHex(HIGH_COLOR);

    // Calculate the color based on the temperature
    let color;
    if (temperature <= midTemp) {
      const factor = (temperature - minTemp) / (midTemp - minTemp);
      color = interpolateColor(LOW_COLOR, MID_COLOR, factor);
    } else {
      const factor = (temperature - midTemp) / (maxTemp - midTemp);
      color = interpolateColor(MID_COLOR, HIGH_COLOR, factor);
    }

    // Return the color as a hex string
    return rgbToHex(color);
  };

  getSenderName(sender_id) {
    const receiversStatus = this.props.status;
    let name = null;
    // get the sender info from senderMap
    if(receiversStatus && receiversStatus.senderMap) {
      const senderMap = receiversStatus.senderMap;
      if(sender_id && sender_id[0] && senderMap.has(sender_id[0])) {
        name = senderMap.get(sender_id[0]).nickname.split(" ")[0];
      } else if(sender_id && sender_id[1] && senderMap.has(sender_id[1])) {
        name = senderMap.get(sender_id[1]).nickname.split(" ")[0];
      }
    }
    return name;
  }

  getSenderInfo(sender_id) {
    const receiversStatus = this.props.status;
    let rom_id = [null, null];
    let nickname = [null, null];
    let lastbeat = [null, null];
    let online = [null, null];
    let lan = [null, null];
    let photocell = [null, null];

    if(receiversStatus && receiversStatus.senderMap) {
      if(sender_id && sender_id[0] && receiversStatus.senderMap.has(sender_id[0])) {
        rom_id[0] = receiversStatus.senderMap.get(sender_id[0]).rom_id;
        nickname[0] = receiversStatus.senderMap.get(sender_id[0]).nickname;
        lastbeat[0] = receiversStatus.senderMap.get(sender_id[0]).lastbeat;
        online[0] = receiversStatus.senderMap.get(sender_id[0]).online;
        lan[0] = receiversStatus.senderMap.get(sender_id[0]).lan;
        photocell[0] = receiversStatus.senderMap.get(sender_id[0]).photocell;
      }
      if(sender_id && sender_id[1] && receiversStatus.senderMap.has(sender_id[1])) {
        rom_id[1] = receiversStatus.senderMap.get(sender_id[1]).rom_id;
        nickname[1] = receiversStatus.senderMap.get(sender_id[1]).nickname;
        lastbeat[1] = receiversStatus.senderMap.get(sender_id[1]).lastbeat;
        online[1] = receiversStatus.senderMap.get(sender_id[1]).online;
        lan[1] = receiversStatus.senderMap.get(sender_id[1]).lan;
        photocell[1] = receiversStatus.senderMap.get(sender_id[1]).photocell;
      }
    }

    return {
      rom_id: rom_id,
      nickname: nickname,
      lastbeat: lastbeat,
      online: online,
      lan: lan,
      photocell: photocell,
    }
  }

  // get port_number, sender_id, and receiver position from portMap of a cell of receivers
  getPortInfo(cell) {
    const receiversStatus = this.props.status;
    let port = [null, null];
    let sender = [null, null];
    let position = [null, null];
    let brightness = [null, null];
    if(receiversStatus && receiversStatus.portMap) {
      if(cell && cell[0] && receiversStatus.portMap.get(cell[0].pstatus_id)) {
        port[0] = receiversStatus.portMap.get(cell[0].pstatus_id).port_number;
        brightness[0] = receiversStatus.portMap.get(cell[0].pstatus_id).brightness;
        sender[0] = cell[0].sender_id;
        position[0] = cell[0].position;
      } 
      if (cell[1] && receiversStatus.portMap.get(cell[1].pstatus_id)) {
        port[1] = receiversStatus.portMap.get(cell[1].pstatus_id).port_number;
        brightness[1] = receiversStatus.portMap.get(cell[1].pstatus_id).brightness;
        sender[1] = cell[1].sender_id;
        position[1] = cell[1].position;
      }
    }

    return {
      position: position,
      sender: sender,
      port: port,
      brightness: brightness,
    }
  }

  // calculate the average temprature of receiver list
  calculateAvgTemp(receiverList) {
    if (!receiverList || receiverList.length === 0)
      return 0;

    const validReceivers = receiverList.filter(receiver => receiver != null && receiver.temperature != null);
    if (validReceivers.length === 0)
      return 0;

    const totalTemperature = validReceivers.reduce((acc, receiver) => acc + receiver.temperature, 0);
    return (totalTemperature / validReceivers.length).toFixed(1);
  }

  // return the temperature of a module
  getModuleTemperature(temperatureArray) {
    const [temp1, temp2] = temperatureArray;
    if (temp1 !== null && temp2 !== null) {
      return (temp1 + temp2) / 2; // Return the average if both exist
    } else if (temp1 !== null) {
      return temp1; // Return the first if it exists
    } else if (temp2 !== null) {
      return temp2; // Return the second if it exists
    } else {
      return null; // Return null if neither exists
    }
  }

  calculateLastbeat(lastbeat) {
    const lastBeat = [null, null];
    lastBeat[0] = (!lastbeat[0]) ? lastbeat[0] : moment.tz(lastbeat[0], "YYYY-MM-DD hh:mm:ss", "UTC").tz(moment.tz.guess());
    lastBeat[1] = (!lastbeat[1]) ? lastbeat[1] : moment.tz(lastbeat[1], "YYYY-MM-DD hh:mm:ss", "UTC").tz(moment.tz.guess());

    const ago = [null, null];
    ago[0] = utils.timeAgo(lastBeat[0], 365*24*60, "minutes");
    ago[1] = utils.timeAgo(lastBeat[1], 365*24*60, "minutes");

    if (ago[0].minutes<=12) {
      ago[0].percent = 100;
      ago[0].description = "now";
    } else {
      if (ago.percent>0 && ago.percent<100) {
        ago[0].percent = 100 - ago[0].percent;
      }
    }

    if (ago[1].minutes<=12) {
      ago[1].percent = 100;
      ago[1].description = "now";
    } else {
      if (ago.percent>0 && ago.percent<100) {
        ago[1].percent = 100 - ago[1].percent;
      }
    }
    return ago;
  }

  generatePhotocellElementId(cell) {
    // prefix: rid
    // if first rstatus exists, use first
    // else if second rstatus exists, use second
    // else use null
    // append 's' if it is small screen, else append 'l'
    // example id: 'id10000s'
    return (cell[0]&&cell[0].rstatus_id?`photo${cell[0].rstatus_id}`:cell[1]&&cell[1].rstatus_id?`photo${cell[1].rstatus_id}`:'null')+(this.props.smallScreen?'-s':'-l');
  }

  generateSenderElementId(cell) {
    // prefix: rid
    // if first rstatus exists, use first
    // else if second rstatus exists, use second
    // else use null
    // append 's' if it is small screen, else append 'l'
    // example id: 'id10000s'
    return (cell[0]&&cell[0].rstatus_id?`sid${cell[0].rstatus_id}`:cell[1]&&cell[1].rstatus_id?`sid${cell[1].rstatus_id}`:'null')+(this.props.smallScreen?'-s':'-l');
  }

  generateReceiverElementId(cell) {
    // prefix: rid
    // if first rstatus exists, use first
    // else if second rstatus exists, use second
    // else use null
    // append 's' if it is small screen, else append 'l'
    // example id: 'id10000s'
    return (cell[0]&&cell[0].rstatus_id?`rid${cell[0].rstatus_id}`:cell[1]&&cell[1].rstatus_id?`rid${cell[1].rstatus_id}`:'null')+(this.props.smallScreen?'-s':'-l');
  }

  generateModuleElementId(cell) {
    // prefix: mid
    // if first mstatus exists, use first
    // else if second mstatus exists, use second
    // else use null
    // append 's' if it is small screen, else append 'l'
    // example id: 'id10000s'
    return (cell.mstatus_id[0]?`mid${cell.mstatus_id[0]}`:cell.mstatus_id[1]?`mid${cell.mstatus_id[1]}`:'null')+(this.props.smallScreen?"-s":"-l");
  }
}

export default TemperatureGrid;