export function configureFakeBackend() {
  // let users = [{ id: 1, username: 'test', password: 'test', firstName: 'Test', lastName: 'User' }];
  let realFetch = window.fetch;
  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      // wrap in timeout to simulate server api call
      setTimeout(() => {
        // console.log("# fetch:", url, opts)
        // // authenticate
        // if (url.endsWith('/auth/login') && opts.method === 'POST') {
        //   // get parameters from post request
        //   let params = JSON.parse(opts.body);

        //   // find if any user matches login credentials
        //   let filteredUsers = users.filter(user => {
        //     return user.username === params.username && user.password === params.password;
        //   });

        //   if (filteredUsers.length) {
        //     // if login details are valid return user details and fake jwt token
        //     let user = filteredUsers[0];
        //     let responseJson = {
        //       id: user.id,
        //       username: user.username,
        //       firstName: user.firstName,
        //       lastName: user.lastName,
        //       token: 'fake-jwt-token'
        //     };
        //     resolve({ ok: true, json: () => Promise.resolve(responseJson) });
        //   } else {
        //     // else return error
        //     reject('Username or password is incorrect');
        //   }

        //   return;
        // }

        // // get users
        // if (url.endsWith('/users') && opts.method === 'GET') {
        //   // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
        //   if (opts.headers && opts.headers.Authorization === 'Bearer fake-jwt-token') {
        //     resolve({ ok: true, json: () => Promise.resolve(users) });
        //   } else {
        //     // return 401 not authorised if token is null or invalid
        //     reject('Unauthorised');
        //   }

        //   return;
        // }

        // get fake alerts
        if (url.endsWith('/alerts?') && opts.method === 'GET') {
          console.log("FAKE API:", opts)
          // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
          if (opts.headers && opts.headers.authorization) {
            resolve({ ok: true, json: () => Promise.resolve(fakeAlerts) });
          } else {
            // return 401 not authorised if token is null or invalid
            reject('Unauthorised');
          }
          return;
        }

        // put Displays
        if (url.endsWith('/displays/8367/detail') && opts.method === 'PUT') {
          // fake display update
          console.log("# fake PUT `/displays` api", opts)
          if (opts.headers && opts.headers.authorization) {
            // let params = JSON.parse(opts.body);

            resolve({ ok: true, json: () => Promise.resolve({DisplayId:1, Monitored:true}) });
          } else {
            // return 401 not authorised if token is null or invalid
            reject('Unauthorised');
          }

          return;
        }


        // pass through any requests not handled above
        realFetch(url, opts)
        .then(response => resolve(response))
        .catch(err => reject(err));

      }, 1500);
    });
  }
}

const fakeAlerts = [
  {
      "AlertId": "20f32e97-0d1d-11ed-9947-720e3c66c8ce",
      "DisplayId": 1105,
      "AlertType": "BrightnessControl",
      "Item": {
          "Position": {
              "Height": 0,
              "Row": 0,
              "Column": 0,
              "Width": 0
          },
          "Type": "Display",
          "Id": 1105
      },
      "Summary": {
          "Source": "self",
          "State": "error"
      },
      "Severity": "Critical",
      "ResolveTime": "nil",
      "CreationTime": "2022-07-26 19:57:07",
      "Description": {
          "Title": "Brightness control changed",
          "ShortBody": "brightnesses control is supposed to be 'time' based all the time but it was changed to '(photocell)'.",
          "LongBody": null
      },
      "ExpiryTime": 1661543827,
      "Lifecycle": "Firing"
  },
  {
      "AlertId": "ad0915aa-1d6e-11ed-bbc0-ba7b1ea78bcd",
      "DisplayId": 1105,
      "AlertType": "State",
      "Item": {
          "Position": {
              "Height": 0,
              "Row": 0,
              "Column": 0,
              "Width": 0
          },
          "Type": "Port",
          "Id": 2392
      },
      "Summary": {
          "Source": "self",
          "State": "offline"
      },
      "Severity": "Error",
      "ResolveTime": "nil",
      "CreationTime": "2022-08-16 14:21:10",
      "Description": {
          "Title": "Port Offline",
          "ShortBody": "Port A on Sender FFE2 and Port A on Sender 8544 are offline",
          "LongBody": "Port A on Sender FFE2 and Port A on Sender 8544 have both been offline since 2021-06-24 12:06:20 EDT"
      },
      "ExpiryTime": 1663338070,
      "Lifecycle": "Firing"
  },
  {
      "AlertId": "9b9a78fe-1d71-11ed-9b2a-dad47f1a2fd8",
      "DisplayId": 1105,
      "AlertType": "State",
      "Item": {
          "Position": {
              "Height": 0,
              "Row": 0,
              "Column": 0,
              "Width": 0
          },
          "Type": "Display",
          "Id": 1105
      },
      "Summary": {
          "Source": "self",
          "State": "offline"
      },
      "Severity": "Critical",
      "ResolveTime": "nil",
      "CreationTime": "2022-08-16 14:42:09",
      "Description": {
          "Title": "Display Offline",
          "ShortBody": "Display is offline",
          "LongBody": "Display has been offline (power, internet, or computer) since 2021-07-13 09:48:44 EDT. This is likely due to internet connectivity: in this state, the display continues to play the normally scheduled content"
      },
      "ExpiryTime": 1663339329,
      "Lifecycle": "Firing"
  },
  {
      "AlertId": "1b9a78fe-1d71-11ed-9b2a-dad47f1a2fd8",
      "DisplayId": 1050,
      "AlertType": "State",
      "Item": {
          "Position": {
              "Height": 0,
              "Row": 0,
              "Column": 0,
              "Width": 0
          },
          "Type": "Display",
          "Id": 1105
      },
      "Summary": {
          "Source": "self",
          "State": "offline"
      },
      "Severity": "Critical",
      "ResolveTime": "nil",
      "CreationTime": "2022-08-16 14:42:09",
      "Description": {
          "Title": "Display Offline",
          "ShortBody": "Display is offline",
          "LongBody": "Display has been offline (power, internet, or computer) since 2021-07-13 09:48:44 EDT. This is likely due to internet connectivity: in this state, the display continues to play the normally scheduled content"
      },
      "ExpiryTime": 1663339329,
      "Lifecycle": "Firing"
  },
  {
      "AlertId": "2b9a78fe-1d71-11ed-9b2a-dad47f1a2fd8",
      "DisplayId": 1050,
      "AlertType": "State",
      "Item": {
          "Position": {
              "Height": 0,
              "Row": 0,
              "Column": 0,
              "Width": 0
          },
          "Type": "Display",
          "Id": 1105
      },
      "Summary": {
          "Source": "self",
          "State": "offline"
      },
      "Severity": "Critical",
      "ResolveTime": "nil",
      "CreationTime": "2022-08-16 14:42:09",
      "Description": {
          "Title": "Display Offline",
          "ShortBody": "Display is offline",
          "LongBody": "Display has been offline (power, internet, or computer) since 2021-07-13 09:48:44 EDT. This is likely due to internet connectivity: in this state, the display continues to play the normally scheduled content"
      },
      "ExpiryTime": 1663339329,
      "Lifecycle": "Firing"
  }
]