import { brightnessConstants } from '../_constants';
import { brightnessService } from '../_services';
import axios from 'axios';

export const brightnessActions = {
  getHistory,
  getConfig,
  putTimeConfig,
  putPhotocellConfig,
  putHoldConfig,
  putSelectedProfile
};

function getHistory(id, date) {
  return dispatch => {
    dispatch(request());

    brightnessService.getHistory(id, date)
      .then(
        history => {
          dispatch(success(history));
          },
        error => {
          if (axios.isCancel(error)) {
            dispatch(cancel(error.message));
          } else {
            dispatch(failure(error));
          }
        }
      );
  };

  function request() { return { type: brightnessConstants.GETHISTORY_REQUEST } }
  function success(history) { return { type: brightnessConstants.GETHISTORY_SUCCESS, history } }
  function failure(error) { return { type: brightnessConstants.GETHISTORY_FAILURE, error } }
  function cancel(cancelMsg) { return { type: brightnessConstants.GETHISTORY_CANCEL, cancelMsg } }
}

function getConfig(id) {
  return dispatch => {
    dispatch(request());

    brightnessService.getConfig(id)
      .then(
        config => {
          dispatch(success(config));
        },
        error => {
          if (axios.isCancel(error)) {
            dispatch(cancel(error.message));
          } else {
            dispatch(failure(error));
          }
        }
      );
  };

  function request() { return { type: brightnessConstants.GETCONFIG_REQUEST } }
  function success(config) { return { type: brightnessConstants.GETCONFIG_SUCCESS, config } }
  function failure(error) { return { type: brightnessConstants.GETCONFIG_FAILURE, error } }
  function cancel(cancelMsg) { return { type: brightnessConstants.GETCONFIG_CANCEL, cancelMsg } }
}

function putTimeConfig(id, timeConfig) {
  return dispatch => {
    dispatch(request());

    brightnessService.putTimeConfig(id, timeConfig)
      .then(
        time => {
          dispatch(success(time));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: brightnessConstants.PUT_TIME_CONFIG_REQUEST } }
  function success(time) { return { type: brightnessConstants.PUT_TIME_CONFIG_SUCCESS, time } }
  function failure(error) { return { type: brightnessConstants.PUT_TIME_CONFIG_FAILURE, error } }
}

function putPhotocellConfig(id, photocellConfig) {
  return dispatch => {
    dispatch(request());

    brightnessService.putPhotocellConfig(id, photocellConfig)
      .then(
        photocell => {
          dispatch(success(photocell));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: brightnessConstants.PUT_PHOTOCELL_CONFIG_REQUEST } }
  function success(photocell) { return { type: brightnessConstants.PUT_PHOTOCELL_CONFIG_SUCCESS, photocell } }
  function failure(error) { return { type: brightnessConstants.PUT_PHOTOCELL_CONFIG_FAILURE, error } }
}

function putHoldConfig(id, holdConfig) {
  return dispatch => {
    dispatch(request());

    brightnessService.putHoldConfig(id, holdConfig)
      .then(
        hold => {
          dispatch(success(hold));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: brightnessConstants.PUT_HOLD_CONFIG_REQUEST } }
  function success(hold) { return { type: brightnessConstants.PUT_HOLD_CONFIG_SUCCESS, hold } }
  function failure(error) { return { type: brightnessConstants.PUT_HOLD_CONFIG_FAILURE, error } }
}

function putSelectedProfile(id, modeConfig) {
  return dispatch => {
    dispatch(request());

    brightnessService.putSelectedProfile(id, modeConfig)
      .then(
        profile => {
          dispatch(success(profile));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: brightnessConstants.PUT_PROFILE_REQUEST } }
  function success(profile) { return { type: brightnessConstants.PUT_PROFILE_SUCCESS, profile } }
  function failure(error) { return { type: brightnessConstants.PUT_PROFILE_FAILURE, error } }
}
