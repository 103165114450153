import React, { Component } from 'react';
import 'react-dates/initialize';
import _ from 'lodash';
import 'moment-timezone';
import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities'
import { utils } from '../../../utils';
import ChartWrap from '../../ChartWrap';

const NOT_SET = Symbol("not set")

export default class ChartHotDisplays  extends Component  {
  constructor(props) {
    super(props);

    this.state = {
      params: this.props.params,
      expandStats: false,
      previewSnapshot: null,
      showChart: null,
    }
  }

  toggle = (name, val = NOT_SET) => {
    val = (val === NOT_SET) ? !this.state[name] : val
    this.setState({[name]: val});
  }

  getDatasetOptions = (key, count) => {
    let r = {
      _datasetKey: key, 
      _colorName: "primary",
      label: key,
      borderDash: [],
      borderWidth: 2,
      backgroundColor: 'transparent',
      yAxisID: 'y-axis-scalar',
      xAxisID: 'x-axis-numeric',
      pointHitRadius: count < 300 ? 5 : 2,
      radius: count < 20 ? 2 : 0
    }
    if (key === "lx") {
      r.label = "Photocell"
      r._colorName = "primary"
      r.yAxisID = 'y-axis-log'
    } else if (key === "rx") {
      r.label = "Receiver"
      r._colorName = "warning"
    } else if (key === "md") {
      r.label = "Module"
      r._colorName = "success"
    } else if (key === "wx") {
      r.label = "Weather"
      r._colorName = "info"
      r.backgroundColor = hexToRgba(getStyle('--info'), 10)
    } else if (key === "pc") {
      r.label = "CPU"
      r._colorName = "secondary"
    } else if (key === "rtr") {
      r.label = "Router"
      r._colorName = "secondary"
      r.borderWidth = 1
    } else if (key === "delta") {
      r.label = "Delta"
      r._colorName = "danger"
      r.borderWidth = 1
      // r.borderDash = [8, 5]
    }
    r.borderColor = getStyle('--' + r._colorName)
    return r
  }

  processChartData = (data) => {
    let dispData = _.get(data, "display.displays")
    let pcData = utils.getMonitorMap(_.get(data, "monitor.stats.PcHealth"))
    let rtrData = utils.getMonitorMap(_.get(data, "monitor.stats.Router"))
    let labelset = [];
    let ds = {rx:[], md:[], wx:[], lx:[], pc:[], delta:[], rtr:[]};

    // digest the data
    let sorted = (dispData || []).map(d => {
      let item = utils.addMonitorTemps(d, pcData, rtrData)
      let stat = utils.marshallTempStat(item)
      return {
        DisplayId: item.DisplayId,
        sortVal: parseFloat(_.get(stat,"StatsTemperature.Stats.TempReceiver.Value", 0)) || 0,
        ...stat,
      }
    })
    .sort((a,b) => {return b.sortVal - a.sortVal})
    for (let i = 0; i < sorted.length; i++) {
      const stat = sorted[i];
      labelset.push(stat.DisplayId)
      const rxTemp = _.get(stat,"StatsTemperature.Stats.TempReceiver.Value", null)
      const wxTemp = _.get(stat,"StatsTemperature.Stats.TempWeather.Value", null)
      const deltaTemp =  (rxTemp === null || wxTemp === null) ? null : Math.round((rxTemp - wxTemp)*100)/100

      ds.rx.push({x: stat.DisplayId, y:rxTemp})
      ds.wx.push({x: stat.DisplayId, y:wxTemp})
      ds.delta.push({x: stat.DisplayId, y:deltaTemp})
      ds.md.push({x: stat.DisplayId, y:_.get(stat,"StatsTemperature.Stats.TempModule.Value")})
      ds.lx.push({x: stat.DisplayId, y:_.get(stat,"StatsTemperature.Stats.Brightness.Value")})
      ds.pc.push({x: stat.DisplayId, y:_.get(stat,"StatsTemperature.Stats.TempPC.Value")})
      ds.rtr.push({x: stat.DisplayId, y:_.get(stat,"StatsTemperature.Stats.TempRtr.Value")})
    }

    // set datasets
    let datasets = []
    for (const key in ds) {
        const options = this.getDatasetOptions(key, ds[key] && ds[key].length)
        datasets.push({
          ...options,
          data: ds[key]
        })
    }
    
    return {
      datasets: datasets,
      labels: labelset
    }
  }

  render() {
    const yLabel = `Temperature (${utils.htmlDecode('&deg;C')})`
    const chartData = this.processChartData(_.get(this.props, "data"))

    return (
        <div className="chart-container vhd-100" style={{height: "60vh"}}>
            <ChartWrap data={chartData} xLabel={"Displays"} yLabel={yLabel}/>
        </div>
    )
  }
};
