import { authConstants } from '../_constants';
import { authService } from '../_services';
import { history } from '../_helpers';
import { utils } from '../../utils';

export const authActions = {
  login,
  logout,
  loginMfa,
  refreshToken,
  resetPassword,
  forgotPassword,
  verifyEmail,
  confirmEmail,
  cleanupEmail
};

const REFRESH_INTERVAL = 10 * 60 * 1000 // 10h
let refreshTokenTimer = 0

function login(username, password, fromUrl) {
  return dispatch => {
    dispatch(request({ username }));

    authService.login(username, password)
      .then(
        user => {
          dispatch(success(user));
          const firstTimeLogin = user.resetPwd
          if (firstTimeLogin) {
            history.push("/resetpwd");
          } else if (!user.mfaToken) {
            history.push(fromUrl || "/");
            setRefreshTokenInterval(dispatch, user);
          }
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(user) { return { type: authConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: authConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function logout(reload = true) {
  authService.logout();
  if (reload)
  {
    if (refreshTokenTimer) clearTimeout(refreshTokenTimer);
    history.push('/login');
    window.location.reload(true);
  }
  return { type: authConstants.LOGOUT };
}

function loginMfa(mfaToken, mfa_code, fromUrl) {
  return dispatch => {
    dispatch(request({ mfa_code }));

    authService.loginMfa(mfaToken, mfa_code)
      .then(
        user => {
          dispatch(success(user));
          history.push(fromUrl || "/");
          setRefreshTokenInterval(dispatch, user);
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(user) { return { type: authConstants.LOGIN_MFA_REQUEST, user } }
  function success(user) { return { type: authConstants.LOGIN_MFA_SUCCESS, user } }
  function failure(error) { return { type: authConstants.LOGIN_MFA_FAILURE, error } }
}

function refreshToken() {
  // WHEN page is reloaded from the browser (app start)
  // AND  user role is ['noc_viewer', 'noc_user']
  // THEN WHEN refresh token is expired
  //      THEN Logout
  //      ELSE refresh token
  //           set refresh token timer
  //           render the whole Layout
  // ELSE return REFRESH_TOKEN_DISABLE

  const user = utils.getUser()
  return dispatch => new Promise((resolve, reject) => {

    if (user && user.payload && ['noc_viewer', 'noc_user'].includes(user.payload.role)) {
      const refreshTokenExpired = (user.refreshPayload.exp < window.Date.now()/1000)
      if (refreshTokenExpired) {
        logout()
        resolve()
      } else {
        dispatch(request());
        authService.refreshToken(user)
          .then(
            user => {
              setRefreshTokenInterval(dispatch, user)
              dispatch(success(user))
              resolve()
            },
            error => {
              dispatch(failure(error));
              reject(error)
            }
          )
      }
    } else {
      dispatch(denied())
      resolve({ type: authConstants.REFRESH_TOKEN_DISABLE })
    }
  })

  function request() { return { type: authConstants.REFRESH_TOKEN_REQUEST } }
  function success(user) { return { type: authConstants.REFRESH_TOKEN_SUCCESS, user } }
  function failure(error) { return { type: authConstants.REFRESH_TOKEN_FAILURE, error } }
  function denied() { return { type: authConstants.REFRESH_TOKEN_DISABLE } }
}

function setRefreshTokenInterval(dispatch, user) {
  user = utils.getUser(user)
  if (user && user.payload && ['noc_viewer', 'noc_user'].includes(user.payload.role)) {
    if (refreshTokenTimer) clearTimeout(refreshTokenTimer);
    refreshTokenTimer = setTimeout(()=>dispatch(refreshToken()), REFRESH_INTERVAL);
  }
}

function resetPassword(oldPwd, newPwd, key){
  return dispatch => {
    dispatch(request());
    authService.resetPassword(oldPwd, newPwd, key)
      .then(
        user => {
          dispatch(success());
          if (!!key) logout()
          else history.goBack()
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: authConstants.LOGIN_PWD_RESET_REQUEST } }
  function success() { return { type: authConstants.LOGIN_PWD_RESET_SUCCESS} }
  function failure(error) { return { type: authConstants.LOGIN_PWD_RESET_FAILURE, error } }
}

function forgotPassword(email){
  return dispatch => {
    dispatch(request({ email }));

    authService.forgotPassword(email)
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(email) { return { type: authConstants.LOGIN_FORGOT_PWD_REQUEST, email } }
  function success(user) { return { type: authConstants.LOGIN_FORGOT_PWD_SUCCESS, user } }
  function failure(error) { return { type: authConstants.LOGIN_FORGOT_PWD_FAILURE, error } }
}

function verifyEmail(email){
  return dispatch => {
    dispatch(request(email));

    authService.verifyEmail(email)
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(email) { return { type: authConstants.LOGIN_VERIFY_EMAIL_REQUEST, email} }
  function success(user) { return { type: authConstants.LOGIN_VERIFY_EMAIL_SUCCESS, user } }
  function failure(error) { return { type: authConstants.LOGIN_VERIFY_EMAIL_FAILURE, error } }
}

function confirmEmail(key){
  return dispatch => {
    dispatch(request());

    authService.confirmEmail(key)
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(key) { return { type: authConstants.LOGIN_CONFIRM_EMAIL_REQUEST, key } }
  function success(user) { return { type: authConstants.LOGIN_CONFIRM_EMAIL_SUCCESS, user } }
  function failure(error) { return { type: authConstants.LOGIN_CONFIRM_EMAIL_FAILURE, error } }
}

function cleanupEmail(key){
  return dispatch => {
    dispatch(_cleanupEmail());
    function _cleanupEmail() { return { type: authConstants.LOGIN_VERIFY_EMAIL_CLEANUP } }
  }
}
