import { displayConstants } from '../_constants';
import { displayService } from '../_services';

export const displayActions = {
  getSummary,
  getSingleSummary,
  getDetail,
  getAlerts,
  selectDisplay,
  deselectDisplay,
  putDisplay,
  getComments,
  getCommentHistory,
  postComment,
  putComment,
  deleteComment,
  unDeleteComment
};

function getSummary() {
  return dispatch => {
    dispatch(request());

    displayService.getSummary()
      .then(
        displays => dispatch(success(displays)),
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: displayConstants.GETSUMMARY_REQUEST } }
  function success(displays) { return { type: displayConstants.GETSUMMARY_SUCCESS, displays } }
  function failure(error) { return { type: displayConstants.GETSUMMARY_FAILURE, error } }
}

function getDetail(id) {
  return dispatch => {
    dispatch(request());

    displayService.getDetail(id)
      .then(
        detail => dispatch(success(detail)),
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: displayConstants.GETDETAIL_REQUEST } }
  function success(detail) { return { type: displayConstants.GETDETAIL_SUCCESS, detail } }
  function failure(error) { return { type: displayConstants.GETDETAIL_FAILURE, error } }
}

function getSingleSummary(id) {
  return dispatch => {
    dispatch(request());

    displayService.getSingleSummary(id)
      .then(
        display => dispatch(success(display)),
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: displayConstants.GETSINGLE_SUMMARY_REQUEST } }
  function success(display) { return { type: displayConstants.GETSINGLE_SUMMARY_SUCCESS, display } }
  function failure(error) { return { type: displayConstants.GETSINGLE_SUMMARY_FAILURE, error } }
}



function getAlerts(id) {
  return dispatch => {
    dispatch(request());

    displayService.getAlerts(id)
      .then(
        alerts => dispatch(success(alerts)),
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: displayConstants.GETALERT_REQUEST } }
  function success(alerts) { return { type: displayConstants.GETALERT_SUCCESS, alerts } }
  function failure(error) { return { type: displayConstants.GETALERT_FAILURE, error } }
}


function selectDisplay(id) {
  return { type: displayConstants.SELECT_DISPLAY, id }
}


function deselectDisplay() {
  return { type: displayConstants.DESELECT_DISPLAY };
}


function putDisplay(id, displayObj, fieldName) {
  return dispatch => {
    dispatch(request());

    displayService.putDisplay(id, displayObj)
      .then(
        display => dispatch(success({DisplayId: id, ...display})),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: displayConstants.PUT_DISPLAY_REQUEST, fieldName } }
  function success(display) { return { type: displayConstants.PUT_DISPLAY_SUCCESS, fieldName, display } }
  function failure(error) { return { type: displayConstants.PUT_DISPLAY_FAILURE, fieldName, error } }
}

// Comments ---------------------------------
function getComments(id, viewDeleted) {
  return dispatch => {
    dispatch(request());

    displayService.getComments(id, viewDeleted)
      .then(
        comments => dispatch(success(comments)),
        error => {dispatch(failure(error))}
      );
  };

  function request() { return { type: displayConstants.GET_COMMENTS_REQUEST } }
  function success(comments) { return { type: displayConstants.GET_COMMENTS_SUCCESS, comments } }
  function failure(error) { return { type: displayConstants.GET_COMMENTS_FAILURE, error } }
}

function getCommentHistory(comment) {
  return dispatch => {
    dispatch(request());

    displayService.getCommentHistory(comment)
      .then(
        commentHistory => dispatch(success(comment, commentHistory)),
        error => {dispatch(failure(error))}
      );
  };

  function request() { return { type: displayConstants.GET_COMMENTS_HISTORY_REQUEST } }
  function success(comment, commentHistory) { return { type: displayConstants.GET_COMMENTS_HISTORY_SUCCESS, comment, commentHistory } }
  function failure(error) { return { type: displayConstants.GET_COMMENTS_HISTORY_FAILURE, error } }
}

function postComment(comment) {
  return dispatch => {
    dispatch(request());

    displayService.postComment(comment)
      .then(
        comment => dispatch(success(comment)),
        error => {dispatch(failure(error))}
      );
  };

  function request() { return { type: displayConstants.POST_COMMENT_REQUEST } }
  function success(comment) { return { type: displayConstants.POST_COMMENT_SUCCESS, comment } }
  function failure(error) { return { type: displayConstants.POST_COMMENT_FAILURE, error } }
}

function putComment(comment) {
  return dispatch => {
    dispatch(request());

    displayService.putComment(comment)
      .then(
        comment => dispatch(success(comment)),
        error => {dispatch(failure(error))}
      );
  };

  function request() { return { type: displayConstants.PUT_COMMENT_REQUEST } }
  function success(comment) { return { type: displayConstants.PUT_COMMENT_SUCCESS, comment } }
  function failure(error) { return { type: displayConstants.PUT_COMMENT_FAILURE, error } }
}

function deleteComment(comment, doUpdate = false) {
  return dispatch => {
    dispatch(request());

    displayService.deleteComment(comment)
      .then(
        item => dispatch(success(item, doUpdate)),
        error => {dispatch(failure(error))}
      );
  };

  function request() { return { type: displayConstants.DELETE_COMMENT_REQUEST } }
  function success(deletedComment, doUpdate) { return { type: displayConstants.DELETE_COMMENT_SUCCESS, deletedComment, doUpdate } }
  function failure(error) { return { type: displayConstants.DELETE_COMMENT_FAILURE, error } }
}

function unDeleteComment(comment) {
  return dispatch => {
    dispatch(request());

    displayService.unDeleteComment(comment)
      .then(
        comment => dispatch(success(comment)),
        error => {dispatch(failure(error))}
      );
  };

  function request() { return { type: displayConstants.UNDELETE_COMMENT_REQUEST } }
  function success(comment) { return { type: displayConstants.UNDELETE_COMMENT_SUCCESS, comment } }
  function failure(error) { return { type: displayConstants.UNDELETE_COMMENT_FAILURE, error } }
}