import { authHeader } from '../_helpers';
import { authService } from '.';
import { config } from '../_config';
const apiUrl = config.apiUrl
// const apiUrl = "http://localhost:3001";  // <---- ONLY for testing

export const commentsService = {
  getComments,
};

function getComments() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${apiUrl}/comments`, requestOptions).then(authService.handleResponse);
}

