import { reqOptions } from '../_helpers';
import axios from 'axios';
import { config } from '../_config';
const apiUrl = config.apiUrl
// const apiUrl = "http://localhost:3002";  // <---- ONLY for testing

export const brightnessService = {
  getHistory,
  getConfig,
  putTimeConfig,
  putPhotocellConfig,
  putHoldConfig,
  putSelectedProfile
};

const ACTIONS = {
  "HISTORY": Symbol("Brightness-history"),
  "CONFIG": Symbol("Brightness-config"),
}

function getHistory(id, date) {
  const requestOptions = reqOptions(ACTIONS.HISTORY)
  return axios.get(`${apiUrl}/brightness/${id}/history?date=${date.format("YYYY-MM-DD")}`, requestOptions).then(handleResponse);
}

function getConfig(id) {
  const requestOptions = reqOptions(ACTIONS.CONFIG)
  return axios.get(`${apiUrl}/brightness/${id}/config`, requestOptions).then(handleResponse);
}

function putTimeConfig(id, timeConfig) {
  const requestOptions = reqOptions()
  return axios.put(`${apiUrl}/brightness/${id}/config/time`, timeConfig, requestOptions).then(handleResponse);
}

function putPhotocellConfig(id, photocellConfig) {
  const requestOptions = reqOptions()
  return axios.put(`${apiUrl}/brightness/${id}/config/photocell`, photocellConfig, requestOptions).then(handleResponse);
}

function putHoldConfig(id, holdConfig) {
  const requestOptions = reqOptions()
    return axios.put(`${apiUrl}/brightness/${id}/config/hold`, holdConfig, requestOptions).then(handleResponse);
}

function putSelectedProfile(id, modeConfig) {
  const requestOptions = reqOptions()
    return axios.put(`${apiUrl}/brightness/${id}/mode`, {"ProfileSelected":modeConfig}, requestOptions).then(handleResponse);
}

// Helpers functions ---------------------
function handleResponse(response) {
  return response.data;
}