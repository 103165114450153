/* eslint-disable import/first */
import React, { Component } from 'react';
import 'react-dates/initialize';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/dark.css';
import moment from 'moment';
import {
  MdAddBox,
  MdDelete
} from 'react-icons/md';
import {
  FaSun,
  FaMoon
} from 'react-icons/fa'

class BrightnessTable extends Component {

  constructor(props) {
    super(props);

    this.PHOTOCELL_MAX = 100000;
    this.PHOTOCELL_MIN = 0;

    this.TIME_MAX = "23:59";
    this.TIME_MIN = "00:00";

    this.state = {
      table: props.table,
      mode: props.mode
    };
 
    this.addRow = this.addRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.toggleRise = this.toggleRise.bind(this);
    this.toggleSet = this.toggleSet.bind(this);
    this.changePhotocellInput = this.changePhotocellInput.bind(this);
    this.changeTimeInput = this.changeTimeInput.bind(this);
    this.changeOutput = this.changeOutput.bind(this);
    this.photocellInputFormatter = this.photocellInputFormatter.bind(this);
    this.timeInputFormatter = this.timeInputFormatter.bind(this);
    this.outputFormatter = this.outputFormatter.bind(this);
    this.autoFormatter = this.autoFormatter.bind(this);
    this.controlFormatter = this.controlFormatter.bind(this);

    this.photocellColumns = [
      {
        dataField: 'Input',
        text: 'Ambient (Lux)',
        formatter: this.photocellInputFormatter
      },
      {
        dataField: 'Output',
        text: 'Display (%)',
        formatter: this.outputFormatter
      },
      {
        dataField: 'Controls',
        isDummyField: true,
        text: '',
        formatter: this.controlFormatter
      }
    ];

    this.timeColumns = [
      {
        dataField: 'Input',
        text: 'Time (Local)',
        formatter: this.timeInputFormatter
      },
      {
        dataField: 'Output',
        text: 'Display (%)',
        formatter: this.outputFormatter
      },
      {
        dataField: 'RiseSet',
        isDummyField: true,
        text: 'Auto',
        formatter: this.autoFormatter
      },
      {
        dataField: 'Controls',
        isDummyField: true,
        text: '',
        formatter: this.controlFormatter
      }
    ];
  }

  updateTable(newTable) {

    newTable.sort(compareInput);

    this.setState({
      table: newTable
    });

    this.props.onDatasetChange(newTable);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.table !== nextProps.table) {
      this.setState({table: nextProps.table});
    }
    if (this.state.mode !== nextProps.mode) {
      this.setState({mode: nextProps.mode});
    }
  }

  formatTime(date) {
    let hour = date.getHours().toString();
    let minute= date.getMinutes().toString();
    
    while (hour.length < 2) {hour = "0" + hour;}
    while (minute.length < 2) {minute = "0" + minute;}
    
    return hour + ":" + minute;
  }

  toggleRise(row) {
    //first, we are going to get the current sunset selection. If the sunset is before the new rise, then we need to push it to after.
    let setNeedsPush = false;
    let newSetRow = null;
    let curSetRow = this.state.table.find(function(element) {
      return element.Sunset;
    });
    //if there is no current set row, or if the sunset is before or equal to the selected rise, push it after
    if (!curSetRow || (new moment("2000-01-01 " + curSetRow.Input)).isBefore(new moment("2000-01-01 " + row.Input)) || curSetRow.Input === row.Input) {
      setNeedsPush = true;
      //If it needs to be pushed, find the first element thats greater.
      newSetRow = this.state.table.find(function(element) {
        return new moment("2000-01-01 " + element.Input).isAfter(new moment("2000-01-01 " + row.Input));
      })
    }

    //if there is no row that can accept the set flag, cancel the operation.
    if (setNeedsPush && !newSetRow) {
      return;
    }

    //if this is already checked, we are just going to disable everything... if that's the case, !row.Sunrise will always be false.
    let newTable = this.state.table.map(x => {
      if (x.Input === row.Input) {
        return {Input: x.Input, Output: x.Output, Sunrise: !row.Sunrise, Sunset: false};
      } 
      else if (setNeedsPush && x.Input === newSetRow.Input) {
        return {Input: x.Input, Output: x.Output, Sunrise: false, Sunset: !row.Sunrise};
      } 
      else if (setNeedsPush && (curSetRow && x.Input === curSetRow.Input)) {
        return {Input: x.Input, Output: x.Output, Sunrise: false, Sunset: false};
      }
      else {
        return {Input: x.Input, Output: x.Output, Sunrise: false, Sunset: (x.Sunset && !row.Sunrise)};
      }
    });
    this.updateTable(newTable);
  }

  toggleSet(row) {
    //first, we are going to get the current sunrise selection. If the sunrise is before the new set, then we need to push it to before.
    let riseNeedsPush = false;
    let newRiseRow = null;
    let curRiseRow = this.state.table.find(function(element) {
      return element.Sunrise;
    });

    //if there is no current sunrise, or if it's on or after the selection
    if (!curRiseRow || (new moment("2000-01-01 " + curRiseRow.Input)).isAfter(new moment("2000-01-01 " + row.Input)) || curRiseRow.Input === row.Input) {
      riseNeedsPush = true;
      //If it needs to be pushed, find the first element thats greater than the selection.
      newRiseRow = this.state.table.reverse().find(function(element) {
        return new moment("2000-01-01 " + element.Input).isBefore(new moment("2000-01-01 " + row.Input));
      })
    }

    //if there is no row that can accept the rise flag, cancel the operation.
    if (riseNeedsPush && !newRiseRow) {
      return;
    }

    //if this is already checked, we are just going to disable everything... if that's the case, !row.Sunset will always be false.
    let newTable = this.state.table.map(x => {
      if (x.Input === row.Input) {
        return {Input: x.Input, Output: x.Output, Sunrise: false, Sunset: !row.Sunset};
      } 
      else if (riseNeedsPush && x.Input === newRiseRow.Input) {
        return {Input: x.Input, Output: x.Output, Sunrise: !row.Sunset, Sunset: false};
      } 
      else if (riseNeedsPush && (curRiseRow && x.Input === curRiseRow.Input)) {
        return {Input: x.Input, Output: x.Output, Sunrise: false, Sunset: false};
      }
      else {
        return {Input: x.Input, Output: x.Output, Sunrise: (x.Sunrise && !row.Sunset), Sunset: false};
      }
    });
    this.updateTable(newTable);
  }

  deleteRow(row) {
    let newTable = this.state.table.filter(x => x.Input !== row.Input);
    this.updateTable(newTable);
  }

  addRow(row) {
    let newTable = this.state.table.slice()

    let tmpInput = row.Input;
    let found = false;
    let underMax = true;

    var findInput = function (val) {
      return val.Input === tmpInput;
    }

    //increment
    if (this.state.mode === "time") {
      let tempDate = new moment("2000-01-01 " + tmpInput);
      do {
        underMax = tmpInput !== this.TIME_MAX;
        tempDate.add(1, 'minute'); 
        tmpInput = this.formatTime(tempDate.toDate());
        found = newTable.some(findInput);
      } while (found && underMax)
    }
    else {
      do {
        underMax = tmpInput < this.PHOTOCELL_MAX
        tmpInput = tmpInput + 1; 
        found = newTable.some(findInput);
      } while (found && underMax)
    }

    if (!found && underMax) {
      newTable.push({Input: tmpInput, Output: row.Output});
    }

    this.updateTable(newTable);
  }

  changeTimeInput(selectedDates, dateStr, instance) {
    if (selectedDates && selectedDates[0]) {
      let parsedTime = this.formatTime(selectedDates[0])
      let sourceTime = instance.config.defaultDate;

      //if this is a true change
      if (sourceTime !== parsedTime) {
        
        var findInput = function (val) {
          return val.Input === parsedTime;
        }

        let found = this.state.table.some(findInput);
        let underMax = true;

        //if this is a duplicate, increment until it's not
        while (found && underMax) {
          let unparsedTime = new moment('2000-01-01 ' + parsedTime);
          unparsedTime.add(1, 'minutes');
          parsedTime = this.formatTime(unparsedTime.toDate())
          underMax = parsedTime !== this.TIME_MAX;
          found = this.state.table.some(findInput);
        }

        if (!found && underMax) {
          let newTable = this.state.table.map(x => {
            if (x.Input === sourceTime) {
              return {Input: parsedTime, Output: x.Output};
            } else {
              return x;
            }
          })

          this.updateTable(newTable);
        }
      }
    }
  }

  changePhotocellInput(inputId, input) {

    let parsedInt = parseInt(input, 10);

    //if this is a true change
    if (inputId !== parsedInt) {
      
      parsedInt = (parsedInt > this.PHOTOCELL_MAX) ? this.PHOTOCELL_MAX : parsedInt;
      parsedInt = (parsedInt < this.PHOTOCELL_MIN) ? this.PHOTOCELL_MIN : parsedInt;

      var findInput = function (val) {
        return val.Input === parsedInt;
      }

      let found = this.state.table.some(findInput);
      let underMax = true;

      //if this is a duplicate, increment until it's not
      while (found && underMax) {
        parsedInt = parsedInt + 1; 
        underMax = parsedInt <= this.PHOTOCELL_MAX
        found = this.state.table.some(findInput);
      }

      if (!found && underMax) {
        let newTable = this.state.table.map(x => {
          if (x.Input === inputId) {
            return {Input: parsedInt, Output: x.Output};
          } else {
            return x;
          }
        })

        this.updateTable(newTable);
      }
    }
  }

  changeOutput(inputId, output) {
    let parsedInt = parseInt(output, 10);
    parsedInt = (parsedInt > 100) ? 100 : parsedInt;
    parsedInt = (parsedInt < 0) ? 0 : parsedInt;

    let newTable = this.state.table.map(x => {
      if (x.Input === inputId) {
        return {Input: x.Input, Output: parsedInt};
      } else {
        return x;
      }
    })

    this.updateTable(newTable);
  }

  photocellInputFormatter(cell, row) {
    return (
      <span>
        <input
          type="number"
          id={row.Input}
          className="w-100"
          min={this.PHOTOCELL_MIN}
          max={this.PHOTOCELL_MAX}
          step="1"
          defaultValue={row.Input}
          disabled={row.Input === this.PHOTOCELL_MIN}
          onBlur={(event) => this.changePhotocellInput(row.Input, event.target.value)}
          onKeyPress={ (e) => {
            if (!e) e = window.event;
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13){
              this.changePhotocellInput(row.Input, e.target.value);
            }}}>
        </input>
      </span>
    );
  }

  timeInputFormatter(cell, row) {
    return (
      <Flatpickr
        data-enable-time
        options={{
          enableTime: true,
          noCalendar: true,
          dateFormat: "h:i K",
          defaultHour: 0,
          defaultMinute: 0,
          defaultDate: row.Input
        }}
        onChange={this.changeTimeInput}
      />
    );
  }

  outputFormatter(cell, row) {
    return (
      <span>
        <input
          type="number"
          id={row.Output}
          min={0}
          max={100}
          className="w-100"
          step="1"
          defaultValue={row.Output}
          onBlur={(event) => this.changeOutput(row.Input, event.target.value)}
          onKeyPress={ (e) => {
            if (!e) e = window.event;
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13){
              this.changeOutput(row.Input, e.target.value);
            }}}>
        </input>
      </span>
    );
  }
  
  autoFormatter(cell, row) {
    return (
      <span>
        <span className="rise-btn p-1"><FaSun size={16} className={row.Sunrise ? "auto-icon-sel" : "auto-icon"} id={row.Input+"rise"} onClick={() => {this.toggleRise(row)}}/></span>
        <span className="set-btn p-1"><FaMoon size={16} className={row.Sunset ? "auto-icon-sel" : "auto-icon"} id={row.Input+"set"} onClick={() => {this.toggleSet(row)}}/></span>
      </span>
    );
  }

  controlFormatter(cell, row) {
    return (
      <span>
        {row.Input !== (this.state.mode === "time" ? this.TIME_MAX : this.PHOTOCELL_MAX) && <span className="btn p-1" title="Add new row"><MdAddBox size={24} color="green" id={row.Input+"add"} style={{cursor:'pointer'}} onClick={() => {this.addRow(row)}}/></span>}
        {row.Input !== (this.state.mode === "time" ? this.TIME_MIN : this.PHOTOCELL_MIN) && this.state.table.length > 1 && <span className="btn p-1" title="Delete the row"><MdDelete size={24} color="gray" id={row.Input+"del"} style={{cursor:'pointer'}} onClick={() => {this.deleteRow(row)}}/></span>}
      </span>
    );
  }

  render() {

    const defaultSorted = [{
      dataField: 'Input',
      order: 'asc'
    }];

    const { table } = this.state;
    return (
      <div>
      {table &&
        <BootstrapTable
            version='4'
            keyField='Input'
            data={table}
            columns={(this.state.mode === "time") ? this.timeColumns : this.photocellColumns}
            defaultSorted={ defaultSorted }
            hover
            classes='tbl-brightness'
        />}
      </div>
    );
  }
}

function compareInput(a,b) {
  if (a.Input < b.Input)
    return -1;
  if (a.Input > b.Input)
    return 1;
  return 0;
}

export default BrightnessTable;
