export const displayConstants = {
  GETSUMMARY_REQUEST: 'DISPLAY_GET_SUMMARY_REQUEST',
  GETSUMMARY_SUCCESS: 'DISPLAY_GET_SUMMARY_SUCCESS',
  GETSUMMARY_FAILURE: 'DISPLAY_GET_SUMMARY_FAILURE',

  GETSINGLE_SUMMARY_REQUEST: 'DISPLAY_GET_SINGLE_SUMMARY_REQUEST',
  GETSINGLE_SUMMARY_SUCCESS: 'DISPLAY_GET_SINGLE_SUMMARY_SUCCESS',
  GETSINGLE_SUMMARY_FAILURE: 'DISPLAY_GET_SINGLE_SUMMARY_FAILURE',

  GETDETAIL_REQUEST: 'DISPLAY_GET_DETAIL_REQUEST',
  GETDETAIL_SUCCESS: 'DISPLAY_GET_DETAIL_SUCCESS',
  GETDETAIL_FAILURE: 'DISPLAY_GET_DETAIL_FAILURE',

  SELECT_DISPLAY: 'SELECT_DISPLAY',
  DESELECT_DISPLAY: 'DESELECT_DISPLAY',

  GETALERT_REQUEST: 'DISPLAY_GET_ALERT_REQUEST',
  GETALERT_SUCCESS: 'DISPLAY_GET_ALERT_SUCCESS',
  GETALERT_FAILURE: 'DISPLAY_GET_ALERT_FAILURE',

  PUT_DISPLAY_REQUEST: 'DISPLAY_PUT_REQUEST',
  PUT_DISPLAY_SUCCESS: 'DISPLAY_PUT_SUCCESS',
  PUT_DISPLAY_FAILURE: 'DISPLAY_PUT_FAILURE',

  GET_COMMENTS_REQUEST: 'DISPLAY_GET_COMMENTS_REQUEST',
  GET_COMMENTS_SUCCESS: 'DISPLAY_GET_COMMENTS_SUCCESS',
  GET_COMMENTS_FAILURE: 'DISPLAY_GET_COMMENTS_FAILURE',

  GET_COMMENTS_HISTORY_REQUEST: 'DISPLAY_GET_COMMENTS_HISTORY_REQUEST',
  GET_COMMENTS_HISTORY_SUCCESS: 'DISPLAY_GET_COMMENTS_HISTORY_SUCCESS',
  GET_COMMENTS_HISTORY_FAILURE: 'DISPLAY_GET_COMMENTS_HISTORY_FAILURE',

  POST_COMMENT_REQUEST: 'DISPLAY_POST_COMMENT_REQUEST',
  POST_COMMENT_SUCCESS: 'DISPLAY_POST_COMMENT_SUCCESS',
  POST_COMMENT_FAILURE: 'DISPLAY_POST_COMMENT_FAILURE',

  PUT_COMMENT_REQUEST: 'DISPLAY_PUT_COMMENT_REQUEST',
  PUT_COMMENT_SUCCESS: 'DISPLAY_PUT_COMMENT_SUCCESS',
  PUT_COMMENT_FAILURE: 'DISPLAY_PUT_COMMENT_FAILURE',

  DELETE_COMMENT_REQUEST: 'DISPLAY_DELETE_COMMENT_REQUEST',
  DELETE_COMMENT_SUCCESS: 'DISPLAY_DELETE_COMMENT_SUCCESS',
  DELETE_COMMENT_FAILURE: 'DISPLAY_DELETE_COMMENT_FAILURE',

  UNDELETE_COMMENT_REQUEST: 'DISPLAY_UNDELETE_COMMENT_REQUEST',
  UNDELETE_COMMENT_SUCCESS: 'DISPLAY_UNDELETE_COMMENT_SUCCESS',
  UNDELETE_COMMENT_FAILURE: 'DISPLAY_UNDELETE_COMMENT_FAILURE',

};
