import React from 'react';
import { ListGroup } from 'reactstrap';
import _ from 'lodash';
import { AlertItem } from '../../views/Tables/Alert';
// ---------------------------------------------
// STRUCTURE
// ---------------------------------------------
// Display 1
//    - Alert 1
//    - Alert 2
// Display 2
//    - Alert 1
//    - Alert 2
// ---------------------------------------------
const DetailsShortAlert = (props) => {
  const brief = _.get(props, "brief", true)
  const noHighlight = _.get(props, "noHighlight", true)
  const adminRole = _.get(props, "adminRole")
  const displayGroup = _.get(props, "item")
  const dataKey = _.get(props, "dataKey")
  const listView = _.get(props, "listView", true)
  const [childProp,] = (dataKey || "").split(".")
  const alerts = displayGroup[childProp]

  return (
    (!displayGroup)
      ? <div>No data</div>
      : <React.Fragment>
        <div className="my-1">
          <ListGroup key={_.get(displayGroup, 'DisplayId')} className="list-group-accent">
            {alerts.map(alert => <AlertItem key={alert && alert.AlertId} alert={alert} listView={listView} brief={brief} adminRole={adminRole} noHighlight={noHighlight} />)}
          </ListGroup>
        </div>
      </React.Fragment>
  );
}

export default DetailsShortAlert;