import React, {Component} from 'react';
import { ListGroup, ListGroupItem, Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import {displayActions} from "../../../../api/_actions";
import { MdApps, MdFormatListBulleted } from 'react-icons/md';
import PropTypes from 'prop-types';
import _ from 'lodash'
import AlertItem, { mapSeverityToColor } from "../AlertItem"
import { utils } from "../../../../utils"

class AlertGroupItem extends Component {
  constructor(props) {
    super(props);

    this.toggleCollapse = this.toggleCollapse.bind(this)

    this.state = {
      collapse: true,
    }
  }
  toggleCollapse() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    const {title, listView} = this.props
    const items = this.props.items || []
    const severity = items && items[0] && items[0].Severity

    return (
    <ListGroup className="list-group-accent">
    <ListGroupItem key={title} className={`list-group-item-accent-${mapSeverityToColor(severity)} list-group-item-divider`}>
      <div className="mb-2" onClick={this.toggleCollapse}>
        {title} 
        <span style={{minWidth: 30 +'px'}} className="badge badge-primary mx-1 p-1 ml-2" title={title}>{(items && items.length) || 0}</span>
        <a className={"card-header-action btn btn-minimize float-right"} data-target="#collapseAlertItems" onClick={this.toggleCollapse}><i className={this.state.collapse ? "icon-arrow-down" : "icon-arrow-up"}></i></a>
      </div>
      <Collapse isOpen={!this.state.collapse} id="collapseAlertItems">
        <ListGroup className="list-group-accent">
          {items.map(item => <AlertItem key={item && item.AlertId} alert={item} listView={listView}/>)}
        </ListGroup>
      </Collapse>
    </ListGroupItem>
  </ListGroup>
  )
  }
}

class AlertList extends Component {
  constructor(props) {
    super(props);

    this.toggleCollapseHistory = this.toggleCollapseHistory.bind(this)
    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.toggleViewActive = this.toggleViewActive.bind(this)
    this.toggleViewHistory = this.toggleViewHistory.bind(this)

    this.state = {
      collapseHistory: true,
      collapse: true,
      viewListActive: false,
      viewListHistory: true,
    }
  }

  componentDidMount() {
    this.interval = setInterval(() =>
    {
      const { selectedDisplay } = this.props;
      if (selectedDisplay) {
        this.props.dispatch(displayActions.getAlerts(selectedDisplay));
        this.props.dispatch(displayActions.getSingleSummary(selectedDisplay));
      }
    }, 30000);

    const { selectedDisplay } = this.props;
    if (selectedDisplay) {
      this.props.dispatch(displayActions.getAlerts(selectedDisplay));
      this.props.dispatch(displayActions.getSingleSummary(selectedDisplay));
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedDisplay } = this.props;
    if (selectedDisplay !== prevProps.selectedDisplay) {
      this.props.dispatch(displayActions.getAlerts(selectedDisplay));
      this.props.dispatch(displayActions.getSingleSummary(selectedDisplay));
    }
  }
  
  toggleCollapse() {
    this.setState({ collapse: !this.state.collapse });
  }
  toggleCollapseHistory() {
    this.setState({ collapseHistory: !this.state.collapseHistory });
  }
  toggleViewActive() {
    this.setState({viewListActive: !this.state.viewListActive})
  }
  toggleViewHistory() {
    this.setState({viewListHistory: !this.state.viewListHistory})
  }

  render() {

    const { activeAlerts, inactiveAlertsGrouped, display, user } = this.props;
    const adminRole = (_.get(user,"payload.role") === "admin")
    const alertCount = Object.assign({
        Critical: 0,
        Error: 0,
        Failover: 0,
        Warning: 0
      }, display && display.Status && display.Status.AlertCount
    )

    const button = (viewList, toggleCallback) => {
      if (viewList) {
        return (<i className="ml-2" title="Expand alert details"><MdApps size={16} onClick={toggleCallback}></MdApps></i>)
      } else {
        return (<i className="ml-2" title="Collapse alert details"><MdFormatListBulleted size={16} onClick={toggleCallback}></MdFormatListBulleted></i>)
      }
    }
    return (
      <div className="animated container h-100 p-0">
        <div className="col h-100 p-0">
          <div className="row m-0">
            <Card className="w-100 mb-2">
              <CardHeader title="Active alerts">
                <i className="fa fa-align-justify"></i>Alerts
                <div className="float-right">
                  <span style={{minWidth: 30 +'px'}} className={`badge mx-1 p-1 badge-danger ${alertCount.Critical ? '' :  'op-20'} `} title="Critical alerts">{alertCount.Critical}</span>
                  <span style={{minWidth: 30 +'px'}} className={`badge mx-1 p-1 badge-danger ${alertCount.Error ? '' :  'op-20'} `} title="Error alerts">{alertCount.Error}</span>
                  <span style={{minWidth: 30 +'px'}} className={`badge mx-1 p-1 badge-warning ${alertCount.Failover ? '' :  'op-20'} `} title="Failover alerts">{alertCount.Failover}</span>
                  <span style={{minWidth: 30 +'px'}} className={`badge mx-1 p-1 badge-info ${alertCount.Warning ? '' :  'op-20'} `} title="Warning alerts">{alertCount.Warning}</span>
                  {button(this.state.viewListActive, this.toggleViewActive)}
                </div>
              </CardHeader>
              <CardBody className="scrollbar scrollbar-warning">
                <ListGroup className="list-group-accent h-100">
                  {activeAlerts && activeAlerts.length
                  ? activeAlerts.map(alertItem => {
                    return (<AlertItem key={alertItem && alertItem.AlertId} alert={alertItem} listView={this.state.viewListActive}/>)
                  })
                  : <i className="text-muted">There are no active alerts</i>}
                </ListGroup>
              </CardBody>
            </Card>
          </div>
          {adminRole &&
          <div className="row m-0">
            <Card className="w-100 mb-0 mh-100">
              <CardHeader title="Inactive Alerts">
                <i className="fa fa-align-justify"></i>Alerts history <small className="text-muted ml-1">for the last {utils.MAX_ALERT_HISTORY_IN_DAYS} days</small>
                <div className="float-right">
                  {button(this.state.viewListHistory, this.toggleViewHistory)}
                </div>
              </CardHeader>
              <CardBody className="scrollbar scrollbar-warning op-50">
              {inactiveAlertsGrouped && Object.keys(inactiveAlertsGrouped).length
              ? Object.keys(inactiveAlertsGrouped).map(grpKey => {
                return (<AlertGroupItem key={grpKey} title={grpKey} items={inactiveAlertsGrouped[grpKey]} listView={this.state.viewListHistory}/>)
              })
              : <i className="text-muted">There are no inactive alerts</i>}
              </CardBody>
            </Card>
          </div>
          }
        </div>
      </div>
    );
  }
}

AlertGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
}
AlertItem.propTypes = {
  alert: PropTypes.object.isRequired
}


function mapStateToProps(state) {
  const { selectedDisplay, activeAlerts, inactiveAlertsGrouped, display } = state.display;
  const { user } = state.authentication

  return {
    selectedDisplay, activeAlerts, inactiveAlertsGrouped, display, user
  };

}

const connectedAlertList = connect(mapStateToProps)(AlertList);
export default connectedAlertList;
