import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

export const TempSlider = withStyles({
  root: {
    "& > span.MuiSlider-mark": {
      opacity: "0.8",
      "background-color": "#fff",
    },
    "& > span.MuiSlider-markLabel": {
      color: "gray",
      "font-size": "80%",
      top: "1.1rem",
    },
    // "& > span:nth-child(5)": {       // first Marker
    //   "margin-left": "0.5rem",
    // },
    // "& > span:nth-last-child(3)": {  // last Marker
    //   "padding-right": "1rem",
    // },
  },
})(Slider)


export default TempSlider;