import { heatmapConstants } from '../_constants';
import { heatmapService } from '../_services';

export const heatmapActions = {
  getHeatmapConfig,
  getHeatmapStatus,
};

function getHeatmapConfig(id) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request());

    heatmapService.getHeatmapConfig(id)
      .then(
        config => {
          dispatch(success(config));
          resolve(config)
        },
        error => {
          dispatch(failure(error));
          reject(error)
        }
      );
  });

  function request() { return { type: heatmapConstants.GET_HEATMAP_CONFIG_REQUEST } }
  function success(config) { return { type: heatmapConstants.GET_HEATMAP_CONFIG_SUCCESS, config } }
  function failure(error) { return { type: heatmapConstants.GET_HEATMAP_CONFIG_FAILURE, error } }
}

function getHeatmapStatus(id) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request());

    heatmapService.getHeatmapStatus(id)
      .then(
        status => {
          dispatch(success(status));
          resolve(status)
        },
        error => {
          dispatch(failure(error));
          reject(error)
        }
      );
  });

  function request() { return { type: heatmapConstants.GET_HEATMAP_STATUS_REQUEST } }
  function success(status) { return { type: heatmapConstants.GET_HEATMAP_STATUS_SUCCESS, status } }
  function failure(error) { return { type: heatmapConstants.GET_HEATMAP_STATUS_FAILURE, error } }
}