import { profileConstants } from '../_constants';
import { profileService } from '../_services';
import { utils } from '../../utils';
import _ from 'lodash';

export const profileActions = {
  getProfile,
  putProfile,
  setupMFA,
  setupMFA_complete,
  disableMFA
};

function setupMFA() {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request());
    profileService.postMfaSetup()
      .then(
        mfa => {
          dispatch(success(mfa));
          resolve()
        },
        error => {
          dispatch(failure(error));
          reject(error)
        }
      );
  });

  function request() { return { type: profileConstants.PROFILE_MFA_POST_REQUEST} }
  function success(mfa) { return { type: profileConstants.PROFILE_MFA_POST_SUCCESS, mfa } }
  function failure(error) { return { type: profileConstants.PROFILE_MFA_POST_FAILURE, error } }  
}

function setupMFA_complete(mfa_code) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request({mfa_code}));
    profileService.patchMfaSetup({mfa_code})
      .then(
        mfa => {
          dispatch(success(mfa));
          resolve()
        },
        error => {
          dispatch(failure(error));
          reject(error)
        }
      );
  });

  function request(data) { return { type: profileConstants.PROFILE_MFA_PATCH_REQUEST, data} }
  function success(mfa) { return { type: profileConstants.PROFILE_MFA_PATCH_SUCCESS, mfa } }
  function failure(error) { return { type: profileConstants.PROFILE_MFA_PATCH_FAILURE, error } }  
}

function disableMFA() {
  const username = _.get(utils.getUser(), "payload.username")
  const data = {mfa_enabled: 0}
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request(data));
    profileService.putUserProfile(username, data)
      .then(
        payload => {
          dispatch(success(data, payload));
          resolve()
        },
        error => {
          dispatch(failure(error));
          reject(error)
        }
      );
  });

  function request(data) { return { type: profileConstants.PROFILE_MFA_DISABLE_REQUEST, data } }
  function success(data) { return { type: profileConstants.PROFILE_MFA_DISABLE_SUCCESS, data } }
  function failure(error) { return { type: profileConstants.PROFILE_MFA_DISABLE_FAILURE, error } }  
}

function getProfile() {
  const username = _.get(utils.getUser(), "payload.username")
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request(username));
    profileService.getUserProfile(username)
      .then(
        profile => {
          dispatch(success(profile));
          resolve()
        },
        error => {
          dispatch(failure(error));
          reject(error)
        }
      );
  });

  function request(username) { return { type: profileConstants.PROFILE_GET_REQUEST, username } }
  function success(profile) { return { type: profileConstants.PROFILE_GET_SUCCESS, profile } }
  function failure(error) { return { type: profileConstants.PROFILE_GET_FAILURE, error } }
}

function putProfile(username, profile) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request(username));
    profileService.putUserProfile(username, profile)
      .then(
        () => {
          dispatch(success(profile));
          resolve()
        },
        error => {
          dispatch(failure(error));
          reject(error)
        }
      );
  });

  function request(username) { return { type: profileConstants.PROFILE_PUT_REQUEST, username } }
  function success(profile) { return { type: profileConstants.PROFILE_PUT_SUCCESS, profile } }
  function failure(error) { return { type: profileConstants.PROFILE_PUT_FAILURE, error } }
}
