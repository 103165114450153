import { authHeader } from '../_helpers';
import { authService } from './';
import axios from 'axios';
import { config } from '../_config';
const apiUrl = config.apiUrl
// const apiUrl = "http://localhost:3001";  // <---- ONLY for testing

export const displayService = {
  getSummary,
  getDetail,
  getSingleSummary,
  getAlerts,
  putDisplay,
  getComments,
  getCommentHistory,
  postComment,
  putComment,
  deleteComment,
  unDeleteComment
};

function getSummary() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${apiUrl}/displays`, requestOptions).then(authService.handleResponse);
}

function getDetail(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/displays/${id}/detail`, requestOptions).then(authService.handleResponse);
}

function getSingleSummary(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/displays/${id}`, requestOptions).then(authService.handleResponse);
}


function getAlerts(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/displays/${id}/alerts?firing=null&closed=null`, requestOptions).then(authService.handleResponse);
}

// PUT Display functionality -----------------------
function putDisplay(id, displayObj) {
  const requestOptions = {
    headers: authHeader(),
  };
  
  return axios.put(`${apiUrl}/displays/${id}`, displayObj, requestOptions).then(handleResponseAxios);
}
// / PUT Display functionality -----------------------

// Comments ---------------
function getComments(id, viewAll) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axios.get(`${apiUrl}/displays/${id}/comments${viewAll ? "?deleted=null" :""}`, requestOptions).then(handleResponseAxios);
}
function getCommentHistory(comment) {
  const {DisplayId, MsgInfo} = comment || {}
  const requestOptions = {headers: authHeader()};
  return axios.get(`${apiUrl}/displays/${DisplayId}/comments/${MsgInfo}/revisions?deleted=null`, requestOptions).then(handleResponseAxios);
}
function postComment(comment) {
  const {DisplayId} = comment || {}
  const requestOptions = {
    headers: authHeader(),
  };
  return axios.post(`${apiUrl}/displays/${DisplayId}/comments`, comment, requestOptions).then(handleResponseAxios);
}
function putComment(comment) {
  const {DisplayId, MsgInfo} = comment || {}
  const requestOptions = {
    headers: authHeader(),
  };
  return axios.put(`${apiUrl}/displays/${DisplayId}/comments/${MsgInfo}`, comment, requestOptions).then(handleResponseAxios);
}
function deleteComment(comment) {
  const {DisplayId, MsgInfo, Revision} = comment || {}
  const requestOptions = {
    headers: authHeader(),
  };
  return axios.delete(`${apiUrl}/displays/${DisplayId}/comments/${MsgInfo}?rev=${Revision}`, requestOptions).then(handleResponseAxios);
}
function unDeleteComment(comment) {
  const {DisplayId, MsgInfo, Revision} = comment || {}
  const requestOptions = {
    headers: authHeader()
  };
  return axios.patch(`${apiUrl}/displays/${DisplayId}/comments/${MsgInfo}/restore?rev=${Revision}`, null, requestOptions).then(handleResponseAxios)
}
// /Comments  ---------------

// Helper functions -----------------------------
function handleResponseAxios(response) {
  return response.data;
}

// /Helper functions -----------------------------
