import React, { Component } from 'react';
import { ListGroupItem, Progress, Button } from 'reactstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import { MdGridOn, MdCropSquare, MdSettingsInputHdmi, MdDesktopWindows, MdWhatshot, MdBrightness6, MdSecurity, MdWarning  } from 'react-icons/md';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import 'moment-timezone';
import { utils } from '../../../../utils';

class AlertItem extends Component {
  constructor(props) {
    super(props);

    this.toggleView = this.toggleView.bind(this)

    this.state = {
      listView: this.props.listView,
      brief: this.props.brief,
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.listView !== this.props.listView) {
      this.setState({ listView: this.props.listView })
    }
    if (prevProps.brief !== this.props.brief) {
      this.setState({ brief: this.props.brief })
    }
  }
  toggleView() {
    this.setState({ listView: !this.state.listView })
  }

  render() {
    const { alert, adminRole, noHighlight } = this.props
    const { listView, brief } = this.state
    const ago = utils.timeAgo(moment.tz(alert.CreationTime, "YYYY-MM-DD HH:mm:ss", "UTC").tz(moment.tz.guess()))
    const highlight = (!noHighlight && ago && (ago.minutes >= 0 && ago.minutes <= 1)) ? "bg-warning" : "" // highlight if alert is created in the last 1 hour
    const expanded = (listView || highlight) ? "" : "bg-transparent"
    const padding = brief ? "px-2 py-0" : "p-2"
    const idButton = <CopyToClipboard text={alert.AlertId}>
                      <Button color="ghost-dark" className="py-0 px-1 mx-2 bg-darker-10 border-0 text-secondary op-60" title="Click to copy the text">{alert.AlertId}</Button>
                    </CopyToClipboard>


    return (
      <ListGroupItem action tag="a" key={alert.AlertId} className={`list-group-item-accent-${listView ? mapSeverityToColor(alert.Severity) : "light"} list-group-item-divider alarm-divider-5 ${highlight} ${expanded} ${padding}`} onClick={this.toggleView}>
        <div className="d-flex flex-row">
          <div className={`align-middle ${(brief && !listView) ? "mt-1" : ""}`}>
            {mapComponentToIcon(alert.AlertType, alert.Item.Type, listView, brief)}
          </div>
          <div className={`py-1 pl-2 w-100 ${highlight ? "text-secondary" : ""}`}>
            <div className={`text-truncate font-weight-bold`}>
              {alert.Description.Title}
              {brief ? <span className="text-right font-weight-normal ml-1 pull-right op-70">{ago.description}</span>
                     : <Progress color={mapSeverityToColor(alert.Severity)} value={ago.percent} title={`Created on ${ago.title}`} className="pull-right w-25" barClassName="pl-2">{ago.description}</Progress>
              }
            </div>
            {!listView && <div>{alert.Description.LongBody}</div>}
            {!listView && !!adminRole && idButton}
          </div>
        </div>
        {!listView && !brief &&
          <div>
            {idButton}
            <small className="text-muted float-right mt-1">{moment.tz(alert.CreationTime, "YYYY-MM-DD H:mm:ss", "UTC").tz(moment.tz.guess()).format("YYYY-MM-DD H:mm:ss z")}</small>
          </div>
        }
      </ListGroupItem>
    )
  }
}

export function mapSeverityToColor(severity) {
  switch (severity) {
    case ("Critical"):
      return "danger bg-lighter-5";
    case ("Error"):
      return "danger bg-lighter-5";
    case ("Failover"):
      return "warning bg-lighter-5";
    default:
      return "info bg-lighter-5";
  }
}

export default AlertItem;

// HELPER functions ------------------------
function mapComponentToIcon(alertType, component, listView, brief) {
  const size = listView ? (brief ? 16 : 32) : 48
  const style = null // brief ? {lineHeight: "16px"} : null
  if (alertType === "State") {
    switch (component) {
      case ("Module"):
        return <MdCropSquare size={size} color="gray"  style={style} />;
      case ("Receiver"):
        return <MdGridOn size={size} color="gray" style={style} />;
      case ("Port"):
        return <MdSettingsInputHdmi size={size} color="gray" style={style} />;
      case ("Display"):
        return <MdDesktopWindows size={size} color="gray" style={style} />;
      case ("absent"):
        return <MdWarning size={size} color="gray" style={style} />;
      default:
        return <FaQuestionCircle size={size} color="gray" style={style} />;
    }
  }
  else if (alertType === "AbsoluteTemperature" || alertType === "DifferentialTemperature") {
    return <MdWhatshot size={size} color="gray" />;
  }
  else if (alertType === "BrightnessSync" || alertType === 'BrightnessControl') {
    return <MdBrightness6 size={size} color="gray" />;
  }
  else if (alertType === "Security") {
    return <MdSecurity size={size} color="gray" />;
  }
}