import _ from 'lodash';
import { profileConstants } from '../_constants';

export function profile(state = {}, action) {
  switch (action.type) {
    case profileConstants.PROFILE_GET_REQUEST:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case profileConstants.PROFILE_GET_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        loading: false,
        profile: action.profile
      });
    case profileConstants.PROFILE_GET_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        loading: false
      });

    case profileConstants.PROFILE_PUT_REQUEST:
      return Object.assign({}, state, {
        error: null,
        saving: true
      });
    case profileConstants.PROFILE_PUT_SUCCESS:
      // when email changes update the email_verfied
      if (_.get(state, "profile.email") !== _.get(action, "profile.email"))
        _.set(action, "profile.email_verified", 0)
      return Object.assign({}, state, {
        error: null,
        saving: false,
        profile: Object.assign({}, state.profile, action.profile)
      });
    case profileConstants.PROFILE_PUT_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        saving: false
      });

    case profileConstants.PROFILE_MFA_POST_REQUEST:
      return Object.assign({}, state, {
        mfaError: null,
        mfaLoading: true
      });
    case profileConstants.PROFILE_MFA_POST_SUCCESS:
      return Object.assign({}, state, {
        mfaError: null,
        mfaLoading: false,
        mfa: action.mfa
      });
    case profileConstants.PROFILE_MFA_POST_FAILURE:
      return Object.assign({}, state, {
        mfaError: action.error,
        mfaLoading: false
      });

    case profileConstants.PROFILE_MFA_PATCH_REQUEST:
      return Object.assign({}, state, {
        mfaError: null,
        mfaLoading: true
      });
    case profileConstants.PROFILE_MFA_PATCH_SUCCESS:
      return Object.assign({}, state, {
        mfaError: null,
        mfaLoading: false,
        mfa: action.mfa,
        profile: Object.assign({}, state.profile, action.mfa) // update `mfa_enabled` prop of profile
      });
    case profileConstants.PROFILE_MFA_PATCH_FAILURE:
      return Object.assign({}, state, {
        mfaError: action.error,
        mfaLoading: false
      });

    case profileConstants.PROFILE_MFA_DISABLE_REQUEST:
      return Object.assign({}, state, {
        mfaError: null,
        mfaLoading: true
      });
    case profileConstants.PROFILE_MFA_DISABLE_SUCCESS:
      return Object.assign({}, state, {
        mfaError: null,
        mfaLoading: false,
        profile: Object.assign({}, state.profile, action.data)  // update `mfa_enabled` prop of profile 
      });
    case profileConstants.PROFILE_MFA_DISABLE_FAILURE:
      return Object.assign({}, state, {
        mfaError: action.error,
        mfaLoading: false
      });

    default:
      return state
  }
}
