import React, {Component} from 'react';
// import { Button, ListGroup, ListGroupItem, Card, CardHeader, CardBody, Collapse, Progress, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { maintenanceActions } from "../../../api/_actions";
// import { FaQuestionCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
// import moment from 'moment';
// import 'moment-timezone';
import _ from 'lodash'


class MaintenanceAlert extends Component {
  constructor(props) {
    super(props);

    this.suppressMessage = this.suppressMessage.bind(this);

    this.state = {
      showMsg: false,
      suppressMsg: false,
    }
  }
  suppressMessage() {
    this.setState({ suppressMsg: true });
  }

  componentDidMount() {
    // load msgs on mount
    this.props.dispatch(maintenanceActions.getMaintenanceMessages());

    // load msgs every 1h
    const intervalMs = 3600 * 1000 + Math.ceil(Math.random() * 120);  // every 1h +/- 1min
    this.interval = setInterval(() =>
    {
        this.props.dispatch(maintenanceActions.getMaintenanceMessages());
    }, intervalMs); 
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null
  }

  render() {
    
    const { maintenance } = this.props;
    const msg = _.get(maintenance, "messages[0].message", "");
    const showMessage = ((msg || "").length > 0 && !this.state.suppressMsg)

    return (
      <React.Fragment>
        {showMessage && (
          <div className="position-absolute d-flex justify-content-center w-100">
            <div className="alert alert-warning px-2 py-0 m-0" role="alert">
              {msg}
              <button type="button"
                            className="btn btn-link text-danger p-0 ml-2 pb-1"
                            onClick={this.suppressMessage}
              >
                <MdClose size={20}/>
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { maintenance } = state.maintenance;
  const { user } = state.authentication
  return { maintenance, user };
}

const connectedMaintenanceAlert = connect(mapStateToProps)(MaintenanceAlert);
export default connectedMaintenanceAlert;
