export const monitorConstants = {
    GET_STATS_REQUEST: 'MONITOR_GET_STATS_REQUEST',
    GET_STATS_SUCCESS: 'MONITOR_GET_STATS_SUCCESS',
    GET_STATS_FAILURE: 'MONITOR_GET_STATS_FAILURE',
  
    GET_CM_REQUEST: 'MONITOR_GET_CM_REQUEST',
    GET_CM_SUCCESS: 'MONITOR_GET_CM_SUCCESS',
    GET_CM_FAILURE: 'MONITOR_GET_CM_FAILURE',
  
    GET_PC_REQUEST: 'MONITOR_GET_PC_REQUEST',
    GET_PC_SUCCESS: 'MONITOR_GET_PC_SUCCESS',
    GET_PC_FAILURE: 'MONITOR_GET_PC_FAILURE',
    
    GET_ROUTER_REQUEST: 'MONITOR_GET_ROUTER_REQUEST',
    GET_ROUTER_SUCCESS: 'MONITOR_GET_ROUTER_SUCCESS',
    GET_ROUTER_FAILURE: 'MONITOR_GET_ROUTER_FAILURE',

    GET_ISP_REQUEST: 'MONITOR_GET_ISP_REQUEST',
    GET_ISP_SUCCESS: 'MONITOR_GET_ISP_SUCCESS',
    GET_ISP_FAILURE: 'MONITOR_GET_ISP_FAILURE',
  
  };
  