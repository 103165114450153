import _ from 'lodash';
import { alertConstants } from '../_constants';

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message
      };
    case alertConstants.ERROR:
      return {
        type: 'alert-danger',
        message: action.message
      };
    case alertConstants.CLEAR:
      return {
        alertsGrouped: state.alertsGrouped
      };


    case alertConstants.GET_ALERTS_ALL_REQUEST:
      return {
        alertsGrouped: action.alerts,
        loading: true,
        error: null,
      }
    case alertConstants.GET_ALERTS_ALL_SUCCESS:
      return {
        alertsGrouped: groupAlerts(action.alerts),
        loading: false,
        error: null,
      }
    case alertConstants.GET_ALERTS_ALL_FAILURE:
      return {
        alertsGrouped: null,
        loading: false,
        error: action.error,
      }

    case alertConstants.GET_ALERTS_STATS_REQUEST:
      return {
        alertStats: action.alerts,
        loading: true,
        error: null,
      }
    case alertConstants.GET_ALERTS_STATS_SUCCESS:
      return {
        alertStats: groupAlerts(action.alerts),
        loading: false,
        error: null,
      }
    case alertConstants.GET_ALERTS_STATS_FAILURE:
      return {
        alertStats: null,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

const groupAlerts = array => {
  return array.reduce((accumulator, obj) => {
    const displayId = _.get(obj, "DisplayId");
    const grp = accumulator.get(displayId)
    if (grp)
      grp.push(obj)
    else
      accumulator.set(displayId, [obj])

    return accumulator;
  }, new Map())
}