export const brightnessConstants = {
  GETHISTORY_REQUEST: 'BRIGHTNESS_GET_HISTORY_REQUEST',
  GETHISTORY_SUCCESS: 'BRIGHTNESS_GET_HISTORY_SUCCESS',
  GETHISTORY_FAILURE: 'BRIGHTNESS_GET_HISTORY_FAILURE',
  GETHISTORY_CANCEL: 'BRIGHTNESS_GET_HISTORY_CANCEL',

  GETCONFIG_REQUEST: 'BRIGHTNESS_GET_CONFIG_REQUEST',
  GETCONFIG_SUCCESS: 'BRIGHTNESS_GET_CONFIG_SUCCESS',
  GETCONFIG_FAILURE: 'BRIGHTNESS_GET_CONFIG_FAILURE',
  GETCONFIG_CANCEL: 'BRIGHTNESS_GET_CONFIG_CANCEL',

  PUT_TIME_CONFIG_REQUEST: 'BRIGHTNESS_PUT_TIME_CONFIG_REQUEST',
  PUT_TIME_CONFIG_SUCCESS: 'BRIGHTNESS_PUT_TIME_CONFIG_SUCCESS',
  PUT_TIME_CONFIG_FAILURE: 'BRIGHTNESS_PUT_TIME_CONFIG_FAILURE',

  PUT_PHOTOCELL_CONFIG_REQUEST: 'BRIGHTNESS_PUT_PHOTOCELL_CONFIG_REQUEST',
  PUT_PHOTOCELL_CONFIG_SUCCESS: 'BRIGHTNESS_PUT_PHOTOCELL_CONFIG_SUCCESS',
  PUT_PHOTOCELL_CONFIG_FAILURE: 'BRIGHTNESS_PUT_PHOTOCELL_CONFIG_FAILURE',
  
  PUT_HOLD_CONFIG_REQUEST: 'BRIGHTNESS_PUT_HOLD_CONFIG_REQUEST',
  PUT_HOLD_CONFIG_SUCCESS: 'BRIGHTNESS_PUT_HOLD_CONFIG_SUCCESS',
  PUT_HOLD_CONFIG_FAILURE: 'BRIGHTNESS_PUT_HOLD_CONFIG_FAILURE',

  PUT_PROFILE_REQUEST: 'BRIGHTNESS_PUT_PROFILE_REQUEST',
  PUT_PROFILE_SUCCESS: 'BRIGHTNESS_PUT_PROFILE_SUCCESS',
  PUT_PROFILE_FAILURE: 'BRIGHTNESS_PUT_PROFILE_FAILURE',
};
