const ResourceAction = {
    all: Symbol("All"),
    create: Symbol("Create"),
    view: Symbol("View"),
    delete: Symbol("Delete"),
    update: Symbol("Update"),
    viewExt: Symbol("ViewExt"),
    highlight: Symbol("Highlight"),
    viewFavorite: Symbol("ViewFavorite"),
}
const Resources = {
    all: Symbol("All"),
    noc: Symbol("noc"),
    displayStatus: Symbol("Display Status"),
    displayAlerts: Symbol("Display Alerts"),
    displayDetails: Symbol("Display details"),
    displaySnapshots: Symbol("Display Snapshots"),
    displayBrightness: Symbol("Display Brightness"),
    displayLocation: Symbol("Display Location"),
    displayComments: Symbol("Display Comments"),
    dashboard: Symbol("Display Comments"),
    events: Symbol("Display Events"),
    profile: Symbol("User Profile"),
    tools: Symbol("Tools"),
}
function getResourceSymbol(resourceName) {
    switch (resourceName) {
        case "/": 
        case "Home": 
        case "Status": 
        case "Display Status": 
            return Resources.displayStatus
        case "Cameras": 
            return Resources.noc
        case "Dashboard": 
            return Resources.dashboard
        case "Events":
            return Resources.events
        case "Profile":
            return Resources.profile
        case "Tools":
            return Resources.tools
        default: 
            return null
    }
}

// Usage: 
//      user.permissions = new Permissions(user.payload)
//      if (user.permissions.canViewExt(Resources.displayStatus)) { /* do something */}
class Permissions {
    constructor(user) {
        const userRole = user && user.role
        this.canCreate = (resource) => check(userRole, resource, ResourceAction.create);
        this.canView = (resource) => check(userRole, resource, ResourceAction.view);
        this.canUpdate = (resource) => check(userRole, resource, ResourceAction.write);
        this.canDelete = (resource) => check(userRole, resource, ResourceAction.delete);
        this.canViewExt = (resource) => check(userRole, resource, ResourceAction.viewExt);
        this.canHighlight = (resource) => check(userRole, resource, ResourceAction.highlight);
        this.canViewFavorite = (resource) => check(userRole, resource, ResourceAction.viewFavorite);
    }
}

const ac = new AccessControl();
function AccessControl(options){
    this.rules = options || {}
    this.deny_rules = options || {}
    
    this.grant = function(role, resource, actions) {
        this.rules[role] = this.rules[role] || {}
        this.rules[role][resource] = this.rules[role][resource] || {}
        this.rules[role][resource] = actions
        return this.rules
    }
    this.deny = function(role, resource, actions) {
        this.deny_rules[role] = this.deny_rules[role] || {}
        this.deny_rules[role][resource] = this.deny_rules[role][resource] || {}
        this.deny_rules[role][resource] = actions
        return this.deny_rules
    }
}

SetupAccess()

function SetupAccess(){
    setAccess(
        ['admin','superadmin'], 
        Resources.all, 
        ResourceAction.all
    )
    setAccess(
        ['noc_viewer', 'noc_user', 'superuser'], 
        Resources.all, 
        [ResourceAction.view, ResourceAction.viewExt, ResourceAction.highlight, ResourceAction.viewFavorite]
    )
    setAccess(
        ['user'],
        [Resources.displayStatus, Resources.displaySnapshots, Resources.displayLocation, Resources.noc], 
        ResourceAction.view
    )

    // deny access 
    setAccess(
        ['noc_user', 'superuser'],
        Resources.displayComments,
        ResourceAction.all,
        false                   // deny
    )
    setAccess(
        ['noc_user', 'superuser'],
        [Resources.noc, Resources.displayDetails],
        [ResourceAction.viewFavorite, ResourceAction.highlight, ResourceAction.create, ResourceAction.write],
        false                   // deny
    )
    setAccess(
        ['noc_user', 'superuser', 'noc_viewer', 'user'],
        [Resources.events, Resources.tools],
        ResourceAction.all,
        false                   // deny
    )
}

function setAccess(roles, resources, actions, grantAccess = true) {
    roles = Array.isArray(roles) ? roles : [roles]
    resources = Array.isArray(resources) ? resources : [resources]
    actions = Array.isArray(actions) ? actions : [actions]

    roles.forEach(role => {
        resources.forEach(resource => {
            grantAccess ? ac.grant(role, resource, actions)
                        : ac.deny(role, resource, actions)
        });
    });
}

// admin, dashboard, all
function check(role, resource, action, data) {
    // check parameters
    if (!role || !resource) return false;

    // if deny access exist Grant:Resources.All and Grant:actions.all are denied
    const denyRule = ac.deny_rules["all"] || ac.deny_rules[role]
    resource = (typeof resource === "symbol") ? resource : getResourceSymbol(resource)
    action = (typeof action === "symbol") ? action : ResourceAction[action]
    if (resource === Resources.all && denyRule) return false;
    if (action === ResourceAction.all  && ((ac.deny_rules["all"] || [])[resource] || (ac.deny_rules[role] || [])[resource])) {
        return false;
    }

    // check deny rules
    if (ac.deny_rules["all"] && checkAccess(ac.deny_rules["all"], resource, action, data)) return false
    if (checkAccess(ac.deny_rules[role], resource, action, data)) return false

    // check grant rules
    if (ac.rules["all"] && checkAccess(ac.rules["all"], resource, action, data)) return true
    return checkAccess(ac.rules[role], resource, action, data)
}

function checkAccess(rules, resource, action, data) {
    const rolePermissions = rules
    if (!rolePermissions || !resource) {
        return false
    }

    resource = (typeof resource === "symbol") ? resource : getResourceSymbol(resource)
    const resourcePermissions = rolePermissions[resource] || rolePermissions[Resources.all];
    if (!resourcePermissions) {
        return false 
    } 

    // check rules
    if (Array.isArray(resourcePermissions)) {
        return resourcePermissions.includes(action) || resourcePermissions.includes(ResourceAction.all)
    } else {
        const actionPermission = resourcePermissions[action]
        if (!actionPermission) {
            return false
        } else if (typeof actionPermission === "function") {
            actionPermission(data)
        } else {
            return actionPermission
        }
    }
}

export {
    Permissions,
    Resources
}
