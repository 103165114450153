import React, { useState } from 'react';
import 'react-dates/initialize';
import _ from 'lodash';
import axios from 'axios';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Popover, 
  PopoverBody,
} from 'reactstrap';
import {
  MdInfoOutline, 
  MdExtension,
  MdSettingsInputComponent,
  MdComputer,
  MdRouter,
  MdWbSunny,
} from 'react-icons/md';
import { WiDayCloudy } from "react-icons/wi";
import 'moment-timezone';
import moment from 'moment';
import { TempSlider } from './TempSlider';
import { utils } from '../../utils';

const env = process.env.NODE_ENV || 'development';
const config = require(__dirname + '/../../api/_config/api.json')[env];
const WEATHER_KEY = process.env.WEATHER_KEY || config.weather_key;

export const ValueRange = ({id, val, range, step = null, suffix = ""}) => {
  const {min, max} = range || {min: -20, max: 40}  
  const marks = [
    {value: min, label: min + suffix}
  ]
  if (max!==min) marks.push({value: max, label: utils.formatedNumber(max,1) + suffix})
  if (val!==max && val!==min) marks.push({value: val})
  
  const valueText = (value) => value + suffix;
  const handleChange = (event, newValue) => false;
  const offset = (max-min) / 100 * 15

  return (
    <TempSlider
      key={`slider-${id}`}
      defaultValue={20}
      value={val}
      min={min-offset}
      max={max+offset}
      step={null}  // prevent changes
      marks={marks}
      getAriaValueText={valueText}
      aria-labelledby="discrete-slider-restrict"
      valueLabelDisplay="auto"
      valueLabelFormat={val => utils.Round(val,1) + suffix}
      onChange={handleChange}
      className="d-blockd mt-minus-2 mb-1"
    />
  );
}

export const WeatherInfo = ({display}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [displayId] = useState(_.get(display, "DisplayId", null))
  const refData = React.useRef(null);
  const refCancelToken = React.useRef(null);
  const data = refData.current
  const wx_id = "wx-popover-" + displayId

  const toggle = () => {
    const prom = (!popoverOpen && !refData.current)
                  ? getWeatherData(display)
                    .then(data => refData.current=data)
                  : Promise.resolve();
    prom.then(() => setPopoverOpen(!popoverOpen))      
  };
  React.useEffect(() => {refData.current = null}, [displayId]);
  const getWeatherData = async (display) => {
    if (!display || !display.DisplayId) {return Promise.resolve}
    const displayId = display.DisplayId
    const lat = _.get(display, "Address.Latitude")
    const lon = _.get(display, "Address.Longitude")

    // cancel previous request
    if (refCancelToken.current) {
      refCancelToken.current.cancel(`Cancel weather info operation for display ${displayId}`)
    }
    refCancelToken.current = axios.CancelToken.source()
    const requestOptions = {cancelToken: refCancelToken.current.token}

    // get weather data
    return await axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${WEATHER_KEY}&mode=json`, requestOptions)
      .then(
        response => {
          if (!response || !response.data) {return {}}

          const data = response.data
          const iconCode = _.get(data, "weather[0].icon")
          const iconUrl = `http://openweathermap.org/img/w/${iconCode}.png`;
          const MIN_TEMP = -273.15
          const temp = Math.round((data.main.temp + MIN_TEMP)*100)/100
          
          return {
            temp,
            iconUrl,
            displayId,
            id: data.id,
            cityName: data.name,
            humidity: data.main.humidity,
            wind: data.wind.speed,
            pressure: data.main.pressure,
          }
        },
        error => {
          Promise.resolve(axios.isCancel(error) ? null : error)
        });
  }

  return (
    <React.Fragment>
      <WiDayCloudy size={16} className="mr-1 op-50 pointer" id={wx_id} onClick={toggle} />
      {(!data) 
        ? ""
        : <Popover
            placement="top"
            isOpen={popoverOpen}
            target={wx_id}
            toggle={toggle}
            className="popover-dark"
          >
            <PopoverBody>
              <div>
                <h5 className="fg-light mb-0">{data.cityName}</h5>
                <div className="d-block">
                  <div className="d-block">
                    <div className="d-inline-block mb-0">
                      <img height="45" width="45" 
                        style={{"width": "45px", "height": "45px", "background": `url('${data.iconUrl}') repeat scroll 0% 0% transparent`}} alt="title" 
                        src="http://openweathermap.org/images/transparent.png"
                      />
                    </div>
                    <div className="d-inline-block mx-3">
                      <h6 className="font-weight-bold" title="Current Temperature">{data.temp}&deg;C</h6>
                    </div>
                  </div>
                  <span className="d-block">Clouds: 90%</span>
                  <small className="d-block text-white-50">Humidity: {data.humidity}%</small>
                  <small className="d-block text-white-50">Wind: {data.wind}m/s</small>
                  <small className="d-inline-block text-white-50 align-top">Pressure: {data.pressure}hpa</small>
                  <small className="d-inline-block float-right">
                    <a href={`http://openweathermap.org/city/${data.id}`} target="_blank">More..</a>
                  </small>
                </div>
              </div>
            </PopoverBody>
          </Popover>
      }
    </React.Fragment>
  )
}

export const TempShort = (props) => {
  const {item, display} = props
  const warnExtreme = "\nWARNING: Extreme temperature"
  const warnExtremeValue = "\nWARNING: Extreme value"

  return (
    (!item) 
      ? <div>No temperatures item</div>
      : <div className="container">
          <div className="row overflow-hidden">
            <div style={{minWidth: 45 +'px'}} className={"col-auto badge p-1 mr-2 text-left badge-" + (item.TempModule.Extreme ? "danger bg-darker-20 text-light" : "transparent")} title={`Module Average Temperature ${item.TempModule.Extreme ? warnExtreme : ""}`}>
              <MdExtension size={16} className="mr-1 op-50" />
              {utils.Round(item.TempModule.Value)} &deg;C
            </div>
            <div style={{minWidth: 45 +'px'}} className={"col-auto badge p-1 mr-2 text-left badge-" + (item.TempReceiver.Extreme ? "danger bg-darker-20 text-light" : "transparent")} title={`Receiver Average Temperature ${item.TempReceiver.Extreme ? warnExtreme : ""}`}>
              <MdSettingsInputComponent size={16} className="mr-1 op-50" />
              {utils.Round(item.TempReceiver.Value)} &deg;C
            </div>
            <div style={{minWidth: 45 +'px'}} className={"col-auto badge p-1 mr-2 text-left badge-" + (item.TempWeather.Extreme ? "danger bg-darker-20 text-light" : "transparent")} title={`Weather Temperature ${item.TempWeather.Extreme ? warnExtreme : ""}`}>
              <WeatherInfo display={display} />
              {utils.Round(item.TempWeather.Value)} &deg;C
            </div>
            <div style={{minWidth: 45 +'px'}} className={"col-auto badge p-1 mr-2 text-left badge-" + (item.TempDelta.Extreme ? "danger bg-darker-20 text-light" : "transparent")} title={`Temperature difference between the Receiver Temperature and the Weather temperature ${item.TempDelta.Extreme ? warnExtreme : ""}`}>
              <span className="mr-1 op-50 temp-delta text-light">&Delta;</span>
              {utils.Round(item.TempDelta.Value)} &deg;C
            </div>
            {!_.isNil(item.TempPC.Value) &&
              <div style={{minWidth: 45 +'px'}} className={"col-auto badge p-1 mr-2 text-left badge-" + (item.TempPC.Extreme ? "danger bg-darker-20 text-light" : "transparent") + (_.isNil(item.TempPC.Value) ? " op-20" : "")} title={`Computer Average Temperature ${item.TempPC.Extreme ? warnExtreme : ""}`}>
                <MdComputer size={16} className="mr-1 op-50" />
                {utils.Round(item.TempPC.Value)} &deg;C
              </div>
            }
            {!_.isNil(item.TempRtr.Value) &&
                <div style={{minWidth: 45 +'px'}} className={"col-auto badge p-1 mr-2 text-left badge-" + (item.TempRtr.Extreme ? "danger bg-darker-20 text-light" : "transparent") + (_.isNil(item.TempRtr.Value) ? " op-20" : "")} title={`Router Average Temperature ${item.TempRtr.Extreme ? warnExtreme : ""}`}>
                <MdRouter size={16} className="mr-1 op-50" />
                {utils.Round(item.TempRtr.Value)} &deg;C
              </div>
            }
            <div style={{minWidth: 45 +'px'}} className={"col badge p-1 mr-2 text-left badge-" + (item.Brightness.Extreme ? "danger bg-darker-20 text-light" : "transparent") + (_.isNil(item.Brightness.Value) ? " op-20" : "")} title={`Photocell value ${item.Brightness.Extreme ? warnExtremeValue : ""}`}>
              <MdWbSunny size={16} className="mr-1 op-50" />
              {utils.readableNumber(item.Brightness.Value) + " lx"}
            </div>
          </div>
        </div>
  )
}

export const DetailsTempShort = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = (val) => setDropdownOpen(!dropdownOpen);
  const data = _.get(props, "item.Stats")
  const updateDate = moment.tz(_.get(props, "item.UpdatedAt"), "YYYY-MM-DD hh:mm:ss", "UTC").tz(moment.tz.guess())
  return (
    (!data) 
      ? <div>No data</div>
      : <React.Fragment>
          <div className="my-1">
            <TempShort item={data}/>
          </div>
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="align-top ml-0 float-right">
            <DropdownToggle caret={false} size="sm" className="p-0 btn btn-transparent" title="Info">
              <MdInfoOutline size="16" />
            </DropdownToggle>
            <DropdownMenu right className="p-2" style={{width:"12vw", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.25)"}}>
              <DropdownItem header tag="div" color="light" className="mb-1 text-center">
                <strong>TEMPERATURES</strong>
              </DropdownItem>
              {dropdownOpen && (
                <div className="px-1 mx-0 mt-3">

                  <div className={`text-uppercase mt-2 d-flex ${data.TempModule.Extreme ? "text-danger": "text-muted"}`}>
                    <small><b>MODULE</b></small>
                    <small className="ml-auto"  title="Current Module Temperature">{utils.Round(data.TempModule.Value)}&deg;C</small>
                  </div>
                  <ValueRange id="TempModule" val={utils.Round(data.TempModule.Value)} range={utils.TEMP_RANGE_MOD} suffix="&deg;C" />

                  <div className={`text-uppercase mt-2 d-flex ${data.TempReceiver.Extreme ? "text-danger": "text-muted"}`}>
                    <small><b>RECEIVER</b></small>
                    <small className="ml-auto" title="Current Receiver Temperature">{utils.Round(data.TempReceiver.Value)}&deg;C</small>
                  </div>
                  <ValueRange id="TempRx" val={utils.Round(data.TempReceiver.Value)} range={utils.TEMP_RANGE_RX} suffix="&deg;C" />

                  <div className={`text-uppercase mt-2 d-flex ${data.TempDelta.Extreme ? "text-danger": "text-muted"}`} title="Temperature difference between the Receiver Temperature and the Weather temperature">
                    <small><b>DIFFERENTIAL</b></small>
                    <small className="ml-auto" title="Current Delta">{utils.Round(data.TempDelta.Value)}&deg;C</small>
                  </div>
                  <ValueRange id="TempDelta" val={utils.Round(data.TempDelta.Value)} range={utils.TEMP_RANGE_DELTA} suffix="&deg;C" />

                  <div className={`text-uppercase mt-2 d-flex ${data.TempWeather.Extreme ? "text-danger": "text-muted"}`}>
                    <small><b>WEATHER</b></small>
                    <small className="ml-auto" title="Current Weather Temperature">{utils.Round(data.TempWeather.Value)}&deg;C</small>
                  </div>
                  <ValueRange id="TempWeather" val={utils.Round(data.TempWeather.Value)} range={utils.TEMP_RANGE_WEATHER} suffix="&deg;C" />

                  <div className={`text-uppercase mt-2 d-flex ${data.TempPC.Extreme ? "text-danger": "text-muted"}`}>
                    <small><b>CPU</b></small>
                    <small className="ml-auto" title="Current CPU Temperature">{utils.Round(data.TempPC.Value)}&deg;C</small>
                  </div>
                  <ValueRange id="TempCPU" val={utils.Round(data.TempPC.Value)} range={utils.TEMP_RANGE_PC} suffix="&deg;C" />

                  <div className={`text-uppercase mt-2 d-flex ${data.TempRtr.Extreme ? "text-danger": "text-muted"}`}>
                    <small><b>Router</b></small>
                    <small className="ml-auto" title="Current Router Temperature">{utils.Round(data.TempRtr.Value)}&deg;C</small>
                  </div>
                  <ValueRange id="TempRtr" val={utils.Round(data.TempRtr.Value)} range={utils.TEMP_RANGE_RTR} suffix="&deg;C" />

                  <div className={`text-uppercase mt-2 d-flex ${data.TempRtr.Extreme ? "text-danger": "text-muted"}`}>
                    <small><b>Photocell</b></small>
                    <small className="ml-auto" title="Current Photocell Value">{utils.formatedNumber(data.Brightness.Value,1)} lx</small>
                  </div>
                  <ValueRange id="Brightness" val={utils.Round(data.Brightness.Value)} range={utils.BRIGHTNESS_RANGE} suffix=" lx" />
                </div>
              )}
              <div className="mt-1 text-muted op-50 pull-right">
                <small>data received {utils.timeAgo(updateDate).description}</small>
              </div>
            </DropdownMenu>
          </ButtonDropdown>
        </React.Fragment>
  );
}

export default DetailsTempShort;