import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';
import _ from 'lodash';
import { utils } from '../../utils';
import { authActions } from '../../api/_actions';
import { SpinnerDots } from '../../_components/SpinnerDots'

const ConfirmEmail = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.authentication) || {}
  const { callingConfirmEmail, calledConfirmEmail, errorConfirmEmail }  = auth
  const { key } = utils.parseQueryString(_.get(window, "location.search"))
  const isLoggedIn = !!_.get(auth, "user.payload.username")

  useEffect(() => {
    dispatch(authActions.confirmEmail(key))
  }, [ key ])

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6" >
            <CardGroup>
              <Card className="text-white bg-primary p-4">
                <CardBody>
                  <h1 className="text-nowrap">Verifying email {(!!calledConfirmEmail && !errorConfirmEmail) ? "succeeded" : "failed"}!</h1>
                  <Row className={"mt-4"}>
                    <Col>
                      {!!calledConfirmEmail && !errorConfirmEmail &&
                        <p>Thank you for verifying you email!</p>
                      }
                      {!!errorConfirmEmail && 
                        <p className="text-error">Error: {"" + errorConfirmEmail}</p>
                      }
                      <div className="float-left"><SpinnerDots loading={callingConfirmEmail} /></div>
                    </Col>
                  </Row>
                  <Row className={"mt-2"}>
                    <Col className="text-right">
                      <Link className="btn btn-secondary text-light" to="/" >{isLoggedIn ? "Close" : "Login"}</Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default ConfirmEmail
