import React from 'react';
import Loadable from 'react-loadable'

import Layout from './containers/Layout';

function Loading() {
  return <div>Loading...</div>;
}

// const DashboardDemo = Loadable({
//   loader: () => import('./views/DashboardDemo'),
//   loading: Loading,
// });

const Profile = Loadable({
  loader: () => import('./views/Profile'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const DisplayTable = Loadable({
  loader: () => import('./views/Tables/DisplayTable'),
  loading: Loading,
});

const NocWall = Loadable({
  loader: () => import('./views/NocWall'),
  loading: Loading,
});

const Tables = Loadable({
  loader: () => import('./views/Tables/Tables'),
  loading: Loading,
});

const Events = Loadable({
  loader: () => import('./views/Events'),
  loading: Loading,
});

const Tools = Loadable({
  loader: () => import('./views/Tools'),
  loading: Loading,
});

const ToolsRGB = Loadable({
  loader: () => import('./views/Tools/RGB'),
  loading: Loading,
});


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', name: 'Home', component: Layout, exact: true },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  // { path: '/dashboard_demo', name: 'Dashboard', component: DashboardDemo },
  { path: '/status/:DisplayId?', name: 'Display Status', component: DisplayTable },
  { path: '/users', name: 'Users', component: Tables },
  { path: '/cameras', name: 'Cameras', component: NocWall },
  { path: '/events', name: 'Events', component: Events},
  { path: '/tools/rgb', name: 'Tools - RGB', component: ToolsRGB},
  { path: '/tools', name: 'Tools', component: Tools},
  { path: '/profile', name: 'Profile', component: Profile},
];

export default routes;
