const ENABLED_DISABLED = {
  0: "Disabled",
  1: "Enabled",
}
const CELLULAR_STATE_DETAILS = {
  100: "Disconnected",
  101: "Connecting",
  102: "Data connection failed. Waiting to retry",
  103: "Not Connected - Radio Connect off",
  104: "Not Connected - Waiting for Activity",
  105: "No SIM or Unexpected SIM Status",
  106: "SIM Locked, but bad SIM PIN",
  107: "SIM PIN Incorrect, 5 Attempts Left",
  108: "SIM PIN Incorrect, 4 Attempts Left",
  109: "SIM PIN Incorrect, 3 Attempts Left",
  110: "SIM PIN Incorrect, 2 Attempts Left",
  111: "SIM PIN Incorrect, 1 Attempt Left",
  112: "SIM PIN Incorrect, 0 Attempts Left",
  113: "SIM Blocked, Bad unlock code",
  114: "SIM Locked: 10 PUK Attempts Left",
  115: "SIM Locked: 9 PUK Attempts Left",
  116: "SIM Locked: 8 PUK Attempts Left",
  117: "SIM Locked: 7 PUK Attempts Left",
  118: "SIM Locked: 6 PUK Attempts Left",
  119: "SIM Locked: 5 PUK Attempts Left",
  120: "SIM Locked: 4 PUK Attempts Left",
  121: "SIM Locked: 3 PUK Attempts Left",
  122: "SIM Locked: 2 PUK Attempts Left",
  123: "SIM Locked: 1 PUK Attempt Left",
  124: "SIM Blocked, unblock code incorrect",
  125: "IP Acquired",
}
const CELLULAR_STATE = {
  0: "Not Connected",
  1: "Connected",
  2: "No Service",
}
const CELLULAR_END_TO_END_CONN = {
  0: "Not Verified",
  1: "Pending",
  2: "Established",
  3: "Not Established",
}
const CARRIER_AVAILABILITY = {
  0: "Not Available",
  1: "Available",
}
const SIM_SLOT = {
  0: "Slot 1",
  1: "Slot 2",
}
const SIM_PRESENT = {
  0: "SIM Absent",
  1: "SIM Present",
}
const DATA_UNITS = {
  1: "MB",
  2: "KB",
}
const DATA_UNITS_MONTHLY = {
  0: "MB",
  1: "GB",
}
const DMZ_HOST = {
  2: "Automatic",
  1: "Manual",
  0: "Disable",
}
const PORT_FILTERING = {
  0: "Disabled",
  1: "Blocked Ports",
  2: "Allowed Ports",    
}
const fmtNetIntf = val => {
  switch (val && val.toString()) {
      case "0": return "None";
      case "1": return "Cellular";
      case "2": return "Wi-Fi";
      case "3": return "Ethernet</option";
      default: return "n/a";
  }
}
const fmtUptime = min => {
  if (!min) return "n/a"
  
  var years = Math.floor(min / (365*24*60));                  min = (years)   ? min % (365*24*60): min;
  var months = Math.floor((min - years) / (31*24*60));        min = (months)  ? min % (31*24*60): min;
  var days = Math.floor((min - years - months) / (24*60));    min = (days)    ? min % (24*60): min;
  var hours = Math.floor((min - years - months - days) / 60); min = (hours)   ? min % (60): min;
  var minutes = min

  var result = ""
  if (years) result += ", " + years + " years"
  if (months) result += ", " + months + " months"
  if (days) result += ", " + days + " days"
  if (hours) result += ", " + hours + " hours"
  if (minutes) result += ", " + minutes + " minutes"

  return result ? result.substring(2) : result;
}
const fmtTemp = temp => temp ? temp + " °C" : "n/a";
const fmtMB1 = (bytes, data) => bytes ? bytes + " " + (DATA_UNITS[data[ROUTER_FIELDS.CELL_1_PLAN_UNITS.id]] || "") : "n/a";
const fmtMB2 = (bytes, data) => bytes ? bytes + " " + (DATA_UNITS[data[ROUTER_FIELDS.CELL_2_PLAN_UNITS.id]] || "") : "n/a";
const fmtToMB = bytes => (typeof bytes === 'undefined') ? "n/a" :  Number((Math.round(bytes*100 / (1024 * 1024))/100).toString()).toLocaleString("en-US") + " MB";
  
export const ROUTER_MONITOR_STATUS = {
  "BOOTUP":                   -4, // 
  "PAUSED":                   -3, // 
  "DISABLED":                 -2, // 
  "FIRST_TIME_BOOTUP":        -1, // When the app is started for the first time
  "OK":                        0, // No issue (OK)
  "HIGH_DATA_USAGE":           4, // when TotalData/UptimeDays > 1 GB
  "HIGH_DATA_USAGE_DAILY":     5, // when PrevDayUsage > DailyLimit
  "HIGH_DATA_USAGE_MONTHLY":   6, // when CurrentMonthlyUsage/DayofMonth * 30 > MonthlyLimit
  "RECENTLY_REBOOTED":         -7, // Device was rebooted recently (remove the "-" prefix to show this status on the monitoring page)
  "DATA_MONITOR_DISABLED":     8, // Data usage monitoring is not enabled
  "ERROR_PARSING_DATA":        9, // Error while parsing results
}
  
export const ROUTER_FIELDS = {
  // network info
  "ACTIVE_WAN_IPV4_ADDRESS":        {id: 301,   lbl: "Active WAN IPv4 Address",               format: null,                        tip: "WAN IP of the billboard"},
  "DEVICE_IP":                      {id: 1084,  lbl: "Device IP",                             format: null,                        tip: "default: 192.168.127.200"},
  "NETWORK_STATE":                  {id: 259,   lbl: "Network State",                         format: null,                        tip: "default: Network Ready"},
  "IPV4_NETWORK_INTERFACE":         {id: 10438, lbl: "IPv4 Network Interface",                format: fmtNetIntf,                  tip: "default: Cellular"},
  "DEVICE_UPTIME":                  {id: 272,   lbl: "Device Uptime",                         format: fmtUptime,                   tip: "How long the device was online"},

  // device info
  "DEVICE_MODEL":                   {id: 7,     lbl: "Device Model",                          format: null,                        tip: "default: RV50X"},
  "RADIO_MODULE_TYPE":              {id: 9,     lbl: "Radio Module Type",                     format: null,                        tip: "default: MC7455"},
  "SERIAL_NUMBER":                  {id: 25,    lbl: "Serial Number",                         format: null,                        tip: null},
  "ALEOS_SOFTWARE_VERSION":         {id: 11850, lbl: "ALEOS Software Version",                format: null,                        tip: "default: 4.12.0"},
  "POWER_INPUT_VOLTAGE":            {id: 266,   lbl: "Power Input Voltage (volts)",           format: null,                        tip: null},
  "BOARD_TEMPERATURE":              {id: 267,   lbl: "Board Temperature",                     format: fmtTemp,                     tip: null},
  "RADIO_INTERNAL_TEMPERATURE":     {id: 641,   lbl: "Radio Module Internal Temperature",     format: fmtTemp,                     tip: null},
  "NUMBER_OF_SYSTEM_RESETS":        {id: 302,   lbl: "Number of System Resets",               format: null,                        tip: "Number of system resets/reboots (since factory default configuration reset)"},

  // cellinfo
  "CELL_ID":                        {id: 773,   lbl: "Cell ID",                               format: null,                        tip: null},
  "CELLULAR_IP_ADDRESS":            {id: 303,   lbl: "Cellular IP Address",                   format: null,                        tip: null},
  "IMSI":                           {id: 785,   lbl: "IMSI",                                  format: null,                        tip: "International Mobile Subscriber Identity"},
  "ESN/EID/IMEI":                   {id: 10,    lbl: "ESN/EID/IMEI",                          format: null,                        tip: null},
  "CELLULAR_STATE":                 {id: 10415, lbl: "Cellular State",                        format: CELLULAR_STATE,              tip: "default: 1 - Connected"},
  "CELLULAR_STATE_DETAILS":         {id: 10426, lbl: "Cellular State Details",                format: CELLULAR_STATE_DETAILS,      tip: "default: 125 - IP Acquired"},
  "CELLULAR_END-TO-END_CONNECTION": {id: 25025, lbl: "Cellular End-to-End Connection",        format: CELLULAR_END_TO_END_CONN,    tip: "default: 2 - Established"},
  "CARRIER_AVAILABILITY":           {id: 25022, lbl: "Carrier Availability",                  format: CARRIER_AVAILABILITY,        tip: "default: 1 - Available"},

  "SIM_ID":                         {id: 771,   lbl: "SIM ID (ICCID)",                        format: null,                        tip: "ICCID (Integrated Circuit Card ID) an 18- or 19-digit identification number used to identify the SIM itself"},
  "NUMBER_OF_SIMS_PRESENT":         {id: 10701, lbl: "Number of SIMs present",                format: null,                        tip: null},
  "PRIMARY_SIM":                    {id: 10702, lbl: "Primary SIM",                           format: SIM_SLOT,                    tip: "default: 0 - Slot 1"},
  "ACTIVE_SIM":                     {id: 10704, lbl: "Active SIM",                            format: SIM_SLOT,                    tip: "default: 0 - Slot 1"},
  "SIM_NETWORK_OPERATOR":           {id: 770,   lbl: "SIM Network Operator",                  format: null,                        tip: "example: Verizon Wireless"},
  "SERVING_NETWORK_OPERATOR":       {id: 780,   lbl: "Serving Network Operator",              format: null,                        tip: "example: Verizon Wireless"},
  "APN_STATUS":                     {id: 11202, lbl: "APN Status",                            format: null,                        tip: "example: ds.mediares.iot"},
  "SLOT_1":                         {id: 10748, lbl: "Slot 1",                                format: SIM_PRESENT,                 tip: "default: 1 - SIM Present"},
  "SLOT_2":                         {id: 10802, lbl: "Slot 2",                                format: SIM_PRESENT,                 tip: "default: 1 - SIM Present"},

  "RADIO_TECHNOLOGY":               {id: 264,   lbl: "Radio Technology",                      format: null,                        tip: "default: LTE-Advanced"},
  "NETWORK_SERVICE_TYPE":           {id: 306,   lbl: "Network Service Type",                  format: null,                        tip: "default: 4G"},
  "ACTIVE_FREQUENCY_BAND":          {id: 671,   lbl: "Active Frequency Band",                 format: null,                        tip: "default: LTE BAND 7"},
  "CELLULAR_NETWORK_WATCHDOG":      {id: 10230, lbl: "Cellular Network Watchdog",             format: ENABLED_DISABLED,            tip: "default: 1 - enabled"},

  "SIGNAL_STRENGTH":                {id: 261,   lbl: "Signal Strength(RSSI)",                 format: null,                        tip: null},
  "LTE_SIGNAL_STRENGTH":            {id: 10210, lbl: "LTE Signal Strength(RSRP)",             format: null,                        tip: null},
  "LTE_SIGNAL_QUALITY":             {id: 10209, lbl: "LTE Signal Quality(RSRQ)",              format: null,                        tip: null},
  "LTE_SIGNAL_INTERFERENCE":        {id: 10211, lbl: "LTE Signal Interference(SINR)",         format: null,                        tip: null},

  // security
  "DMZ_HOST":                       {id: 5113,  lbl: "DMZ Host",                              format: DMZ_HOST,                    tip: "default: 0 - disabled"},
  "PORT_FORWARDING":                {id: 5112,  lbl: "Port Forwarding",                       format: ENABLED_DISABLED,            tip: "default: 0 - disabled"},
  "PORT_FILTERING_INBOUND":         {id: 3505,  lbl: "Port Filtering Inbound",                format: PORT_FILTERING,              tip: "default: 0 - disabled"},
  "PORT_FILTERING_OUTBOUND":        {id: 3506,  lbl: "Port Filtering Outbound",               format: PORT_FILTERING,              tip: "default: 0 - disabled"},
  "OUTBOUND_FIREWALL_MODE":         {id: 3507,  lbl: "Outbound Firewall Mode",                format: ENABLED_DISABLED,            tip: "default: 0 - disabled"},
  "TRUSTED_HOSTS_FRIENDS":          {id: 1062,  lbl: "Trusted Hosts (Friends)",               format: ENABLED_DISABLED,            tip: "default: 0 - disabled", expected:1},
  "MAC_FILTERING":                  {id: 3509,  lbl: "MAC Filtering",                         format: ENABLED_DISABLED,            tip: "default: 0 - disabled"},
  "IP_REJECT_COUNT":                {id: 386,   lbl: "IP Reject Count",                       format: null,                        tip: "default: 0"},

  // data usage
  "BYTES_SENT":                     {id: 283,   lbl: "Bytes Sent",                            format: fmtToMB,                     tip: "# of bytes sent on cellular uplink since system startup"},
  "BYTES_RECEIVED":                 {id: 284,   lbl: "Bytes Received",                        format: fmtToMB,                     tip: "# of bytes received from the network since system startup"},
  // "PERSISTED_BYTES_SENT":           {id: 10283, lbl: "Persisted Bytes Sent",                  format: fmtToMB,                       tip: "# of bytes sent on cellular uplink since last reset to default"},
  // "PERSISTED_BYTES_RECEIVED":       {id: 10284, lbl: "Persisted Bytes Received",              format: fmtToMB,                       tip: "# of bytes received from the network since last reset to default"},

  // cell slot 1
  "CELL_1_USER_ENTERED_APN":        {id: 10710, lbl: "User Entered APN",                      format: null,                        tip: "example: ds.mediares.iot"},
  "CELL_1_USAGE_MONITORING":        {id: 10747, lbl: "Usage Monitoring",                      format: ENABLED_DISABLED,            tip: "default: 1 - Enabled (for Pattison: 0 - Disabled)"},
  "CELL_1_PLAN_UNITS":              {id: 10746, lbl: "Plan Units",                            format: DATA_UNITS,                  tip: "Units used when displaying usage [1 - MB, 2 - KB] \ndefault: 1 - MB"},
  "CELL_1_DAILY_LIMIT":             {id: 10724, lbl: "Daily Limit",                           format: fmtMB1,                      tip: null},
  "CELL_1_CURRENT_DAILY_USAGE":     {id: 10717, lbl: "Current Daily Usage",                   format: fmtMB1,                      tip: null},
  "CELL_1_PREVIOUS_DAILY_USAGE":    {id: 10741, lbl: "Previous Daily Usage",                  format: fmtMB1,                      tip: null},
  "CELL_1_MONTHLY_LIMIT_UNITS":     {id: 10726, lbl: "Monthly Limit Units",                   format: DATA_UNITS_MONTHLY,          tip: null},
  "CELL_1_MONTHLY_LIMIT":           {id: 10725, lbl: "Monthly Limit",                         format: null,                        tip: "Monthly Limit (in units as specified above)"},
  "CELL_1_CURRENT_MONTHLY_USAGE":   {id: 10718, lbl: "Current Monthly Usage",                 format: fmtMB1,                      tip: null},
  "CELL_1_START OF BILLING_CYCLE":  {id: 10722, lbl: "Start Of Billing Cycle (Day Of Month)", format: null,                        tip: "default: 1"},

  // cell slot 2
  "CELL_2_USER_ENTERED_APN":        {id: 10750, lbl: "User Entered APN",                      format: null,                        tip: "example: mediares.crs.bell.ca.ioe"},
  "CELL_2_USAGE_MONITORING":        {id: 10801, lbl: "Usage Monitoring",                      format: ENABLED_DISABLED,            tip: "default: 1 - Enabled (for Pattison: 0 - Disabled)"},
  "CELL_2_PLAN_UNITS":              {id: 10786, lbl: "Plan Units",                            format: DATA_UNITS,                  tip: "Units used when displaying usage [1 - MB, 2 - KB] \ndefault: 1 - MB"},
  "CELL_2_DAILY_LIMIT":             {id: 10764, lbl: "Daily Limit",                           format: fmtMB2,                      tip: null},
  "CELL_2_CURRENT_DAILY_USAGE":     {id: 10757, lbl: "Current Daily Usage",                   format: fmtMB2,                      tip: null},
  "CELL_2_PREVIOUS_DAILY_USAGE":    {id: 10781, lbl: "Previous Daily Usage",                  format: fmtMB2,                      tip: null},
  "CELL_2_MONTHLY_LIMIT_UNITS":     {id: 10766, lbl: "Monthly Limit Units",                   format: DATA_UNITS_MONTHLY,          tip: null},
  "CELL_2_MONTHLY_LIMIT":           {id: 10765, lbl: "Monthly Limit",                         format: null,                        tip: "Monthly Limit (in units as specified above)"},
  "CELL_2_CURRENT_MONTHLY_USAGE":   {id: 10758, lbl: "Current Monthly Usage",                 format: fmtMB2,                      tip: null},
  "CELL_2_START OF BILLING_CYCLE":  {id: 10762, lbl: "Start Of Billing Cycle (Day Of Month)", format: null,                        tip: "default: 1"},

}
