import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardTitle, CardBody, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col,
  ButtonGroup,
  ButtonToolbar,
  Progress,
} from 'reactstrap';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash'
import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities'
import { temperatureActions } from "../../../api/_actions";
import { utils } from '../../../utils'
import ChartWrap from '../../../_components/ChartWrap'
import { SpinnerDots } from '../../../_components/SpinnerDots'

const REFRESH_TIME = 3 * 60000      // 3 min

class TemperatureHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldUpdate: true, // should component update/render
      selectedDate: null, // moment.tz(timezone),
      sunrise: null,
      sunset: null,      
      focused: null,
      modal: false,
      timeZone: null,
      rangeSelected: 1, // 1 Day
    };
  }
  componentDidMount() {
    clearInterval(this.interval);
    this.interval = setInterval(this.getData, REFRESH_TIME)
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { history, display } = this.props;
    const { selectedDate, rangeSelected } = this.state
    if (_.get(this.props, "display.DisplayId") !== _.get(prevProps, "display.DisplayId")) {
      
      // when display selection changes, then change TimeZone and get new data
      this.setState({
          shouldUpdate: false,
          chartData: null,
          timeZone:  _.get(display, "Timezone"),
          sunrise: _.get(display, "Weather.Sunrise"),
          sunset: _.get(display, "Weather.Sunset"),
          selectedDate: selectedDate || moment.tz(this.state.timeZone),
      }, () => {
        this.getData()
        this.setState({shouldUpdate: true})
      })

    } else if (selectedDate !== prevState.selectedDate) {
      this.getData()
    } else if (rangeSelected !== prevState.rangeSelected) {
      this.getData()
    }

    if (history !== prevProps.history) {
      this.onDatasetChange();
    }
  }
  shouldComponentUpdate(nextProps, nextState) { 
    return nextState.shouldUpdate
  }

  getDateRange = (selectedDate, rangeSelected, timeZone) => {
    if (!selectedDate || !rangeSelected || !timeZone) return {}
    const max = moment(selectedDate).endOf("day")
    const min = moment(max).subtract(rangeSelected, "days").add(1,"seconds")
    return {min, max}
  }

  getData = () => {
    const { selectedDisplay } = this.props;
    const { selectedDate, rangeSelected, timeZone } = this.state;
    
    if (selectedDisplay && selectedDate && timeZone) {
      const {min, max} = this.getDateRange(selectedDate, rangeSelected, timeZone)
      this.props.dispatch(temperatureActions.getHistoryRange(selectedDisplay, min.utc(), max.utc()))
      .catch(reason => console.warn("# getTempHistory error: ", reason));
    }
  }
  onDateChange = (selectedDate) => {
    const {timeZone} = this.state
    selectedDate = (timeZone) ? moment.tz(selectedDate, utils.FORMAT_DATE_TIME, timeZone) : null
    this.setState({ selectedDate });
  }
  onRadioBtnClick(rangeSelected) {
    this.setState({rangeSelected});
  }
  toggle = () => {
    this.setState({modal: !this.state.modal})
  }
  onDatasetChange = () => {
    const { history, display } = this.props;
    const { selectedDate, rangeSelected, timeZone } = this.state;

    const timezoneString = (display && history) ? moment.tz(timeZone).format('(z)'): ""
    const {min, max} = this.getDateRange(selectedDate, rangeSelected, timeZone)
  
    const labelset = utils.getLabelSets(min, max, null, null, timeZone)
    const datasets = this.parseDataset(history, labelset, timeZone)
    const annotations = this.parseSunrise(history, timeZone, rangeSelected < 3)

    const chartData = Object.assign({}, this.state.chartData, {
      datasets: datasets,
      labels: labelset,
      annotations: annotations
    })


    this.setState({
      xLabel: `Time of Day ${timezoneString}`,
      chartData,
    });
  }

  parseDataset = (history, labels, timeZone) => {
    if (!labels || !labels.length || !history || !timeZone) { return null}

    // sync data points with labels
    let ds = utils.extrapolateToLabelPoints(history, labels, timeZone, ["_sun"])
    
    // for each Dataset set Data and Style
    let datasets = []
    for (const key in ds) {
        const options = this.getDatasetOptions(key)
        datasets.push({
          ...options,
          data: ds[key].data
        })
    }
    
    return datasets
  }
  parseSunrise = (history, timeZone, showLabels) => {
    if (!history || !history._sun || !timeZone) return null
    let r = []
    for (const key in history._sun) {
      r.push({
        id: "sr-" + key,
        value: utils.unixUtcToTzStr(key*1000, timeZone), 
        content: "Sunrise",
        enabled: showLabels,
      })
      r.push({
        id: "ss-" + key,
        value: utils.unixUtcToTzStr(history._sun[key]*1000, timeZone), 
        content: "Sunset",
        enabled: showLabels,
      })
    }
    return r
  }
  getDatasetOptions = (key) => {
    let r = {
      _datasetKey: key, 
      _colorName: "primary",
      spanGaps: false,  // when false it's suppose to show gaps between the lines works for "linear" type but not for "logarithmic" type of axis
      label: key,
      borderDash: [],
      borderWidth: 2,
      backgroundColor: 'transparent',
      xAxisID: 'x-axis-time',
      yAxisID: 'y-axis-scalar',
    }
    if (key === "lx") {
      r.label = "Photocell"
      r._colorName = "primary"
      r.yAxisID = 'y-axis-log'
    } else if (key === "rx") {
      r.label = "Receiver"
      r._colorName = "warning"
    } else if (key === "md") {
      r.label = "Module"
      r._colorName = "success"
    } else if (key === "brt") {
      r.label = "Brightness"
      r._colorName = "secondary"
      r.unit = " %"
    } else if (key === "wx") {
      r.label = "Weather"
      r._colorName = "info"
      r.backgroundColor = hexToRgba(getStyle('--info'), 10)
    } else if (key === "pc") {
      r.label = "CPU"
      r._colorName = "danger"
      r.borderWidth = 1
    } else if (key === "rtr") {
      r.label = "Router"
      r._colorName = "danger"
      r.borderWidth = 1
      // r.borderDash = [8, 5]
    // } else if (key === "delta") {
    //   r.label = "Delta"
    //   r._colorName = "light"
    }
    r.borderColor = getStyle('--' + r._colorName)
    return r
  }
  getFootNotes = () => {
    const { display, history } = this.props
    const { chartData, timeZone } = this.state
    if (!display || !history || !chartData) { return }
    
    const getStatN = (total, val, max) => {
      const percent = (max) ? Math.min(Math.round(val / max * 100), 100) : 0
      return {total, percent}
    }
    const getStatS = (obj, prefix, propOnline, propMax) => {
      const total = _.get(obj, `${prefix}.${propMax}`, 0) 
      const online = _.get(obj, `${prefix}.${propOnline}`)
      const percent = (total) ? Math.min(Math.round(online / total * 100), 100) : 0
      return {total, percent, online}
    }
    const min = moment.tz(timeZone).startOf("day")
    let max = moment.tz(timeZone).endOf("day")
    max = moment.tz(timeZone).isBefore(max) ? moment.tz(timeZone) : max // make sure max time is not in the future
    const maxPoints         = utils.getPointsRange(min, max, null, null, timeZone).points
    const maxPointsWeather  = utils.getPointsRange(min, max, utils.WEATHER_INTERVAL_IN_MIN * 60, null, timeZone).points
    const maxPointsRouter   = utils.getPointsRange(min, max, utils.ROUTER_INTERVAL_IN_MIN * 60, null, timeZone).points
    const maxPointsPC       = utils.getPointsRange(min, max, utils.PC_INTERVAL_IN_MIN * 60, null, timeZone).points

    const datasets = _.get(chartData, "datasets")
    let r = [];

    (datasets || []).forEach(ds => {
      const key = ds._datasetKey
      let val = null
      if      (key==="rx")  val = getStatS(display, "Status.ComponentStatus.Receiver", "Online", "Total")  // Rx total,  Rx online %
      else if (key==="md")  val = getStatS(display, "Status.ComponentStatus.Module", "Online", "Total")    // Md total,  Md online %
      else if (key==="wx")  val = getStatN(1, _.get(history, "wx.length"), maxPointsWeather)               // API Services, online % (wx count/labels hours count)
      else if (key==="pc")  val = getStatN(1, _.get(history, "pc.length"), maxPointsPC)                    // (pc count/labels half hour count)
      else if (key==="rtr") val = getStatN(1, _.get(history, "rtr.length"), maxPointsRouter)               // (rt count/labels half hour count)
      else if (key==="lx")  val = getStatN(_.get(display, "Status.Photocell.length", 0), _.get(history, "lx.length"), maxPoints)      // PhotoCells,   online % (lx count/labels count)

      if (val) {
        r.push({
          key: key, 
          label: ds.label + (!["wx", "rtr"].includes(key) ? "s" : ""), 
          color: ds._colorName, 
          ...val
        })
      }
    });
      
    return r
  }


  render() {
    const { display, loading } = this.props;
    const { selectedDate, rangeSelected, timeZone, xLabel } = this.state;
    const {min, max} = this.getDateRange(selectedDate, rangeSelected, timeZone)
    const footNotes = this.getFootNotes()
    const yLabel = `Temperature (${utils.htmlDecode('&deg;C')})  /  Brightness (%)`
    const expandButton =  loading ? <SpinnerDots loading={loading} className="ml-3" />
                                  : <Button color="outline-secondary" 
                                      className="btn-sm ml-3"
                                      title={(this.state.modal) ? "Collapse" : "Expand"} 
                                      onClick={this.toggle}>
                                        <i className={this.state.modal ? "icon-size-actual" : "icon-size-fullscreen"}></i>
                                    </Button>
    const rangeButtons =  <ButtonToolbar className="ml-3" aria-label="Toolbar with button groups">
                            <ButtonGroup aria-label="First group">
                              <Button color="outline-secondary" className="btn-sm" onClick={() => this.onRadioBtnClick(1)} active={this.state.rangeSelected === 1}>Day</Button>
                              <Button color="outline-secondary" className="btn-sm" onClick={() => this.onRadioBtnClick(2)} active={this.state.rangeSelected === 2}>Two Days</Button>
                              <Button color="outline-secondary" className="btn-sm" onClick={() => this.onRadioBtnClick(7)} active={this.state.rangeSelected === 7}>Week</Button>
                            </ButtonGroup>
                          </ButtonToolbar>  
    const closeButton = <div className="text-nowrap d-inline-flex">
                          <SingleDatePicker
                            small={true}
                            date={this.state.selectedDate}
                            onDateChange={date => this.onDateChange(date)}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            id="your_unique_id"
                            isOutsideRange={(day) => {
                              return !day.isSameOrBefore(moment.tz(timeZone), 'day');
                            }}
                            focused={this.state.focused}
                            hideKeyboardShortcutsPanel={true}
                          />
                          {rangeButtons}
                          {expandButton}
                        </div>
    const footer =  <Row className="text-center w-100">
                      {(footNotes || []).map(footNote => {
                        let title = `Number of ${footNote.label} ${!footNote.online ? "" : "(" + footNote.total + "/" + footNote.online + ")"}`
                        if (footNote.key==="wx") title = "Number of Sources"
                        return <Col sm={12} md className="mb-sm-2 mb-0" key={footNote.key}>
                                <div className="text-muted">{footNote.label}</div>
                                <strong title={title}>{footNote.total}</strong>
                                {(footNote.percent !== 100) && <small className="float-right mt-2 text-muted position-absolute mr-3" style={{ right: 0 }}>{footNote.percent}%</small>}
                                <Progress className="progress-xs mt-2" color={footNote.color} value={footNote.percent} title={footNote.percent + "% online"} />
                              </Col>
                        })
                      }
                    </Row>                        
    return (
      <div className="animated fadeIn h-100 w-100">
        <Card>
          <CardBody>
            <Row>
              <Col sm="5">
                <CardTitle className="mb-0" title={`Historical data of the temperatures for the last ${utils.MAX_TEMPERATURE_HISTORY_IN_YEARS} years`}>Temperature history</CardTitle>
                <div className="small text-muted text-nowrap" title="Current time/range">
                  
                  {(timeZone === null) 
                    ? <small className="text-danger">TimeZone is missing for the display!</small>
                    : (rangeSelected < 2) 
                        ? moment.tz(timeZone).format("LLL z")
                        : `${min && min.format("ll")} - ${max && max.format("ll")}`
                  }
                </div>
              </Col>
              <Col sm="7" className="d-none d-sm-inline-block">
                <div className="text-nowrap d-inline-flex float-right">
                  {rangeButtons}
                  {expandButton}
                </div>
              </Col>
            </Row>
            <div className="chart-wrapper" style={{ height: 300 + 'px', marginTop: 40 + 'px' }}>
              <ChartWrap data={this.state.chartData} xLabel={xLabel} yLabel={yLabel} loading={loading}/>
            </div>
          </CardBody>
          <CardFooter>
            {footer}
          </CardFooter>
        </Card>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className="custom-modal">
          <ModalHeader toggle={this.toggle} close={closeButton}>
            Temperature History
            <small className="text-muted d-block">{display && display.CompanyName} {display && display.ProjectName}</small>
          </ModalHeader>
          <ModalBody>
            <ChartWrap data={this.state.chartData} xLabel={xLabel} yLabel={yLabel} loading={loading}/>
          </ModalBody>
          <ModalFooter>
            {footer}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { selectedDisplay, display } = state.display;
  const { history, loading } = state.temperature;

  return {
    selectedDisplay, display, history, loading
  };
}

const connectedTemperatureHistory = connect(mapStateToProps)(TemperatureHistory);
export default connectedTemperatureHistory;
