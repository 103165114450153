import React from 'react'

export const SpinnerDots = ({loading, className}) => {
	return <React.Fragment>
        {loading &&
          <div className={"sk-three-bounce spinner-small float-right my-0 mb-1 " + className}>
            <div className="sk-child sk-bounce1 bg-white"></div>
            <div className="sk-child sk-bounce2 bg-white"></div>
            <div className="sk-child sk-bounce3 bg-white"></div>
          </div>
        }
	</React.Fragment>
}