export const maintenanceConstants = {
  SUCCESS: 'MAINTENANCE_MSG_SUCCESS',
  ERROR: 'MAINTENANCE_MSG_ERROR',
  CLEAR: 'MAINTENANCE_MSG_CLEAR',

  GET_MAINTENANCE_MSG_ALL_REQUEST: 'GET_MAINTENANCE_MSG_ALL_REQUEST',
  GET_MAINTENANCE_MSG_ALL_SUCCESS: 'GET_MAINTENANCE_MSG_ALL_SUCCESS',
  GET_MAINTENANCE_MSG_ALL_FAILURE: 'GET_MAINTENANCE_MSG_ALL_FAILURE'

};
