import {combineReducers} from 'redux';
import { alert } from "./alert.reducer";
import { display } from "./display.reducer";
import { comments } from "./comments.reducer";
import { authentication } from "./authentication.reducer";
import { profile } from "./profile.reducer";
import { brightness } from "./brightness.reducer";
import { camera } from "./camera.reducer";
import { monitor } from "./monitor.reducer";
import { temperature } from "./temperature.reducer";
import { maintenance } from "./maintenance.reducer";
import { heatmap } from "./heatmap.reducer"

const rootReducer = combineReducers({
  display: display,
  comments: comments,
  authentication: authentication,
  profile: profile,
  alert: alert,
  brightness: brightness,
  camera: camera,
  monitor: monitor,
  temperature: temperature,
  maintenance: maintenance,
  heatmap: heatmap,
});

export default rootReducer;

export { alert, authentication, profile, display, comments, brightness, camera, monitor, temperature, heatmap };
