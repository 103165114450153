import { cameraConstants } from '../_constants';

export function camera(state = {}, action) {
  switch (action.type) {
    case cameraConstants.GETHISTORY_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        // history: null,
        // latestSnapshots: null,
        error: null
      });
    case cameraConstants.GETHISTORY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        history: action.history,
        // latestSnapshots: null,
        error: null
      });
    case cameraConstants.GETHISTORY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        history: null,
        // latestSnapshots: null,
        error: action.error
      });


    case cameraConstants.GETLATEST_SNAPSHOTS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        // history: null,
        // latestSnapshots: null,
        error: null
      });
    case cameraConstants.GETLATEST_SNAPSHOTS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        history: null,
        latestSnapshots: action.latestSnapshots,
        error: null
      });
    case cameraConstants.GETLATEST_SNAPSHOTS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        history: null,
        latestSnapshots: null,
        error: action.error
      });


    case cameraConstants.NEW_SNAPSHOT_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        // history: null,
        // latestSnapshots: null,
        error: null
      });
    case cameraConstants.NEW_SNAPSHOT_SUCCESS:
      // prepend Snapshot if history exists
      if (state && state.history) {
        state.history.images = (state.history.images || []) 
        state.history.images.unshift(action.snapshot)
        if (state.history.images.length > state.history.count) {
          state.history.images.pop()
        }
      }
      return Object.assign({}, state, {
        loading: false,
        // history: state.history,
        // latestSnapshots: null,
        error: null
      });
    case cameraConstants.NEW_SNAPSHOT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        // history: null,
        // latestSnapshots: null,
        error: action.error
      });

    case cameraConstants.GET_SNAPSHOTS_STATS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case cameraConstants.GET_SNAPSHOTS_STATS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        snapshotStats: action.snapshotStats,
        error: null
      });
    case cameraConstants.GET_SNAPSHOTS_STATS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        history: null,
        error: action.error
      });


    default:
      return state
  }
}
