import axios from 'axios';
// import { URL } from 'url'
import { history } from '../_helpers';
import { authService } from '../_services';
const URL = require("url");


const UNAUTHORIZED = 401;

// intercept Unauthorized response and redirect to login page
axios.interceptors.response.use(
  response => response,
  error => {
      let errMsg = null
      if (typeof error !== 'undefined') {
        if (typeof error.response !== 'undefined') {
          if (error.response.status === UNAUTHORIZED) {
            const url = URL.parse(error.config && (error.config.url || ""));
            if (["api.openweathermap.org"].includes(url.hostname)) {
              errMsg = error
            } else {
              // auto logout if 401 response returned from api
              authService.logout();
              history.push('/');
              window.location.reload(true);
            }
          }
          errMsg = error.response.data && (error.response.data.message || error.response.data.error || error.response.statusText)
        } else {
          errMsg = error
        }
      }
      return Promise.reject(errMsg);
 }
)